export const PathConnectionRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
    {
        tab: 10,
    },
]

export const ways1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways1.png?alt=media&token=3d581d28-353d-4cc5-8b12-94d5eae88724"
export const ways2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways2.png?alt=media&token=5be0a641-3a8a-4883-810a-98dd06c9599e"
export const ways3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways3.png?alt=media&token=a0c73cf9-c812-4c9f-a0f4-161e7012295a"
export const ways4Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways4.png?alt=media&token=86e2f021-0bd9-4536-be7d-5d7b02821661"
export const ways5Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways5.png?alt=media&token=17ac683d-edff-4716-9087-cf01656fa59c"
export const ways6Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways6.png?alt=media&token=fd538ab3-c068-4f27-91bb-d3ac808c312f"
export const ways7Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways7.png?alt=media&token=7401cc59-3944-4689-b2ad-183232a5ca72"
export const ways8Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways8.png?alt=media&token=652b6b0b-309f-42d2-a562-9786f01609a8"
export const ways9Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways9.png?alt=media&token=dccaee92-af05-4ffb-bb4a-6431b70e4c5f"
export const ways10Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways10.png?alt=media&token=21adaa2c-1ff8-498e-8e0a-a9c386fa4dcb"
export const ways11Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways11.png?alt=media&token=8a7b67bd-382d-42b4-8e12-583f81545aae"
export const ways12Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways12.png?alt=media&token=8fcd0d55-c6a7-4ce5-8028-aaba1fd7ceb7"
export const ways13Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways13.png?alt=media&token=2aaa18ef-7864-473d-bd49-a9d024191616"
export const ways14Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways14.png?alt=media&token=cc77e091-ca3a-4837-bab4-8c8e541c8be5"
export const ways15Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways15.png?alt=media&token=8ecc6b23-d505-49bc-b3ef-372c41615334"
export const ways16Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways16.png?alt=media&token=f8c037ff-ec1e-45ad-b7be-22c889bb8117"
export const ways17Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fways%2Fways17.png?alt=media&token=37ed3e6c-a110-4087-9ac4-54b2491280ba"


