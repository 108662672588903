export const COUPLES_BONDING = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/game1%2F5.1%20Bottle.png?alt=media&token=538e2ae1-9d68-40ba-a4ea-8780d569a24b&_gl=1*alqcqx*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNjUwMC41Ny4wLjA.'
    },
    {
        img:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/game1%2F5.2%20Truth%20or%20Dare.png?alt=media&token=7ad7d489-2e0f-471a-a9b2-e94630b0f65d&_gl=1*bleu88*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNjUwMi41NS4wLjA."
    }

] as const
export const YOU_CUM = [
    {
        img:'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/game2%2F6.1%20Sex%2018%2B.png?alt=media&token=5d119f55-92f6-48bd-9299-ff0a72df9e5c&_gl=1*1t16r19*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNjU2MC41OC4wLjA.'
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/game2%2F6.2%20Oral%20Sex.png?alt=media&token=fee801b8-47df-4432-bc95-a5f62023749d&_gl=1*12sosyd*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNjU2My41NS4wLjA.'
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/game2%2F6.3%20Hard%20Mode.png?alt=media&token=4228b3f8-4b5c-40c7-abcb-798064d2512c&_gl=1*yqyvwn*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNjU2NS41My4wLjA.'
    },
] as const
export const YOU_DECIDE = [
    {
        img:'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/game3%2F7.1%20Truth%20.png?alt=media&token=0f9f20d1-ea6a-44ef-a6e5-da1e9005c822&_gl=1*13n51tt*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNjU4Ny4zMS4wLjA.'
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/game3%2F7.2%20Dare.png?alt=media&token=82544eb4-9cbd-4fc0-b9f6-6b9888b12810&_gl=1*30kpol*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNjU4OS4yOS4wLjA.'
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/game3%2F7.3%20Hot%20Tasks.png?alt=media&token=fbd37504-895e-48fb-9aa1-e0a7def134d2&_gl=1*1v8ujve*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNjU5MC4yOC4wLjA.'
    },
] as const

export const COUPLES_BONDING_ROUTES = [
    '/bottle',
    '/truth-or-dare'
]
export const YOU_CUM_ROUTES = [
    '/sex',
    '/oral-sex',
    '/hard-made'
]

export const YOU_DECIDE_ROUTES = [
    '/truth',
    '/dare',
    '/hot-tasks'
]
export const COUPLES_BONDING_NUMBER = '0'
export const YOU_CUM_NUMBER = '1'

export const YOU_DECIDE_NUMBER = '2'