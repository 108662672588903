import './ProgressDivision.scss';
import {memo} from "react";
import {useTranslation} from "react-i18next";
import {ProgressBarState} from "../../../constants/onBoardingConstants/progressBar.constants";

export const ProgressDivision =memo((
    {label, divisionStatus, onClick}: {label?:string, onClick?: ()=>void, divisionStatus:ProgressBarState})=>{
    const { t } = useTranslation('onboarding');

    return <div className={'divisionContainer'}>
        <div className={'divisionLabel'}>{t(label)}</div>
        <div onClick={onClick} className={`bar ${divisionStatus}`}/>
    </div>;
})
