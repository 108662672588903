import './PayCardsContainer.scss';
import {ReactComponent as VisaSvg} from 'assets/payment/visa.svg'
import {ReactComponent as MastercardSvg} from 'assets/payment/masterCard.svg'
import {ReactComponent as PCISvg} from 'assets/payment/pi.svg'


export const PayCardsContainer =()=>{
    return (
        <div className={'payCardsContainer'}>
            <MastercardSvg />
            <VisaSvg />
            <PCISvg />
        </div>
    )
}
