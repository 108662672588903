import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {LOVE_YOUR_SELF} from "../../CourseItem/contants";
import {COUPLES_BONDING} from "../../GameItem/contants";


const Bottle = () => {
    const { t } = useTranslation('app');
    const title = t("game1_bottle_title1")
    const subtitle = t("game1_bottle_title2")
    const subtitle1 = t("game1_bottle_title3")
    const subtitle2 = t("game1_bottle_title4")
    const subtitle3 = t("game1_bottle_title5")
    const subtitle4 = t("game1_bottle_title6")
    const article = t("game1_bottle_article1")
    const list = t("game1_bottle_list1", { returnObjects: true })
    const list1 = t("game1_bottle_list2", { returnObjects: true })
    const list2 = t("game1_bottle_list3", { returnObjects: true })
    const list3 = t("game1_bottle_list4", { returnObjects: true })
    const list4 = t("game1_bottle_list5", { returnObjects: true })
    const titleMain = t("couples_bonding_game")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("couples_bonding") + 0]}
                        objectToSave={{
                            img: COUPLES_BONDING[0].img,
                            value: 'couples_bonding',
                            route: '/bottle',
                            index: 0
                        }}
                        uuid={"couples_bonding0"}
                        route={"/games/couples-bonding"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle3}
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__list">
                {list3.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__list">
                {list4.map((i: string, idx: number) => <>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={imgUrl[3+idx] || imgUrl[idx-10]} alt="course-preview" />
                    </div>
                    <div className="child-container__games-wrapper column">
                        <div className="icon">
                        <div className="icon__value child-container__red-title typography-14 typography-bold">
                            {idx + 1}
                        </div>
                        </div>
                    <div className="child-container__list mt-16 typography-14"> {i}</div>
                    </div>
                        </>)}
            </div>
        </div>
            </>
    )
}

export default Bottle