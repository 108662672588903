export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fforbidden%2F8.2.2.1.png?alt=media&token=eb3d290d-4a8a-4464-9e96-ccc52521c458",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fforbidden%2F8.2.2.2.png?alt=media&token=4ec631f5-596e-4867-a024-d7192c7a10b3",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fforbidden%2F8.2.2.3.png?alt=media&token=2ff6e3cc-403b-4fd3-b4fc-cc56f081aaa1",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fforbidden%2F8.2.2.4.png?alt=media&token=55a8e619-584b-48c5-91b0-b74ec4bce21d"
]





