import {Trans, useTranslation} from "react-i18next";
import {hundredsOfThousands} from "../../../assets/onBoardingImages";
import {ButtonThemes} from "../../../constants/button.constants";
import './TestBeginningScreen.scss';
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

export const TestBeginningScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={hundredsOfThousands} />
                <h2 className={'testBeginningTitle'}>{t("testBeginningScreen.introductoryTitle").toString().toUpperCase()}</h2>
                <p className={'testBeginningText'}>
                    <Trans t={t}>{t("testBeginningScreen.introductoryText")}</Trans>
                </p>
            </ContentContainer>
            <NextRouteButton option={t("testBeginningScreen.continueButton")}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'continueButton'} />
        </>
    )
}
