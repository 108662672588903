import {useTranslation} from "react-i18next";
import  {src10, src11, src5, src6, src7, src8, src9} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');
    const title1 = t("course3_techniques_title6")
    const subtitle7 = t("course3_techniques_title7")
    const subtitle8 = t("course3_techniques_title8")
    const subtitle9 = t("course3_techniques_title9")
    const subtitle10 = t("course3_techniques_title10")
    const subtitle11 = t("course3_techniques_title11")
    const subtitle12 = t("course3_techniques_title12")
    const subtitle13 = t("course3_techniques_title13")
    const article9 = t("course3_techniques_article9")
    const article10 = t("course3_techniques_article10")
    const article11 = t("course3_techniques_article11")
    const article12 = t("course3_techniques_article12")
    const article13 = t("course3_techniques_article13")
    const article14 = t("course3_techniques_article14")
    const article15 = t("course3_techniques_article14")
    const article16 = t("course3_techniques_article14")
    const list = t("course3_techniques_list1", { returnObjects: true })
    const list2 = t("course3_techniques_list2", { returnObjects: true })
    const list3 = t("course3_techniques_list3", { returnObjects: true })
    const list4 = t("course3_techniques_list4", { returnObjects: true })
    const list5 = t("course3_techniques_list4", { returnObjects: true })

    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle7}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article9}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src5} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle8}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article10}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src6} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article11}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle9}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article12}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src7} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle10}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article13}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src8} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article14}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle11}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article15}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src9} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle12}
            </div>
            <div className="child-container__list">
                {list3.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__list">
                <ul>
                    {list4.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src10} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list5.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle13}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article16}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src11} alt="course-preview" />
            </div>
        </div>
    )
}