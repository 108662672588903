import {ProgressBarState} from "../constants/onBoardingConstants/progressBar.constants";
import {IProgressBarRoute, IProgressDivision} from "../types/progressBar.types";
import {
    BOREDOM_DISCUSSION_ROUTE,
    CANDOR_CHECK_ROUTE,
    CONFIDENT_CHECK_ROUTE, CREATE_ACCOUNT_ROUTE,
    CURRENT_RELATIONSHIP_ROUTE,
    DISTANT_RELATIONSHIP_ROUTE,
    EDUCATION_TIME_CHECK_ROUTE,
    EMAIL_CHECK_ROUTE,
    ENTICING_ONE_ROUTE,
    GOOD_TEST_RESULT_ROUTE,
    INTIMATE_LIFE_ROUTE, NEW_LIFE_JOURNEY_ROUTE,
    NEW_READINESS_ROUTE,
    PARTNER_OPENNESS_CHECK_ROUTE, PAY_INFO_ROUTE,
    RELATIONSHIP_ISSUE_ROUTE,
    SEXUAL_POTENTIAL_ROUTE, SIGN_UP_OFFER_ROUTE,
    TEST_BEGINNING_ROUTE,
    TRUSTING_RELATIONSHIP_ROUTE
} from "../constants/routes.constants";

const deleteAlwaysUnfilledFirstRoute=(routes:{ label?:string; route:string }[])=>{
    const routesForDelete=[...routes]
    routesForDelete.shift();
    return routesForDelete;
}
export const getProgressDivisionsInitialState=(onboardingTestRoutes:{ label?:string; route:string }[],location:string):IProgressDivision[]=>{
    const routesToFill=deleteAlwaysUnfilledFirstRoute(onboardingTestRoutes)
    const currentQuestionBarDivisionIndex= routesToFill.findIndex((route)=>route.route===location)

    return Array.from(routesToFill,
        (route,i)=> ({
            label: route.label? route.label:'',
            route: route.route,
            divisionNumber: i+1,
            divisionStatus: i<=currentQuestionBarDivisionIndex ?
                    ProgressBarState.FILLED: ProgressBarState.UNFILLED
        }))
}

export const getOnboardingQuestionsRoutes=(isClever:boolean): IProgressBarRoute[]=> [
    {route:TEST_BEGINNING_ROUTE},
    {route:CONFIDENT_CHECK_ROUTE},
    {route:CURRENT_RELATIONSHIP_ROUTE},
    {route:CANDOR_CHECK_ROUTE},
    {route:RELATIONSHIP_ISSUE_ROUTE},
    {route:BOREDOM_DISCUSSION_ROUTE},
    {route:PARTNER_OPENNESS_CHECK_ROUTE},
    {route:isClever ? TRUSTING_RELATIONSHIP_ROUTE : DISTANT_RELATIONSHIP_ROUTE},
    {route:EDUCATION_TIME_CHECK_ROUTE},
    {route:NEW_READINESS_ROUTE},
    {route:SEXUAL_POTENTIAL_ROUTE},
    {route: ENTICING_ONE_ROUTE},
    {route:INTIMATE_LIFE_ROUTE},
    {route: EMAIL_CHECK_ROUTE}]

export const purchaseRoutes:IProgressBarRoute[]= [{route:GOOD_TEST_RESULT_ROUTE, label: ''},
    {route:PAY_INFO_ROUTE, label: 'progressBarTextForSignUp.purchasePlan' },
    {route:CREATE_ACCOUNT_ROUTE,label:'progressBarTextForSignUp.createAccount'},
    // {route:SIGN_UP_OFFER_ROUTE, label: 'progressBarTextForSignUp.signUpOffer'},
    {route:NEW_LIFE_JOURNEY_ROUTE, label: 'progressBarTextForSignUp.onboarding'}]
