import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import {Part2} from "./Part2";
import {Prisoner} from "./Prisoner";
import Intro from "./Intro";
import Tropical from "./Tropical";
import School from "./School";
import Mind from "./Mind";
import Surprise from "./Surprise";
import Dark from "./Dark";
import Chef from "./Chef";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PATH_TO_PLEASURE} from "../../../CourseItem/contants";
const SexGames = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("path_to_pleasure_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 18]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[18].img,
                            value: 'path_to_pleasure',
                            route: '/sex-games/1',
                            index: 18
                        }}
                        uuid={"path_to_pleasure18"}
                        route={"/courses/path_to_pleasure"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Part2 />} />
                <Route path={'/3'} element={<Prisoner />} />
                <Route path={'/4'} element={<Tropical />} />
                <Route path={'/5'} element={<School />} />
                <Route path={'/6'} element={<Mind />} />
                <Route path={'/7'} element={<Surprise />} />
                <Route path={'/8'} element={<Dark />} />
                <Route path={'/9'} element={<Chef />} />

            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.SEX_GAMES}  />
        </>
    )
}

export default SexGames