import './ShadowContainer.scss';
import React, {useContext} from "react";
import {ThemeContext} from "../../../context/themeContext";

export const ShadowContainer =({children}:{children:React.ReactElement[]|React.ReactElement})=>{
    const { theme } = useContext(ThemeContext);

    return (
        <div className={`signUpOfferContainer ${theme}`}>
            {children}
        </div>
    )
}
