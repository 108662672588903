import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import Intro from "./Intro";
import ClassicVersion from "./ClassicVersion";
import Warm from "./Warm";
import Whirlpool from "./Whirlpool";
import Freezing from "./Freezing";
import Technique from "./Technique";
import WetLips from "./WetLips";
import Stunning from "./Stunning";
import Narrow from "./Narrow";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {PATH_TO_PLEASURE} from "../../../CourseItem/contants";
import {useTranslation} from "react-i18next";
const BlowJob = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("blow_job_title")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 13]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[13].img,
                            value: 'path_to_pleasure',
                            route: '/blow-job/1',
                            index: 13
                        }}
                        uuid={"path_to_pleasure13"}
                        route={"/courses/path_to_pleasure"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<ClassicVersion />} />
                <Route path={'/3'} element={<Warm />} />
                <Route path={'/4'} element={<Whirlpool />} />
                <Route path={'/5'} element={<Freezing />} />
                <Route path={'/6'} element={<Narrow />} />
                <Route path={'/7'} element={<Stunning />} />
                <Route path={'/8'} element={<Technique />} />
                <Route path={'/9'} element={<WetLips />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.BLOW_JOB}  />
        </>
    )
}

export default BlowJob