export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fparent-control%2F8.3.1.1.png?alt=media&token=c7bbcdbf-3f46-4865-ada4-fd628f7e9fd0",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fparent-control%2F8.3.1.2.png?alt=media&token=994e16b4-c8cf-4c81-941e-1b85c133f13d",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fparent-control%2F8.3.1.3.png?alt=media&token=0241576d-fc35-42fd-8cf4-34308eb301f0",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fparent-control%2F8.3.1.4.png?alt=media&token=4a3bb76c-cd8b-4df5-bff8-dc6c69532ed8"
]





