import {useTranslation} from "react-i18next";
import {masturbation16Src, masturbation17Src} from "./constants";

const Types = () => {
    const { t } = useTranslation('app');
    const title = t("course2_masturbation_title17")
    const article = t("course2_masturbation_article17")
    const article1 = t("course2_masturbation_subtitle17")
    const list = t("course2_masturbation_list17", { returnObjects: true })
    const list1 = t("course2_masturbation_list17-1", { returnObjects: true })
    return (
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={masturbation16Src} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={masturbation17Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Types