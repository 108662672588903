import React, { useState } from "react";
import {Input} from "../../lego/Input/Input";
import {useTranslation} from "react-i18next";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";

const CARD_NUMBER ='card_number';
const EXPIRED_DATE ='EXPIRED_DATE';
const CVV ='CVV';

const CardInfo = () => {

    const [cardNumber, setCardNumber] = useState('');
    const [cvv, setCVV] = useState('');
    const [expDate, setExpDate] = useState('');


    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()
    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }

    return(
        <div className="paymentForm">
            <Input onChange={(value)=>{
                let newValue = value.replace(/\D/g, ''); // Remove non-numeric characters
                // Add space every 4 characters
                newValue = newValue.replace(/(\d{4})(?=\d)/g, '$1 ');

                clearErrorData(newValue,setCardNumber)
            }}
                   id={CARD_NUMBER}
                   inputType={'card_number'}
                   placeholderText={"xxxx xxxx xxxx xxxx"}
                   maxLength={19}
                   value={cardNumber}
                   errorMessage={errorState.errorInputId===CARD_NUMBER?errorState.message:''}
            />
            <div className="row-container space-between" style={{gap: 16}}>
            <Input onChange={(value)=>{
                let newValue = value.replace(/\D/g, ''); // Remove non-numeric characters
                if (newValue.length >= 2) {
                    newValue = newValue.replace(/(\d{2})(.*)/, '$1/$2');
                }
                clearErrorData(newValue,setExpDate)
            }}
                   id={EXPIRED_DATE}
                   inputType={'expired_date'}
                   placeholderText={"MM/YY"}
                   maxLength={5}
                   value={expDate}
                   errorMessage={errorState.errorInputId===EXPIRED_DATE?errorState.message:''}
            />
            <Input onChange={(value)=>clearErrorData(value,setCVV)}
                   id={CVV}
                   inputType={'cvv'}
                   placeholderText={"CVV"}
                   maxLength={4}
                   errorMessage={errorState.errorInputId===CVV?errorState.message:''}
            />
            </div>
        </div>
    )
}

export default CardInfo