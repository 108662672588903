export const TipsRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
]

export const tips1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Ftips%2Ftips1.png?alt=media&token=0d25e9c3-7762-4723-9c66-2d1c1d39c40f"
export const tips2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Ftips%2Ftips2.png?alt=media&token=19294fb4-93bd-44c6-9aa1-bbea42203f5b"
export const tips3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Ftips%2Ftips3.png?alt=media&token=076e79d7-ae87-4d3f-a73d-dff588de7782"
export const tips4Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Ftips%2Ftips4.png?alt=media&token=94c02d91-0fcc-4b50-80d4-dcfd2c4e9ca4"
export const tips5Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Ftips%2Ftips5.png?alt=media&token=2bf0a111-d16c-4786-8bc4-c38c5ab676af"
export const tips6Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Ftips%2Ftips6.png?alt=media&token=4ace1fda-11d3-4d11-990e-f4645a9fbb57"
