import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import {Part2} from "./Part2";
import Intro from "./Intro";
import { Fitness } from "./Fitness";
import {Pilot} from "./Pilot";
import { Maid } from "./Maid";
import { MrX } from "./MrX";
import {Taxi} from "./Taxi";
import {Patient} from "./Patient";
import { Policeman } from "./Policeman";
import {Student} from "./Student";
import { Boss } from "./Boss";
import { Husband } from "./Husband";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import { useEffect, useState } from "react";
import { TFavoritesList } from "screens/app/FavoritesPage/FavoritesPage";
import { getAuth } from "firebase/auth";
import { getListOfFavorites } from "utils/firebase/userData.utils";
import { EMANCIPATION } from "screens/app/CourseItem/contants";
const RolePlaying = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const titleMain = t("emancipation_course")?.split(' ')[0]
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("emancipation") + 2]}
                        objectToSave={{
                            img: EMANCIPATION[2].img,
                            value: 'emancipation',
                            route: '/role-playing/1',
                            index: 2
                        }}
                        uuid={"emancipation2"}
                        route={"/courses/emancipation"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Part2 />} />
                <Route path={'/3'} element={<Fitness />} />
                <Route path={'/4'} element={<Pilot />} />
                <Route path={'/5'} element={<Maid />} />
                <Route path={'/6'} element={<MrX />} />
                <Route path={'/7'} element={<Taxi />} />
                <Route path={'/8'} element={<Patient />} />
                <Route path={'/9'} element={<Policeman />} />
                <Route path={'/10'} element={<Student />} />
                <Route path={'/11'} element={<Boss />} />
                <Route path={'/12'} element={<Husband />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.ROLE_PLAYING}  />
        </>
    )
}

export default RolePlaying