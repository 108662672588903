import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {LOVE_YOUR_SELF} from "../../CourseItem/contants";
import {COUPLES_BONDING} from "../../GameItem/contants";


const TruthOrDare = () => {
    const { t } = useTranslation('app');
    const title = t("game2_truth_title")
    const subtitle = t("game2_truth_title1")
    const subtitle1 = t("game2_truth_title2")
    const subtitle2 = t("game2_truth_title3")
    const subtitle4 = t("game2_truth_title4")
    const subtitle5 = t("game2_truth_title5")
    const subtitle6 = t("game2_truth_title6")
    const article = t("game2_truth_article")
    const note = t("game2_truth_note")
    const list = t("game2_truth_list1", { returnObjects: true })
    const list1 = t("game2_truth_list2", { returnObjects: true })
    const list2 = t("game2_truth_list4", { returnObjects: true })
    const list3 = t("game2_truth_list5", { returnObjects: true })
    const list4 = t("game2_truth_list6", { returnObjects: true })
    const list5 = t("game2_truth_list7", { returnObjects: true })
    const list6 = t("game2_truth_list8", { returnObjects: true })
    const titleMain = t("couples_bonding_game")

    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("couples_bonding") + 1]}
                        objectToSave={{
                            img: COUPLES_BONDING[1].img,
                            value: 'couples_bonding',
                            route: '/truth-or-dare',
                            index: 1
                        }}
                        uuid={"couples_bonding1"}
                        route={"/games/couples-bonding"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__list">
                <ul>
                    {list2.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__list">
                <ul>
                    {list3.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__list">
                {list4.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle5}
            </div>
            <div className="child-container__list">
                <ul>
                    {list5.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[3]} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle6}
            </div>
            <div className="child-container__list">
                {list6.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[4]} alt="course-preview" />
            </div>
        </div>
            </>
    )
}

export default TruthOrDare