export const PREMIUM_NAMES_ROUTES = [
    "bdsm-game",
    "truth-or-dare-advanced",
    "days-monthly",
    "days-365",
]

export const BDSM_GAME_ROUTES = [
    "/scenarios-bdsm",
    "/level-1",
    "/level-2",
    "/level-3",
    "/level-4",
    "/level-5",
    "/level-6",
    "/play-with-his-sperm"
]
export const DAYS_MONTHLY_ROUTES = [
    "/monthly-challenges"
]
export const TRUTH_OR_DARE_ADVANCED_ROUTES = [
    "/unforgettable-blowjob-scenarios",
    "/top-positions",
    "/cunnilingus-positions",
    "/man-truth",
    "/man-truth-hard-mode",
    "/man-dare",
    "/man-dare-hard-mode",
    "/girl-truth",
    "/girl-truth-hard-mode",
    "/girl-dare",
    "/girl-dare-hard-mode"
]

export const DAYS_365_ROUTES= [
    "/days-of-sex-calendar/1"
]

export const DAYS_365 =  [
    {
        img: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fdays%2F4.%20365%20Days%20of%20Sex%20Calendar.png?alt=media&token=8ea3be70-5ef8-4c75-af41-dc8ab4032a6f"
    }
] as const
export const BDSM_GAME = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fbdsm%2F1.1%20Scenarios.png?alt=media&token=bf4e7569-1694-42f6-8176-173471e607e1'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fbdsm%2F1.2%20Level%201%20-%20Light%20mode.png?alt=media&token=432828e9-9db3-48d8-b54d-4cd056776256'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fbdsm%2F1.3%20Level%202%20-%20Hard%20mode.png?alt=media&token=bdcf796f-7cdc-4105-ae9d-46ff4f19a635'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fbdsm%2F1.4%20Level%203%20-%20Mistress.png?alt=media&token=d7076f30-1ed9-4430-9675-1dbc1af9cebb'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fbdsm%2F1.5%20Level%204%20-%20Master.png?alt=media&token=39bd3c4a-c725-42b1-ad35-bae89a843b9a'
    },
    {
        img:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fbdsm%2F1.6%20Level%205.png?alt=media&token=d2f0b810-6d3b-4406-a298-d9040329d501"
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fbdsm%2F1.7%20Level%206%20.png?alt=media&token=67823643-8abb-4bae-a610-6a17151e4b2b"
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fbdsm%2F1.8%20Play%20with%20his%20sperm.png?alt=media&token=17ea90d6-d384-4188-b63f-efa165728d4b'
    }
] as const


export const DAYS_MONTHLY = [
    { img: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2F3.%2030%20Monthly%20Challenges%20to%20Boost%20Your%20Libido.png?alt=media&token=8d8e245e-cd01-4226-9550-8205ab25ada2" }
] as const

export const TRUTH_OR_DARE_ADVANCED = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.1%2013%20Unforgettable%20Blowjob%20Scenarios.png?alt=media&token=3cb6a327-e044-4b54-b455-9a4740118a91'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.10%20Girl%20Dare.png?alt=media&token=54159941-988e-4458-922c-c99beb962409'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.11%20Girl%20Dare%20Hard%20mode.png?alt=media&token=109b01d4-a77d-4016-99ff-1403001301cd'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.2%20Top%204%20Positions%20for%20Intensely%20Deep%20Penetration.png?alt=media&token=238e3478-70dd-4aee-a781-7f96a43b54b9'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.3%20Cunnilingus%20Positions.png?alt=media&token=ebdd7e38-4123-4a4b-8b61-40412025d7b4'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.4%20Man%20Truth.png?alt=media&token=2f627156-eebe-40cc-aef2-d1b5882cc9d7'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.5%20Man%20Truth%20Hard%20mode.png?alt=media&token=c254c0e3-ebf6-4728-ae4f-46740f4cad89'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.6%20Man%20Dare.png?alt=media&token=7cc6ffbb-1c3e-4add-ae73-feb242309cf6'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.7%20Man%20Dare%20Hard%20mode.png?alt=media&token=62048d35-f1c3-4ff6-abfa-61dcea5c881b'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.8%20Girl%20Truth.png?alt=media&token=9d464451-c942-40c2-873d-601124eb5e9f'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2F2.9%20Girl%20Truth%20Hard%20mode.png?alt=media&token=f09ba257-2266-4a9c-a9f0-fdeb81121da4'
    }
] as const


export const BDSM_GAME_NUMBER = '0'
export const DAYS_MONTHLY_NUMBER  = '1'
export const TRUTH_OR_DARE_ADVANCED_NUMBER  = '2'

export const DAYS_365_NUMBER = '3'

