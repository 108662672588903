import { useEffect, useState} from "react";
import './styles.scss'
import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";
import {getSingleImage} from "../../../../utils/firebase/storage";
import {useTranslation} from "react-i18next";
export const CalendarDays = () => {
    const { t } = useTranslation('app');
        const [selectedDate, setSelectedDate] = useState(new Date());
        const [currentMonth, setCurrentMonth] = useState(new Date());
    const [src, setSrc] = useState('')
    const [info, setInfo] = useState({title: '', description: ''})
    const calendarContent = t("calendar_content", { returnObjects: true })
    const daysOfWeek = t("week_days", { returnObjects: true })
    const allMonths = t("month_names", { returnObjects: true })


    const getImageUrl = async () =>{
        if(!selectedDate || !currentMonth) return
        try{
            const res =  await getSingleImage('premium/main/calendar/'+ currentMonth.toLocaleString('default', { month: 'long' }).toLowerCase() + '/' + selectedDate.getDate() + '.png' )

            setSrc(res)

        }
        catch (e){
            console.error(e)
        }
    }

    const getInfo = () => {
        return calendarContent[currentMonth.toLocaleString('default', { month: 'long' }).toLowerCase()][selectedDate.getDate() - 1]
    }

    useEffect(() =>{
        getImageUrl()
        const res = getInfo()
        setInfo(res)
    }, [selectedDate])

        const handleDateClick = (day: any) => {
            setSelectedDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day));
        };

        const handleMonthChange = (change: any) => {
            setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + change, 1));
        };

        const renderDaysOfWeek = () => {

            return daysOfWeek.map((day, index) => (
                <div key={index} className="day-of-week typography-10">{day}</div>
            ));
        };

        const renderDays = () => {
            const days = [];
            const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
            const lastDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
            const startingDayOfWeek = firstDayOfMonth.getDay();

            for (let i = 0; i < startingDayOfWeek; i++) {
                days.push(<div key={`empty-${i}`} className="empty-day"></div>);
            }

            for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
                const currentDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i);
                days.push(
                    <div
                        key={i}
                        className={`day ${selectedDate && currentDate.toDateString() === selectedDate.toDateString() ? 'selected' : ''} typography-20 typography-400`}
                        onClick={() => handleDateClick(i)}
                    >
                        {i}
                    </div>
                );
            }

            return days;
        };

        return (
            <div className="child-container">
                <div className="date-picker-wrapper">
                <div className="datepicker mt-32">
                    <div className="month-header">
                        <span onClick={() => handleMonthChange(-1)}><ArrowIcon /></span>
                        <span>{allMonths[currentMonth.getMonth()]}</span>
                        <span onClick={() => handleMonthChange(1)}><ArrowIcon style={{      transform: 'rotateY(180deg)'}} /></span>
                    </div>
                    <div className="days-of-week row-container">{renderDaysOfWeek()}</div>
                    <div className="days">{renderDays()}</div>
                </div>

            </div>
                {src && <div className="child-container__image mt-24">
                    <img loading="lazy" src={src} alt="course-preview"/>
                </div>}
                <div className="calendar-content-wrapper mt-32 row-container">
                    <div className="calendar-content-wrapper__left-part column">
                <div className="typography-10 ">
                    {daysOfWeek[selectedDate.getDay()].toUpperCase()}
                </div>
                <div className="child-container__red-title typography-18 typography-bold">
                    {selectedDate.getDate()}
                </div>
                    </div>
                    <div className="calendar-content-wrapper__right-part column">
                <div className="typography-16 typography-bold">{info.title}</div>
                <div dangerouslySetInnerHTML={{ __html: info.description }} className="typography-14 typography-300" />
                    </div>
                </div>
                </div>
        );
}