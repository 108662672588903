import React from "react";
import {ReactComponent as CheckIcon} from "assets/app/Progress/check.svg";
import {ReactComponent as DisabledIcon} from "assets/app/Progress/disabled.svg";
import {ReactComponent as ActiveIcon} from "assets/app/Progress/active.svg";
import cn from'classnames'
export type TProps = {
    title: string,
    description: string,
    icon: JSX.Element,
    disabled: boolean,
    active: boolean,
}
const Item = ({title, description, icon, disabled, active}: TProps) => {
    return(
        <div className={cn("progress-container__block-title column", {active: active, disabled: disabled})}>
            <div className="progress-container__block-title__header row-container space-between align-center">
                <div className={cn("row-container progress-container__block-title__header__left align-center")}>
                    {icon}
                    <div className="typography-14 typography-bold">
                        {title}
                    </div>
                </div>
                {!disabled && !active && <CheckIcon/>}
                {active && <ActiveIcon/>}
                {disabled && <DisabledIcon className="disabled-icon"/>}
            </div>
            <div className="typography-14">
                {description}
            </div>
        </div>
    )
}

export default Item