import {useTranslation} from "react-i18next";
import {ways7Src, ways8Src } from "./constants";

const SecondPartOfWays = () => {
    const { t } = useTranslation('app');
    const subTitle = t("ways_7_title")
    const article = t("ways_7_article")
    const list = t("ways_7_list", { returnObjects: true })
    const subTitle1 = t("ways_8_title")
    const list1 = t("ways_8_article", { returnObjects: true })

    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-16">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways7Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways8Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default SecondPartOfWays