import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import { TRUTH_OR_DARE_ADVANCED} from "../../contants";

export const BlowJobPremium = () => {
    const { t } = useTranslation('app');
    const titleMain = t("truth_or_dare_advanced_premium")
    const title = t("premium2_blowjob_title1")
    const subtitle = t("premium2_blowjob_title2")
    const subtitle1 = t("premium2_blowjob_title3")
    const subtitle4 = t("premium2_blowjob_title4")
    const subtitle5 = t("premium2_blowjob_title5")
    const subtitle6 = t("premium2_blowjob_title6")
    const subtitle7 = t("premium2_blowjob_title7")
    const subtitle8 = t("premium2_blowjob_title8")
    const subtitle9 = t("premium2_blowjob_title9")
    const subtitle10 = t("premium2_blowjob_title10")
    const subtitle11 = t("premium2_blowjob_title11")
    const subtitle12 = t("premium2_blowjob_title12")
    const subtitle13 = t("premium2_blowjob_title13")
    const subtitle14 = t("premium2_blowjob_title14")
    const subtitle15 = t("premium2_blowjob_title15")
    const subtitle16 = t("premium2_blowjob_title16")
    const subtitle17 = t("premium2_blowjob_title17")
    const subtitle18 = t("premium2_blowjob_title18")
    const subtitle19 = t("premium2_blowjob_title19")
    const subtitle20 = t("premium2_blowjob_title20")
    const note = t("premium2_blowjob_note1")
    const note2 = t("premium2_blowjob_note2")
    const note3 = t("premium2_blowjob_note3")
    const note4 = t("premium2_blowjob_note4")
    const note5 = t("premium2_blowjob_note5")
    const note6 = t("premium2_blowjob_note6")
    const note7 = t("premium2_blowjob_note7")
    const article1 = t("premium2_blowjob_article1")
    const article2 = t("premium2_blowjob_article2")
    const list = t("premium2_blowjob_list1", { returnObjects: true })
    const list1 = t("premium2_blowjob_list2", { returnObjects: true })
    const list2 = t("premium2_blowjob_list3", { returnObjects: true })
    const list3 = t("premium2_blowjob_list4", { returnObjects: true })
    const list4 = t("premium2_blowjob_list5", { returnObjects: true })
    const list5 = t("premium2_blowjob_list6", { returnObjects: true })
    const list7 = t("premium2_blowjob_list7", { returnObjects: true })
    const list8 = t("premium2_blowjob_list8", { returnObjects: true })
    const list9 = t("premium2_blowjob_list9", { returnObjects: true })
    const list10 = t("premium2_blowjob_list10", { returnObjects: true })
    const list11 = t("premium2_blowjob_list11", { returnObjects: true })
    const list12 = t("premium2_blowjob_list12", { returnObjects: true })
    const list13 = t("premium2_blowjob_list13", { returnObjects: true })
    const list14 = t("premium2_blowjob_list14", { returnObjects: true })
    const list15 = t("premium2_blowjob_list15", { returnObjects: true })
    const list16 = t("premium2_blowjob_list16", { returnObjects: true })
    const list17 = t("premium2_blowjob_list17", { returnObjects: true })
    const list18 = t("premium2_blowjob_list18", { returnObjects: true })
    const list19 = t("premium2_blowjob_list19", { returnObjects: true })
    const list20 = t("premium2_blowjob_list20", { returnObjects: true })
    const list21 = t("premium2_blowjob_list21", { returnObjects: true })
    const list22 = t("premium2_blowjob_list22", { returnObjects: true })
    const list23 = t("premium2_blowjob_list23", { returnObjects: true })
    const list24 = t("premium2_blowjob_list24", { returnObjects: true })

    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("truth_or_dare_advanced") + 0]}
                        objectToSave={{
                            img: TRUTH_OR_DARE_ADVANCED[0].img,
                            value: 'truth_or_dare_advanced',
                            route: '/unforgettable-blowjob-scenarios',
                            index: 0
                        }}
                        uuid={"truth_or_dare_advanced0"}
                        route={"/premium/truth-or-dare-advanced"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            {list.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            {list1.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            {list2.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            {list3.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note2}
                </div>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            {list4.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            {list5.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle5}
            </div>
            {list7.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note3}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[3]} alt="course-preview" />
            </div>
            {list8.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle6}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[4]} alt="course-preview" />
            </div>
            {list9.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note4}
                </div>
            </div>
            {list10.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle7}
            </div>
            {list11.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[5]} alt="course-preview" />
            </div>
            {list12.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note5}
                </div>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle8}
            </div>
            {list13.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle9}
            </div>
            {list14.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[6]} alt="course-preview" />
            </div>
            {list15.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle10}
            </div>
            {list16.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[7]} alt="course-preview" />
            </div>
            {list17.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle11}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[8]} alt="course-preview" />
            </div>
            {list18.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle12}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[9]} alt="course-preview" />
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note6}
                </div>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle13}
            </div>
            {list19.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle14}
            </div>
            {list20.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[10]} alt="course-preview" />
            </div>

            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle15}
            </div>
            {list21.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle16}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note7}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[11]} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle17}
            </div>
            {list22.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }

            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle18}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle19}
            </div>
            {list23.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[12]} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle20}
            </div>
            {list24.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
        </div>
            </>
            )
}