export const Rules = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    }
]

export const rules1 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Frules%2Frules1.png?alt=media&token=7166197e-3e66-4623-945e-d470ffc76899"
export const rules2 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Frules%2Frules2.png?alt=media&token=6636c9b3-41c0-42ff-9595-a220cb2fff5d"
export const rules3 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Frules%2Frules3.png?alt=media&token=6b1a941a-138f-4134-ae4e-63b1b5808986"
