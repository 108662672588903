import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {LOVE_YOUR_SELF} from "../../CourseItem/contants";


const Sms = () => {
    const { t } = useTranslation('app');
    const titleMain = t("love_your_self_course")
    const subtitle = t("course4_days_title1")
    const subtitle1 = t("course4_days_title2")
    const subtitle3 = t("course4_days_title3")
    const subtitle4 = t("course4_days_title4")
    const subtitle5 = t("course4_days_title5")
    const subtitle6 = t("course4_days_title6")
    const subtitle7 = t("course4_days_title7")
    const subtitle8 = t("course4_days_title8")
    const subtitle9 = t("course4_days_title9")
    const subtitle10 = t("course4_days_title10")
    const subtitle11 = t("course4_days_title11")
    const subtitle12 = t("course4_days_title12")
    const subtitle13 = t("course4_days_title13")
    const subtitle14 = t("course4_days_title14")
    const subtitle15 = t("course4_days_title15")
    const subtitle16 = t("course4_days_title16")
    const subtitle17 = t("course4_days_title17")
    const subtitle18 = t("course4_days_title18")
    const subtitle19 = t("course4_days_title19")
    const subtitle20 = t("course4_days_title20")
    const subtitle21 = t("course4_days_title21")
    const subtitle22 = t("course4_days_title22")
    const subtitle23 = t("course4_days_title23")
    const subtitle24 = t("course4_days_title24")
    const subtitle25 = t("course4_days_title25")
    const subtitle26 = t("course4_days_title26")
    const subtitle27 = t("course4_days_title27")
    const subtitle28 = t("course4_days_title28")
    const subtitle29 = t("course4_days_title29")
    const subtitle30 = t("course4_days_title30")
    const subtitle31 = t("course4_days_title31")
    const subtitle32 = t("course4_days_title32")
    const subtitle33 = t("course4_days_title33")
    const subtitle34 = t("course4_days_title34")
    const subtitle35 = t("course4_days_title35")
    const subtitle36 = t("course4_days_title36")
    const subtitle37 = t("course4_days_title37")
    const subtitle38 = t("course4_days_title38")
    const subtitle39 = t("course4_days_title39")
    const subtitle40 = t("course4_days_title40")
    const subtitle41 = t("course4_days_title41")
    const subtitle42 = t("course4_days_title42")
    const subtitle43 = t("course4_days_title43")
    const subtitle44 = t("course4_days_title44")
    const subtitle45 = t("course4_days_title45")
    const subtitle46 = t("course4_days_title46")
    const subtitle47 = t("course4_days_title47")
    const subtitle48 = t("course4_days_title48")
    const subtitle49 = t("course4_days_title49")
    const subtitle50 = t("course4_days_title50")
    const subtitle51 = t("course4_days_title51")
    const subtitle52 = t("course4_days_title52")
    const subtitle53 = t("course4_days_title53")
    const subtitle54 = t("course4_days_title54")
    const subtitle55 = t("course4_days_title55")
    const subtitle56 = t("course4_days_title56")
    const subtitle57 = t("course4_days_title57")
    const subtitle58 = t("course4_days_title58")
    const subtitle59 = t("course4_days_title59")
    const subtitle60 = t("course4_days_title60")
    const subtitle61 = t("course4_days_title61")
    const subtitle62 = t("course4_days_title62")
    const subtitle63 = t("course4_days_title63")
    const note1 = t("course4_days_note1")
    const note2 = t("course4_days_note2")
    const note3 = t("course4_days_note3")
    const article1 = t("course4_days_article1")
    const article2 = t("course4_days_article2")
    const article4 = t("course4_days_article4")
    const article5 = t("course4_days_article5")
    const article6 = t("course4_days_article6")
    const list = t("course4_days_list1", { returnObjects: true })
    const list1 = t("course4_days_list2", { returnObjects: true })
    const list3 = t("course4_days_list3", { returnObjects: true })
    const list4 = t("course4_days_list4", { returnObjects: true })
    const list5 = t("course4_days_list5", { returnObjects: true })
    const list6 = t("course4_days_list6", { returnObjects: true })
    const list7 = t("course4_days_list7", { returnObjects: true })
    const list8 = t("course4_days_list8", { returnObjects: true })
    const list9 = t("course4_days_list9", { returnObjects: true })
    const list10 = t("course4_days_list10", { returnObjects: true })
    const list11 = t("course4_days_list11", { returnObjects: true })
    const list12 = t("course4_days_list12", { returnObjects: true })
    const list13 = t("course4_days_list13", { returnObjects: true })
    const list14 = t("course4_days_list14", { returnObjects: true })
    const list15 = t("course4_days_list15", { returnObjects: true })
    const list16 = t("course4_days_list16", { returnObjects: true })
    const list17 = t("course4_days_list17", { returnObjects: true })
    const list18 = t("course4_days_list18", { returnObjects: true })
    const list19 = t("course4_days_list19", { returnObjects: true })
    const list20 = t("course4_days_list20", { returnObjects: true })
    const list21 = t("course4_days_list21", { returnObjects: true })
    const list22 = t("course4_days_list22", { returnObjects: true })
    const list23 = t("course4_days_list23", { returnObjects: true })
    const list24 = t("course4_days_list24", { returnObjects: true })
    const list25 = t("course4_days_list25", { returnObjects: true })
    const list26 = t("course4_days_list26", { returnObjects: true })
    const list27 = t("course4_days_list27", { returnObjects: true })
    const list28 = t("course4_days_list28", { returnObjects: true })
    const list28_1 = t("course4_days_list28_1", { returnObjects: true })
    const list29 = t("course4_days_list29", { returnObjects: true })
    const list30 = t("course4_days_list30", { returnObjects: true })
    const list31 = t("course4_days_list31", { returnObjects: true })
    const list32 = t("course4_days_list32", { returnObjects: true })
    const list33 = t("course4_days_list33", { returnObjects: true })
    const list34 = t("course4_days_list34", { returnObjects: true })
    const list35 = t("course4_days_list35", { returnObjects: true })
    const list36 = t("course4_days_list36", { returnObjects: true })
    const list37 = t("course4_days_list37", { returnObjects: true })
    const list38 = t("course4_days_list38", { returnObjects: true })
    const list39 = t("course4_days_list39", { returnObjects: true })
    const list40 = t("course4_days_list40", { returnObjects: true })
    const list41 = t("course4_days_list41", { returnObjects: true })
    const list42 = t("course4_days_list42", { returnObjects: true })
    const list43 = t("course4_days_list43", { returnObjects: true })
    const list44 = t("course4_days_list44", { returnObjects: true })
    const list45 = t("course4_days_list45", { returnObjects: true })
    const list46 = t("course4_days_list46", { returnObjects: true })
    const list47 = t("course4_days_list47", { returnObjects: true })
    const list48 = t("course4_days_list48", { returnObjects: true })
    const list49 = t("course4_days_list49", { returnObjects: true })
    const list50 = t("course4_days_list50", { returnObjects: true })
    const list51 = t("course4_days_list51", { returnObjects: true })
    const list52 = t("course4_days_list52", { returnObjects: true })
    const list53 = t("course4_days_list53", { returnObjects: true })
    const list54 = t("course4_days_list54", { returnObjects: true })
    const list55 = t("course4_days_list55", { returnObjects: true })
    const list56 = t("course4_days_list56", { returnObjects: true })
    const list57 = t("course4_days_list57", { returnObjects: true })
    const list58 = t("course4_days_list58", { returnObjects: true })
    const list59 = t("course4_days_list59", { returnObjects: true })
    const list60 = t("course4_days_list60", { returnObjects: true })
    const list61 = t("course4_days_list61", { returnObjects: true })
    const list62 = t("course4_days_list62", { returnObjects: true })
    const list63 = t("course4_days_list63", { returnObjects: true })
    const list64 = t("course4_days_list64", { returnObjects: true })
    const list65 = t("course4_days_list65", { returnObjects: true })
    const list66 = t("course4_days_list66", { returnObjects: true })
    const list67 = t("course4_days_list67", { returnObjects: true })
    const list68 = t("course4_days_list68", { returnObjects: true })
    const list69 = t("course4_days_list69", { returnObjects: true })
    const list70 = t("course4_days_list70", { returnObjects: true })
    const list71 = t("course4_days_list71", { returnObjects: true })
    const list72 = t("course4_days_list72", { returnObjects: true })
    const list73 = t("course4_days_list73", { returnObjects: true })
    const list74 = t("course4_days_list74", { returnObjects: true })
    const list75 = t("course4_days_list75", { returnObjects: true })
    const list76 = t("course4_days_list76", { returnObjects: true })
    const list77 = t("course4_days_list77", { returnObjects: true })
    const list78 = t("course4_days_list78", { returnObjects: true })
    const list79 = t("course4_days_list79", { returnObjects: true })
    const list80 = t("course4_days_list80", { returnObjects: true })
    const list81 = t("course4_days_list81", { returnObjects: true })
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("love_your_self") + 1]}
                        objectToSave={{
                            img: LOVE_YOUR_SELF[1].img,
                            value: 'love_your_self',
                            route: '/days',
                            index: 1
                        }}
                        uuid={"love_your_self1"}
                        route={"/courses/love-your-self"}
            />
        <div className="child-container">
            <div className="child-container__red-title typography-20">
                {subtitle}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list3.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle3}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list4.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__list">
                <ul>
                    {list5.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list6.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle5}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note1}
                </div>
            </div>
            <div className="child-container__list">
                <ul>
                    {list7.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle6}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note2}
                </div>
            </div>
            <div className="child-container__list">
                <ul>
                    {list8.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list9.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle7}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list10.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle8}
            </div>
            <div className="child-container__list">
                <ul>
                    {list11.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list12.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle9}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[3]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list13.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle10}
            </div>
            <div className="child-container__list">
                <ul>
                    {list14.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list15.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle11}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[4]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list16.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle12}
            </div>
            <div className="child-container__list">
                <ul>
                    {list17.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle13}
            </div>
            <div className="child-container__list">
                {list18.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle14}
            </div>
            <div className="child-container__list">
                {list19.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle15}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[5]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list20.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle16}
            </div>
            <div className="child-container__list">
                <ul>
                    {list21.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list22.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle17}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[6]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list23.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle18}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[7]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list24.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle19}
            </div>
            <div className="child-container__list">
                <ul>
                    {list25.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list26.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle20}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[8]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list27.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle21}
            </div>
            <div className="child-container__list">
                <ul>
                    {list28.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list28_1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle22}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[9]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list29.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle23}
            </div>
            <div className="child-container__list">
                <ul>
                    {list30.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list31.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle24}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[10]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list32.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle25}
            </div>
            <div className="child-container__list">
                <ul>
                    {list33.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list34.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle26}
            </div>
            <div className="child-container__list">
                <ul>
                    {list35.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle27}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[11]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list36.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle28}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[12]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list37.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle29}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle30}
            </div>
            <div className="child-container__list">
                <ul>
                    {list38.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle31}
            </div>
            <div className="child-container__list">
                <ul>
                    {list39.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle32}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[13]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list40.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle33}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[14]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list41.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle34}
            </div>
            <div className="child-container__list">
                <ul>
                    {list42.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle35}
            </div>
            <div className="child-container__list">
                <ul>
                    {list43.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list44.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle36}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[15]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list45.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle37}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[16]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list46.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle38}
            </div>
            <div className="child-container__list">
                <ul>
                    {list47.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list48.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle39}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[17]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list49.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle40}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[18]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list50.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle41}
            </div>
            <div className="child-container__list">
                <ul>
                    {list51.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle42}
            </div>
            <div className="child-container__list">
                <ul>
                    {list52.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article4}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle43}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[19]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list53.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle44}
            </div>
            <div className="child-container__list">
                <ul>
                    {list54.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list55.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle45}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[20]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list56.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle46}
            </div>
            <div className="child-container__list">
                <ul>
                    {list57.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list58.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle47}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[21]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list59.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle48}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[22]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list60.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle49}
            </div>
            <div className="child-container__list">
                <ul>
                    {list61.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list62.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle50}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[23]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list63.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle51}
            </div>
            <div className="child-container__list">
                <ul>
                    {list64.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article5}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle52}
            </div>
            <div className="child-container__list">
                <ul>
                    {list65.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list66.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle53}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[24]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list67.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle54}
            </div>
            <div className="child-container__list">
                <ul>
                    {list68.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list69.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle55}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[25]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list70.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle56}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[26]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list71.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle57}
            </div>
            <div className="child-container__list">
                <ul>
                    {list72.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list73.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle58}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[27]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list74.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle59}
            </div>
            <div className="child-container__list">
                <ul>
                    {list75.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list76.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle60}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[28]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list77.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle61}
            </div>
            <div className="child-container__list">
                <ul>
                    {list78.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article6}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle62}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[29]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list79.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle63}
            </div>
            <div className="child-container__list">
                <ul>
                    {list80.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list81.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note3}
                </div>
            </div>
        </div>
            </>
    )
}

export default Sms