import {useTranslation} from "react-i18next";
import { zones6Src} from "./constants";

const During = () => {
    const { t } = useTranslation('app');
    const list = t("course2_erogenous_list7", { returnObjects: true })
    const subText1 = t("course2_erogenous_title6")
    const note = t("course2_erogenous_note1")
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={zones6Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default During