import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";
import {useNavigate} from "react-router";
import '../../../components/TabsHeader/style.scss'
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import { isEmptyInput} from "../../../utils/login.utils";
import {FormErrorMessages} from "../../../constants/logInErrorMessages.constants";
import { changeUsername} from "../../../utils/firebase/auth/createUser.utils";
import {SUCCESS_CHANGE_ROUTE} from "../../../constants/routes.constants";
import {Button} from "components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import { Input } from "components/lego/Input/Input";
const USERNAME_ID='username'
const EditNickname = () => {
    const navigate = useNavigate()
    const { t } = useTranslation('onboarding');
    const app = useTranslation('app');
    const [username,setUsername]=useState('')
    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()

    const onSend=()=>{

        if(!isEmptyInput(username)) {
            setErrorState({message:t(FormErrorMessages.EMPTY_USERNAME),errorInputId:USERNAME_ID})
            return;
        }
        changeUsername(username,  () => navigate(SUCCESS_CHANGE_ROUTE))
    }

    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }
    return (
        <div  className="change-password-wrapper">
            <div className="tabs-header-container row-container space-between align-center">
                <ArrowIcon className="tabs-header-container__back" onClick={() => navigate(-1)} />
                <div className="tabs-header-container__title typography-14 typography-bold">
                    {app.t("change_nickname")}
                </div>
                <div/>
            </div>
            <ContentContainer>
                <div className={'registrationForm'}>

                    <Input onChange={(value)=>clearErrorData(value,setUsername)}
                           id={USERNAME_ID}
                           inputType={'text'}
                           labelText={t("createAccountScreen.username.label")}
                           placeholderText={t("createAccountScreen.username.placeholder")}
                           errorMessage={errorState.errorInputId===USERNAME_ID?errorState.message:''}
                    />
                </div>
            </ContentContainer>
                <Button className="btn-change-password" onClick={onSend} text={app.t("save_new_nickname")}
                        buttonTheme={ButtonThemes.DARK} width={100}
                />
        </div>
    )
}

export default EditNickname