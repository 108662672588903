import {useTranslation} from "react-i18next";
import {maleErogenousZones2Src} from "./constants";

export const SimulateAllZones = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_male_erogenous_zones_subtitle1")
    const article2 = t("course2_male_erogenous_zones_article2")
    const list = t("course2_male_erogenous_zones_list1", { returnObjects: true })
    const list1 = t("course2_male_erogenous_zones_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={maleErogenousZones2Src} alt="course-preview" />
            </div>
        </div>
    )
}