import {useTranslation} from "react-i18next";
import {src7} from "./constants";

const Surprise = () => {
    const { t } = useTranslation('app');
    const article = t("sex_games_article6")
    const subtitle = t("sex_games_subtitle6")
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src7} alt="course-preview" />
            </div>
        </div>
    )
}

export default Surprise