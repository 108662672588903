import cn from "classnames";
import {Bar} from "./Bar";
import './styles.scss'
import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";
import {useNavigate} from "react-router";
type TProps = {
    tab: string,
    routes: { tab: number}[],
    routeToNavigate: string
}
const TabsFooter = ({tab, routes, routeToNavigate}:TProps) => {
    const nav = useNavigate()
    const handleLeft = () => {
        if(tab === '1') return
        nav(routeToNavigate + '/'+ (Number(tab) - 1))
    }
    const handleRight = () => {
        if (tab === routes.length.toString()) return
        nav(routeToNavigate + '/'+ (Number(tab) + 1))
    }
    return (
        <div className="tabs-footer">
            <div className="tabs-footer__arrows">
                <div className={cn("tabs-footer__arrows__left", {inactive: tab === '1'})} onClick={handleLeft}>
                    <ArrowIcon />
                </div>
                <div className={cn("tabs-footer__arrows__right", {inactive: tab === routes.length.toString()})} onClick={handleRight}>
                    <ArrowIcon />
                </div>
            </div>
            <div className="tabs-footer__bars">
                {routes.map((i) => {
                    return <Bar
                        key={i.tab}
                        route={routeToNavigate + '/' + i.tab}
                        status={Number(i.tab) <= Number(tab) ? "filled" : "unfilled"} />
                })}
            </div>
        </div>
    )
}

export default TabsFooter