export const imgUrl = [
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.1.png?alt=media&token=e15a07b1-fbec-4df3-8141-c7dc5a396e39",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.2.png?alt=media&token=e2647fc8-749c-4fdc-922b-3d7596cea899",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.3.png?alt=media&token=c3ac1a06-0cf2-4cd6-a48c-497a10e70f04",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.4.png?alt=media&token=27e99c5a-c014-4756-8e61-e105a2c50f36",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.5.png?alt=media&token=49fe5820-4fbc-49ba-b913-a2c8b42e6a8a",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.6.png?alt=media&token=12791f8c-8e28-4e56-abfb-40caf5a3f34a",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.7.png?alt=media&token=44d05a0f-c252-452c-a7be-f1343762421b",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.8.png?alt=media&token=3bd39bbf-da42-44c8-a192-291c8b6c84a4",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.9.png?alt=media&token=f01a8e0a-a420-45bc-8871-3f283083702f",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.10.png?alt=media&token=ad6c0755-cd5f-42e4-a1e1-69b31567aa2b",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.11.png?alt=media&token=04af0635-cf3d-439d-8539-143c5cc386ef",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.12.png?alt=media&token=fcf558f8-a3b0-43d3-9423-c48fc244bf7c",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.13.png?alt=media&token=374e33c1-07b3-4d48-abd0-60203232936b",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.14.png?alt=media&token=6bbd0f99-2e9e-4214-956c-f064d4468432",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.15.png?alt=media&token=24aa9cb2-eaf0-41f3-9f46-9fc097ee01bc",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.16.png?alt=media&token=55aab983-a3c7-4ece-8496-4ebde4704317",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.17.png?alt=media&token=e5cb185c-d186-49b1-86e2-fd54ff7eae69",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.18.png?alt=media&token=7543c412-ff50-49b0-b779-754afb83e300",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.19.png?alt=media&token=4d268a66-9a7e-47f9-a97e-6fce93fd5458",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.20.png?alt=media&token=f6de54aa-d813-40df-8fb7-e5930d6452c1",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.21.png?alt=media&token=1411c5d0-44f7-4efb-9f0b-a49376fb4338",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.22.png?alt=media&token=111005a9-7ba5-4d59-aade-a267897adb50",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.23.png?alt=media&token=1844b7eb-35fc-4c83-b215-2d5656d2ccaf",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.24.png?alt=media&token=20115b34-21a1-4005-b06a-41d97c8656d6",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.25.png?alt=media&token=ad87c9a9-f04c-401b-9f24-50dfa13799cd",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.26.png?alt=media&token=911e9e8a-2b95-49df-883b-b508904795e9",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.27.png?alt=media&token=ebae4d0d-f742-49ee-9f8b-a32f86c4becf",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.28.png?alt=media&token=6840fbd6-c5d6-48cd-b3ff-849cde82959b",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.29.png?alt=media&token=2814110d-8cb9-49f7-a10d-37894552795b",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fdays%2F4.1.30.png?alt=media&token=c0d81507-34aa-4e6b-ba66-0ecd25309ea6",
]