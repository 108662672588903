export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
]

export const kissingTechniques1Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques1.png?alt=media&token=e7dcb26d-382d-4389-bad2-ec4343ab057c"
export const kissingTechniques2Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques2.png?alt=media&token=512ef398-aaf2-4793-833a-070125969b50"
export const kissingTechniques3Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques3.png?alt=media&token=98cf8bc3-a313-4774-b31f-159a83605eb1"
export const kissingTechniques4Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques4.png?alt=media&token=26e4d0f2-f57f-4ca5-8a01-667a4ac2f3b3"
export const kissingTechniques5Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques5.png?alt=media&token=d9f3376d-5ec8-4734-b9ef-f2f627b8e33a"
export const kissingTechniques6Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques6.png?alt=media&token=8a00c71f-3c80-4810-b462-2ee1d816609f"
export const kissingTechniques7Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques7.png?alt=media&token=a12c34e8-5ce2-406a-a96a-b39908829339"
export const kissingTechniques8Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques8.png?alt=media&token=4caec5f4-411c-44c1-af34-475a0ce47762"
export const kissingTechniques9Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FKissingTechniques%2FKissingTechniques9.png?alt=media&token=2f55adca-7639-4b31-984d-c803f532ef2d"




