import {useTranslation} from "react-i18next";
import {ways13Src} from "./constants";

const SocialSurvey = () => {
    const { t } = useTranslation('app');
    const subTitle = t("ways_13_title")
    const list = t("ways_13_article", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways13Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default SocialSurvey