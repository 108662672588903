export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games1.png?alt=media&token=2505f069-a859-485f-be9b-8751d13ac0cf"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games2.png?alt=media&token=ca3e1718-c9b2-4e9b-95e9-c49e22666018"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games3.png?alt=media&token=465d3c59-f786-4367-be1b-a67139fe4546"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games4.png?alt=media&token=a47e4e1b-780b-4260-ae40-9f0315aa57e9"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games5.png?alt=media&token=55680499-f961-4ddd-a725-006573519c74"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games6.png?alt=media&token=cd544970-5321-44db-87f3-deb4e140d1f7"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games7.png?alt=media&token=65c9ffa6-8b18-4321-af2f-e4a0d22f9834"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games8.png?alt=media&token=4578f100-4fdb-4d5a-a3e7-65e4ad4e66a6"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-games%2Fsex-games9.png?alt=media&token=640563f6-a180-4666-b809-232c3622af06"


