import {useTranslation} from "react-i18next";
import {src1} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const article = t("course3_guest_article1")
    const article1 = t("course3_guest_article2")
    return(
        <div className="child-container">
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro