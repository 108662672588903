export const firstLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Flight%2Flayer2light-1.png?alt=media&token=880c2f3e-4c5e-4595-a680-0bf54288cfc2'
export const secondLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Flight%2Fg23465light-2.png?alt=media&token=1f4f55c9-e4d3-4f74-ae99-2cfeccec6aca'
export const thirdLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Flight%2Flayer2light-3.png?alt=media&token=ea8b9165-a8b4-49df-ac4d-7aca6c7ed1c9'
export const fourthLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Flight%2Flayer2light-4.png?alt=media&token=aa1b24a1-78b4-4567-9824-94b9d1c488c9'
export const fifthLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Flight%2Flayer2light-5.png?alt=media&token=2a3f1980-abdd-4530-a572-9a423ee75bcb'
export const sixLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Flight%2Flayer2light-6.png?alt=media&token=cc913032-9813-4db4-87d6-9356c7887796'
export const sevenLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Flight%2Flayer2light-7.png?alt=media&token=1760fd48-1ae2-4447-bb97-7c8a831820b0'
export const eightLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Flight%2Flayer2light-8.png?alt=media&token=a6cd26dd-f738-403b-9ac1-b5b9149bd9f5'
export const firstDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Fdark%2Flayer-dark-1.png?alt=media&token=b51c6bae-1623-4139-a998-373296095eef'
export const secondDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Fdark%2Fg23465layer-dark-2.png?alt=media&token=12d97c5c-6958-4107-80ab-dddf20bec405'
export const thirdDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Fdark%2Flayer-dark-3.png?alt=media&token=150b9530-fadf-4879-95d0-0f3a59bc2285'
export const fourthDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Fdark%2Flayer-dark-4.png?alt=media&token=e617b279-e092-4097-94c0-df380d5f48a9'
export const fifthDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Fdark%2Flayer-dark-5.png?alt=media&token=6f0b3f9f-4262-491e-b835-317b417372bf'
export const sixDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Fdark%2Flayer2layer-dark-8.png?alt=media&token=4e2c2544-edef-4421-ac11-00f094ce91ac'
export const sevenDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Fdark%2Flayer-dark-6.png?alt=media&token=bb69da26-96f8-48b0-8567-1eac7ec4b0e2'
export const eightDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2Fdark%2Flayer-dark-7.png?alt=media&token=9ffcbff2-097c-4439-aa36-e087add4cd92'