import {useTranslation} from "react-i18next";
import {src32, src33, src34, src35, src36, src37, src38, src39, src40, src41, src42, src43, src44, src45, src46} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";

export const BlowJob = () => {
    const { t } = useTranslation('app');
    const titleMain = t("path_to_pleasure_course")
    const title1 = t("course3_techniques_title41")
    const subtitle42 = t("course3_techniques_title42")
    const subtitle43 = t("course3_techniques_title43")
    const subtitle44 = t("course3_techniques_title44")
    const subtitle45 = t("course3_techniques_title45")
    const subtitle46 = t("course3_techniques_title46")
    const subtitle47 = t("course3_techniques_title47")
    const subtitle48 = t("course3_techniques_title48")
    const subtitle49 = t("course3_techniques_title49")
    const subtitle50 = t("course3_techniques_title50")
    const subtitle51 = t("course3_techniques_title51")
    const subtitle52 = t("course3_techniques_title52")
    const subtitle53 = t("course3_techniques_title53")
    const subtitle54 = t("course3_techniques_title54")
    const subtitle55 = t("course3_techniques_title55")
    const article40 = t("course3_techniques_article40")
    const article41 = t("course3_techniques_article41")
    const article42 = t("course3_techniques_article42")
    const article43 = t("course3_techniques_article43")
    const article44 = t("course3_techniques_article44")
    const article45 = t("course3_techniques_article45")
    const article46 = t("course3_techniques_article46")
    const article47 = t("course3_techniques_article47")
    const article48 = t("course3_techniques_article48")
    const article49 = t("course3_techniques_article49")
    const article50 = t("course3_techniques_article50")
    const article51 = t("course3_techniques_article51")
    const article52 = t("course3_techniques_article52")
    const article53 = t("course3_techniques_article53")
    const article54 = t("course3_techniques_article54")

    return(
        <>
        <TabsHeader title={titleMain} />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle42}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article40}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src32} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle43}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article41}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src33} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle44}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article42}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src34} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle45}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article43}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src35} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle46}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article44}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src36} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle47}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article45}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src37} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle48}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article46}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src38} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle49}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article47}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src39} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle50}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article48}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src40} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle51}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article49}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src41} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle52}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article50}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src42} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article51}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src43} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle53}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article52}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src44} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle54}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article53}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src45} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle55}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article54}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src46} alt="course-preview" />
            </div>
        </div>
            </>
    )
}