import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './BoredomDiscussionScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
export const BoredomDiscussionScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <h4 className={'boredomDiscussionTitle'}>{t("boredomDiscussionScreen.title")}</h4>
                <p className={'boredomDiscussionText'}>{t("boredomDiscussionScreen.text")}</p>
                <h6 className={'boredomDiscussionQuestion'}>{t("boredomDiscussionScreen.discussionResult")}</h6>
            </ContentContainer>
            <NextRouteButton option={t("boredomDiscussionScreen.agreeButtonText")}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'boredomDiscussionAgreeButton'} />
        </>
    )
}
