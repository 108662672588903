export const selfieSrc = [
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fselfie1.png?alt=media&token=2fcdc4ba-c487-49c2-a4d0-f8613773acf9",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fselfie2.png?alt=media&token=81cbcd59-480f-4f79-bc37-13083b68d8d5"
]

export const chairSrc = [
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fchair1.png?alt=media&token=b217d5a3-ce29-44c4-aea3-49b192b3ae6e",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fchair2.png?alt=media&token=bd10c54c-a395-4356-bc50-59b5c7ae492e",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fchair3.png?alt=media&token=9edd1fc3-27a0-4e35-a9fb-d1ff1ea4dbe0",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fchair4.png?alt=media&token=b5258cf1-c9ce-4cb3-bd6a-3a0b15926527",
]

export const standingSrc = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fstanding.png?alt=media&token=f0af0626-8e5c-41ed-afb4-65db30d9166e"
export const floorSrc = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Ffloor1.png?alt=media&token=234bd780-15ca-4da3-9c93-471c5dbd995f"
export const wallSrc = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Ffloor.png?alt=media&token=eb98e88e-4e04-45f9-80f5-b622fd1e6321"
export const portraitSrc = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fportrait.png?alt=media&token=78c9762e-41ce-4db3-bb22-0d41755e3649"

export const pairSrc = [
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpair.png?alt=media&token=799abee1-2cd3-456f-b07e-f1be10d7f568",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpair1.png?alt=media&token=1b21f20f-0850-4924-822d-ba5e43d5a3e1",
]

export const inspireSrc = [
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Finspire1.png?alt=media&token=e4165cfe-01e5-4f2d-8525-e186df3d1211",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Finspire2.png?alt=media&token=f34c94d5-50c4-4750-9853-8ef237ac3120",
]

