import {useEffect, useState} from "react";

export interface IFormErrorState {
    message:string,
    errorInputId: string
}

export const useLogInErrorLogic = ()=>{
    const [errorState,setErrorState]=useState<IFormErrorState>({message:'',errorInputId:''})
    const clearErrorMessage=()=>setErrorState((errorData)=>({errorInputId:'', message:''}))

    useEffect(()=>{
        if(errorState.message) {
            const errorInput = document.getElementById(errorState.errorInputId);
            if(errorInput) {
                errorInput!.classList.add('inputErrorBoundary')
            }
        } else {
            if(errorState.errorInputId) {
                document.getElementById(errorState.errorInputId)!.classList.remove('inputErrorBoundary')
            }
        }
    },[errorState])

    return {clearErrorMessage,errorState, setErrorState};
}
