import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import {Tentative} from "./Tentative";
import Intro from "./Intro";
import {Immediate} from "./Immediate";
import { Slippery } from "./Slippery";
import { French } from "./French";
import { Triangle } from "./Triangle";
import { Sucking } from "./Sucking";
import { Cold } from "./Cold";
import { Sweet } from "./Sweet";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PATH_TO_PLEASURE} from "../../../CourseItem/contants";
const KissingTechniques = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("path_to_pleasure_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 10]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[10].img,
                            value: 'path_to_pleasure',
                            route: '/kissing-techniques/1',
                            index: 10
                        }}
                        uuid={"path_to_pleasure10"}
                        route={"/courses/path_to_pleasure"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Tentative />} />
                <Route path={'/3'} element={<Immediate />} />
                <Route path={'/4'} element={<Slippery />} />
                <Route path={'/5'} element={<French />} />
                <Route path={'/6'} element={<Triangle />} />
                <Route path={'/7'} element={<Sucking />} />
                <Route path={'/8'} element={<Cold />} />
                <Route path={'/9'} element={<Sweet />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.KISSING_TECHNIQUES}  />
        </>
    )
}

export default KissingTechniques