import {Link} from "react-router-dom";
import {Button} from "../lego/Button/Button";
import {ButtonThemes} from "../../constants/button.constants";
import mixpanel from "../../mixpanel";
interface INextRouteButtonProps {
    option: string;
    route: string;
    buttonTheme: ButtonThemes;
    className:string;
    onClick?: ()=>void;
    isDisabled?: boolean;
    notLink?: boolean
}
export const NextRouteButton =({option,buttonTheme,route,onClick,className, isDisabled, notLink}:INextRouteButtonProps)=>{
        return <div className={className}>
            {!!notLink ?
                <Button isDisabled={isDisabled? isDisabled: false}
                        onClick={onClick} text={option}
                        buttonTheme={buttonTheme} width={100} />
                :     <Link style={{textDecoration:'none'}} to={route}>
                    <Button isDisabled={isDisabled? isDisabled: false}
                            onClick={onClick} text={option}
                            buttonTheme={buttonTheme} width={100} />
                </Link>}


        </div>
}
