import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import {Part2} from "./Part2";
import {Pinch} from "./Pinch";
import {Bud} from "./Bud";
import Intro from "./Intro";
import { Elbow } from "./Elbow";
import { Heartbeat } from "./Heartbeat";
import {Degree} from "./Degree";
import { Ok } from "./Ok";
import {Carousel} from "./Carousel";
import { Palms } from "./Palms";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PATH_TO_PLEASURE} from "../../../CourseItem/contants";
const ManualTechniques = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("path_to_pleasure_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 17]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[17].img,
                            value: 'path_to_pleasure',
                            route: '/manual-techniques/1',
                            index: 17
                        }}
                        uuid={"path_to_pleasure17"}
                        route={"/courses/path_to_pleasure"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro/>}/>
                <Route path={'/2'} element={<Part2/>}/>
                <Route path={'/3'} element={<Bud/>}/>
                <Route path={'/4'} element={<Pinch/>}/>
                <Route path={'/5'} element={<Elbow/>}/>
                <Route path={'/6'} element={<Heartbeat/>}/>
                <Route path={'/7'} element={<Degree/>}/>
                <Route path={'/8'} element={<Ok/>}/>
                <Route path={'/9'} element={<Carousel/>}/>
                <Route path={'/10'} element={<Palms/>}/>
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.MANUAL_TECHNIQUES}/>
        </>
    );
}

export default ManualTechniques