import {useEffect, useRef} from "react";

export const useAppContainerWidth = ()=>{
    const appContainerWidth=useRef(0)

    useEffect(()=>{
        const appContainerEl=document.getElementsByClassName('appContainer')[0]
        appContainerWidth.current = appContainerEl?.getBoundingClientRect().width
    },[])

    return appContainerWidth.current;
}
