import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import {approach1Src} from "./contstants";
const Scheme = () => {
    const { t } = useTranslation('app');
    const title = t("approaches_title")
    const subTitle = t("approaches_subtitle")
    const article = t("approaches_scheme_title")
    const list = t("approaches_scheme_content", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {subTitle}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {article}
            </div>
            <div className="child-container__list">
                    {list?.map((i: string, idx: number) => <div key={idx} className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={approach1Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Scheme