export const MaintainConversationRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
]

export const maintain1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fmaintain1.png?alt=media&token=3c329e7e-efe8-4ae5-83a7-db265e34f662"
export const maintain2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fmaintain2.png?alt=media&token=dea5d20e-0101-4d4a-8e9a-68a8b90a4a64"
export const maintain3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fmaintain3.png?alt=media&token=ef353b27-f771-4627-8f7a-9fd0e028e319"
export const maintain4Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fmaintain4.png?alt=media&token=795edce4-c972-4edb-a6fd-91bbd6516f13"
export const maintain5Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fmaintain5.png?alt=media&token=6aac7634-2e33-4970-894a-41bd632551b9"
export const maintain6Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fmaintain6.png?alt=media&token=3df47892-bc99-44cd-9723-b2551bb008e6"