import './Timer.scss';
import {useEffect, useState} from "react";
import {getTimerFromStorage, setTimerToStorage} from "../../utils/localStorage/timer.utils";
export const Timer =({duration, className, withoutHours}: { duration:number, className?:string, withoutHours?: boolean })=>{
    const [time,setTime]=useState(getTimerFromStorage()||duration)

    useEffect(() => {
        setTime(duration)
    }, [])

    const setNumberFormat=(timePart:number)=>{
        return timePart < 10 ? `0${timePart}` : timePart
    }
    const getFormattedTime=(milliseconds:number)=>{
        const totalSeconds=parseInt(String(Math.floor(milliseconds/ 1000)))
        const totalMinutes=parseInt(String(Math.floor(totalSeconds/ 60)))
        const totalHours=parseInt(String(Math.floor(totalMinutes/ 60)))

        let seconds=parseInt(String(totalSeconds % 60))
        let minutes=parseInt(String(totalMinutes % 60))
        let hours=parseInt(String(totalHours % 24))

        if(withoutHours) {
            return `${setNumberFormat(minutes)}.${setNumberFormat(seconds)}`
        } else {
            return `${setNumberFormat(hours)}.${setNumberFormat(minutes)}.${setNumberFormat(seconds)}`
        }


    }

    useEffect(()=>{
        let timerId: ReturnType<typeof setTimeout>;
        timerId = setTimeout(()=>
                setTime((time)=>time-1000)
            ,1000)

        if (time<=0){
            setTime(0)
            clearTimeout(timerId)
        }

       return ()=> {
           clearTimeout(timerId);
           setTimerToStorage(time)
       }
    },[time])

    return (
        <div className={`timerCountdown ${className}`}>
            {getFormattedTime(time)}
        </div>
    )
}
