import {useTranslation} from "react-i18next";
import {src1, src10, src11, src12, src13, src14, src15, src16, src17, src2, src3, src4, src5, src6, src7, src8, src9} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("course3_tests_title1")
    const subtitle = t("course3_tests_title2")
    const subtitle1 = t("course3_tests_title3")
    const subtitle5 = t("course3_tests_title5")
    const subtitle6 = t("course3_tests_title6")
    const subtitle7 = t("course3_tests_title7")
    const subtitle8 = t("course3_tests_title8")
    const subtitle2 = t("course3_tests_title4")
    const subtitle9 = t("course3_tests_title9")
    const subtitle10 = t("course3_tests_title10")
    const subtitle11 = t("course3_tests_title11")
    const subtitle12 = t("course3_tests_title12")
    const subtitle13 = t("course3_tests_title13")
    const subtitle14 = t("course3_tests_title14")
    const subtitle15 = t("course3_tests_title15")
    const subtitle16 = t("course3_tests_title16")
    const subtitle17 = t("course3_tests_title17")
    const subtitle18 = t("course3_tests_title18")
    const subtitle19 = t("course3_tests_title19")
    const subtitle20 = t("course3_tests_title20")
    const subtitle21 = t("course3_tests_title21")
    const subtitle22 = t("course3_tests_title22")
    const subtitle23 = t("course3_tests_title23")
    const subtitle24 = t("course3_tests_title24")
    const subtitle25 = t("course3_tests_title25")
    const subtitle26 = t("course3_tests_title26")
    const subtitle27 = t("course3_tests_title27")
    const subtitle28 = t("course3_tests_title28")
    const subtitle29 = t("course3_tests_title29")
    const subtitle30 = t("course3_tests_title30")
    const subtitle31 = t("course3_tests_title31")
    const subtitle32 = t("course3_tests_title32")
    const subtitle33 = t("course3_tests_title33")
    const subtitle34 = t("course3_tests_title34")
    const subtitle35 = t("course3_tests_title35")
    const article1 = t("course3_tests_article1")
    const article2 = t("course3_tests_article2")
    const article3 = t("course3_tests_article3")
    const article4 = t("course3_tests_article4")
    const list = t("course3_tests_list1", { returnObjects: true })
    const list1 = t("course3_tests_list2", { returnObjects: true })
    const list3 = t("course3_tests_list3", { returnObjects: true })
    const list4 = t("course3_tests_list4", { returnObjects: true })
    const list5 = t("course3_tests_list5", { returnObjects: true })
    const list6 = t("course3_tests_list6", { returnObjects: true })
    const list7 = t("course3_tests_list7", { returnObjects: true })
    const list8 = t("course3_tests_list8", { returnObjects: true })
    const list9 = t("course3_tests_list9", { returnObjects: true })
    const list10 = t("course3_tests_list10", { returnObjects: true })
    const list11 = t("course3_tests_list11", { returnObjects: true })
    const list12 = t("course3_tests_list12", { returnObjects: true })
    const list13 = t("course3_tests_list13", { returnObjects: true })
    const list14 = t("course3_tests_list14", { returnObjects: true })
    const list15 = t("course3_tests_list15", { returnObjects: true })
    const list16 = t("course3_tests_list16", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src2} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle5}
            </div>
            <div className="child-container__list">
                {list3.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle6}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src3} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle7}
            </div>
            <div className="child-container__list">
                {list4.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle8}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src4} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle9}
            </div>
            <div className="child-container__list">
                {list5.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle10}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src5} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle11}
            </div>
            <div className="child-container__list">
                {list6.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle12}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src6} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle13}
            </div>
            <div className="child-container__list">
                {list7.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle14}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src7} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle15}
            </div>
            <div className="child-container__list">
                {list8.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle16}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src8} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle17}
            </div>
            <div className="child-container__list">
                {list9.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle18}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src9} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle19}
            </div>
            <div className="child-container__list">
                {list10.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle20}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src10} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle21}
            </div>
            <div className="child-container__list">
                {list11.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle22}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src11} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle23}
            </div>
            <div className="child-container__list">
                {list12.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle24}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src12} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle25}
            </div>
            <div className="child-container__list">
                {list13.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle26}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src13} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle27}
            </div>
            <div className="child-container__list">
                {list14.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle28}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src14} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle29}
            </div>
            <div className="child-container__list">
                {list15.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle30}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src15} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle31}
            </div>
            <div className="child-container__list">
                {list16.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle32}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle33}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src16} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle34}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article3}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle35}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article4}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src17} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro