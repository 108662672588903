export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-2%2F5.2.1.png?alt=media&token=2eba46b2-7c4f-4646-be1f-3d474fc7b05b",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-2%2F5.2.2.png?alt=media&token=ba5621fc-bbdd-4035-9e29-ec4f53290445",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-2%2F5.2.3.png?alt=media&token=cb3a7a49-ca95-4d3b-9bec-01089ac65105",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-2%2F5.2.4.png?alt=media&token=4f3e1319-f743-42be-aa23-e5cabadcae44",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-2%2F5.2.5.png?alt=media&token=14e052af-2433-4d47-acc0-8a1979e9f917"
    ]



