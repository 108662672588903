export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fthreesome%2Fthreesome1.png?alt=media&token=4b386fa7-a8fd-4257-9430-483db9dab732"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fthreesome%2Fthreesome2.png?alt=media&token=045f4705-694c-47a3-af3a-b578af41bca7"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fthreesome%2Fthreesome3.png?alt=media&token=f9c0e55c-3a5a-4b89-ac2e-3bb2dbfd10b1"
