import {ReactComponent as CheckIcon} from "assets/app/check.svg";
import {ReactComponent as LogoIcon} from "assets/app/logo.svg";
import {useNavigate} from "react-router";
import '../../../components/TabsHeader/style.scss'
import {useTranslation} from "react-i18next";
import { PROFILE_ROUTE} from "../../../constants/routes.constants";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import './styles.scss';

const SuccessNickName = () => {
    const navigate = useNavigate()
    const { t } = useTranslation('app');

    const onSend=()=>{
        navigate(PROFILE_ROUTE)
    }

    return (
        <div  className="change-password-wrapper success-page">
            <div className="success-container__header">
                <LogoIcon />
            </div>
            <div className="success-container">
                <div className="success-container__icon">
                    <CheckIcon />
                </div>
                <div className="success-container__title typography-32 typography-500">
                    {t("done")}
                </div>
                <div className="success-container__subtitle typography-14">
                    {t("you_successfully_changed_the_nick")}
                </div>
            </div>
            <Button className="btn-change-password" onClick={onSend} text={t("back_to_profile")} buttonTheme={ButtonThemes.DARK} width={100}/>
        </div>
    )
}

export default SuccessNickName