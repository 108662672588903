import {useTranslation} from "react-i18next";
import {src5} from "./constants";

export const Dreams = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_scenarios_subtitle4")
    const article = t("course2_scenarios_article4")
    const article1 = t("course2_scenarios_article5")
    const list = t("course2_scenarios_list10", { returnObjects: true })
    const list1 = t("course2_scenarios_list11", { returnObjects: true })
    const list2 = t("course2_scenarios_list12", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src5} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
    )
}