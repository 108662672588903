export const soundsRules = [
    {
        tab: 1,
    },
    {
        tab: 2,
    }
]

export const sounds1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsounds%2Fsounds1.png?alt=media&token=8bc5cbb6-7eef-4efc-bdb2-c3450b2a7f04"
export const sounds2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsounds%2Fsounds2.png?alt=media&token=aefe0962-f99d-48d1-899d-09fcb723fb90"
export const sounds3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsounds%2Fsounds3.png?alt=media&token=eae12215-b8ce-403b-a121-0755c77a8764"