import {useTranslation} from "react-i18next";
import {src3} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_bdsm_subtitle1")
    const subtitle1 = t("course2_bdsm_subtitle2")
    const subtitle2 = t("course2_bdsm_subtitle3")
    const article = t("course2_bdsm_article2")
    const article1 = t("course2_bdsm_article3")
    const article2 = t("course2_bdsm_article4")
    const article3 = t("course2_bdsm_article5")
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src3} alt="course-preview" />
            </div>
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article2}
            </div>
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article3}
            </div>
        </div>
    )
}