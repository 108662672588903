import {useTranslation} from "react-i18next";
import {ways2Src} from "./constants";

const SecondWay = () => {
    const { t } = useTranslation('app');
    const subTitle = t("ways_2_title")
    const article = t("ways_2_article")
    const  list= t("ways_2_list", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-16">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways2Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default SecondWay