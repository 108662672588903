import {useTranslation} from "react-i18next";
import { imgUrl } from "./constants";
export const Advanced = () => {
    const { t } = useTranslation('app');
    const title = t("course3_poses_title38")
    const subtitle = t("course3_poses_title44")
    const article =t(`course3_poses_article39`)
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            {new Array(5).fill("").map((_,idx) => {
              return <> <div className="child-container__red-title mt-24 typography-18 typography-bold">
                    {t(`course3_poses_title${39+idx}`)}
                </div>
                <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl['src'+(34+idx)]} alt="course-preview" />
                </div>
                <div className="child-container__article-title mt-16 typography-16">
            {t(`course3_poses_article${34+idx}`)}
                </div>
               </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
        </div>
    )
}