export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases1.png?alt=media&token=c5470158-c630-441a-9011-99ea00d75fa5"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases2.png?alt=media&token=e3314dc8-a8d3-47d0-9156-bfce56ee2c42"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases3.png?alt=media&token=145601e7-f333-4021-8e96-9aa13eb5691a"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases4.png?alt=media&token=9e7acc82-85b7-4ee9-b516-558738a7089d"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases5.png?alt=media&token=1a59eaa2-009b-4745-937c-a9190ab82c44"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases6.png?alt=media&token=d227d3e6-c730-4f60-9414-79ea33cd6044"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases7.png?alt=media&token=f3ab7abd-4bd5-4869-885c-0942cbf0be10"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases8.png?alt=media&token=2ef36433-2caa-4f3d-8a89-b0fbac7cacd6"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases9.png?alt=media&token=aa3fe43a-a6a0-475c-90c7-50e8ac8a65ee"
export const src10="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases10.png?alt=media&token=d10d0481-7b85-4b39-ace5-f3baafbf1d36"
export const src11="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases11.png?alt=media&token=fdd1386f-36d0-464d-80d9-656c719f09bd"
export const src12="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases12.png?alt=media&token=207f884f-59ff-419e-9442-2449f3bc4728"
export const src13="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fhot-phrases%2Fphrases13.png?alt=media&token=5112d7ab-d016-4e20-bf0c-30564992de45"


