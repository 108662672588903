import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import {AtHome} from "./AtHome";
import Intro from "./Intro";
import {AtHome2} from "./AtHome2";
import {AtHome3} from "./AtHome3";
import {AtHome4} from "./AtHome4";
import { Outdoor } from "./Outdoor";
import {Outdoor2} from "./Outdoor2";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {EMANCIPATION, PATH_TO_PLEASURE} from "../../../CourseItem/contants";
const Guest = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const titleMain = t("emancipation_course")?.split(' ')[0]
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("emancipation") + 1]}
                        objectToSave={{
                            img: EMANCIPATION[1].img,
                            value: 'emancipation',
                            route: '/guest/1',
                            index: 1
                        }}
                        uuid={"emancipation1"}
                        route={"/courses/emancipation"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<AtHome />} />
                <Route path={'/3'} element={<AtHome2 />} />
                <Route path={'/4'} element={<AtHome3 />} />
                <Route path={'/5'} element={<AtHome4 />} />
                <Route path={'/6'} element={<Outdoor />} />
                <Route path={'/7'} element={<Outdoor2 />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.GUEST}  />
        </>
    )
}

export default Guest