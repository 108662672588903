import {useTranslation} from "react-i18next";
import {src3, src4} from "./constants";

export const Vibrator = () => {
    const { t } = useTranslation('app');
    const subtitle = t("sex_toys_subtitle3")
    const subtitle1 = t("sex_toys_subtitle4")
    const article = t("sex_toys_article3")
    const list = t("sex_toys_list4", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src3} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src4} alt="course-preview" />
            </div>
        </div>
    )
}