import {useTranslation} from "react-i18next";
import { src7, src8} from "./constants";

const Gag = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_bdsm_subtitle10")
    const subtitle1 = t("course2_bdsm_subtitle11")
    const note = t("course2_bdsm_note2")
    const article2 = t("course2_bdsm_article9")
    const article3 = t("course2_bdsm_article10")
    const article4 = t("course2_bdsm_article11")
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src7} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article3}
            </div>
            <div className="child-container__red-title  mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article4}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src8} alt="course-preview" />
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
        </div>
    )
}

export default Gag