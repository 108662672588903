export const imgUrl = [
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.1.png?alt=media&token=0c89892a-543e-4fa8-8faa-8e8b67aba8c6",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.2.png?alt=media&token=57b159c4-cc6c-4ce9-b385-47a638e1c86d",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.3.png?alt=media&token=12b1d4d6-893c-4a52-a84f-a56aedf03924",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.4.png?alt=media&token=59e76c6b-b211-4a81-8b1a-21a44cb9edfd",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.5.png?alt=media&token=b16fe2c7-2130-43a1-82dc-bbbc21789b4b",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.6.png?alt=media&token=1b46168b-a670-40ae-9305-b622679cc4de",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.7.png?alt=media&token=22a2353b-54bd-48df-8318-05cdb7a65a41",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.8.png?alt=media&token=e6055e02-0fd9-404c-9500-fb04a6725681",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.9.png?alt=media&token=dd5e8cd8-9928-4d41-bc61-0bbf76c6374f",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.10.png?alt=media&token=5933784a-1678-40b3-97b1-2186bc4fa919",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.11.png?alt=media&token=a81e2ab3-9299-4fae-93ad-3c225b22ea5c",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.12.png?alt=media&token=ebe269bc-23ab-464f-8db3-2052fb8f3c67",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Ftruth-or-dare%2Fblow-job%2F2.1.13.png?alt=media&token=0ba5d0fd-b5d4-44d7-bfea-0f3d129b0b3a",
]