import {useTranslation} from "react-i18next";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {ButtonThemes} from "../../../constants/button.constants";
import {newLifeJourney} from "../../../assets/onBoardingImages";
import './NewLifeJourneyScreen.scss'

export const NewLifeJourneyScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={newLifeJourney} />
                <h4 className={'newLifeJourneyTitle'}>{t("newLifeScreen.title")}</h4>
            </ContentContainer>

            <NextRouteButton option={t("newLifeScreen.getStartedButtonText")}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'newLifeJourneyButton'} />
        </>
    )
}
