export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques1.png?alt=media&token=1f084730-3dc9-40e7-ba88-6cb9eb573ed5"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques2.png?alt=media&token=6439922a-a445-46b8-adeb-fc72272169a3"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques3.png?alt=media&token=875c684c-0def-4279-9dba-a571474186a9"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques4.png?alt=media&token=a344a523-fd31-4de7-8282-6039532a9995"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques5.png?alt=media&token=844deea1-cc2a-48a8-b891-65964471bb12"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques6.png?alt=media&token=24bbc8e8-b5c3-41ae-a96c-c5d9000f58cd"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques7.png?alt=media&token=bc8c5aaf-9e54-45f9-af5f-87ce60ff1605"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques8.png?alt=media&token=bd278728-33da-4921-a099-5c8d7bbb9afc"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques9.png?alt=media&token=c53a723c-c8df-4970-b904-d237c7b8ac1d"
export const src10="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques10.png?alt=media&token=c678b95c-1107-4ebe-a17a-e671ade94b67"
export const src11="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques11.png?alt=media&token=8c0018ff-b4e8-46a9-a358-1da13e020d6b"
export const src12="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques12.png?alt=media&token=e325c2dd-53df-4767-bcdb-17dcd91bfd30"
export const src13="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques13.png?alt=media&token=fd6d6600-b61c-4b6e-96ec-b2322a4e1bab"
export const src14="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques14.png?alt=media&token=e67e14ec-f994-4ee7-96a9-d77cc82f29ce"
export const src15="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques15.png?alt=media&token=cdca083c-b6a4-468c-b7b3-f952cd10ec67"
export const src16="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques16.png?alt=media&token=bab47401-2109-4cce-b97f-f305b1ca657c"
export const src17="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques17.png?alt=media&token=f3473f4a-c5aa-4bc2-a2f1-9df56bc19657"
export const src18="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques18.png?alt=media&token=b53f2db9-e427-40ab-a9a1-c21c7ab90c31"
export const src19="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques19.png?alt=media&token=a01ee15e-ae6a-4eda-b09d-4a3bb4fd79e1"
export const src20="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques20.png?alt=media&token=ea53a858-827f-459c-83c2-4bd54b8018c1"
export const src21="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques21.png?alt=media&token=2325b382-4b60-4fbd-b37b-ffefe1bd6203"
export const src22="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques22.png?alt=media&token=1c500f6c-8097-4b38-bc9f-b615473c2e86"
export const src23="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques23.png?alt=media&token=6b0fa586-3065-4748-9950-3995f7bbcd89"
export const src24="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques24.png?alt=media&token=d5437c93-0620-4f5d-a940-e6db490f16f7"
export const src25="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques25.png?alt=media&token=ddbb3f39-854e-444d-8aec-4646c47f6107"
export const src26="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques26.png?alt=media&token=87b200e2-041f-4c1e-98dd-18f8bc3a07a4"
export const src27="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques27.png?alt=media&token=79901ee7-552e-4cd9-8e53-b6a23114af2e"
export const src28="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques28.png?alt=media&token=238be782-e621-41e3-9180-c7e9c944f559"
export const src29="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques29.png?alt=media&token=f4b64cfb-7d46-4525-a14d-fa3ab1488c52"
export const src30="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques30.png?alt=media&token=ef15e973-a285-4184-b12c-ba662ce4a053"
export const src31="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques31.png?alt=media&token=407aeece-f875-4b66-ad6f-de481fc23322"
export const src32="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques32.png?alt=media&token=8fafa4ae-a0ce-4485-b5fe-51278093b91d"
export const src321="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques32-1.png?alt=media&token=91bf109f-636e-42dd-8850-afebabfdc1f9"
export const src33="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques33.png?alt=media&token=beb1ba5b-3e9a-4eda-adf3-5fdf92615299"
export const src34="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques34.png?alt=media&token=15e08898-af61-4b84-b21c-5e7344dc5678"
export const src35="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques35.png?alt=media&token=0226e5ed-752d-477c-b73f-ac98f7058464"
export const src36="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques36.png?alt=media&token=3eb579fa-9484-4672-b6f9-89bf010873de"
export const src37="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques37.png?alt=media&token=ac6bb1f4-4f07-4112-9df4-33bcbea83290"
export const src38="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques38.png?alt=media&token=138b168a-5208-4219-8507-4772e2185e31"
export const src39="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques39.png?alt=media&token=d82bc1ef-80c3-446e-ac49-29fe39f77b9e"
export const src40="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques40.png?alt=media&token=71907b6b-b3ca-4afe-9dac-cae2688b0706"
export const src41="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques41.png?alt=media&token=d4391c3e-139a-4c1f-9709-14ae93c77c3f"
export const src42="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques42.png?alt=media&token=691429cd-1a3e-485a-a328-0f2b049a6e7a"
export const src43="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques43.png?alt=media&token=a8042ab9-e5bc-4696-92a9-91e139012b44"
export const src44="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques44.png?alt=media&token=6ad97406-6454-4324-980e-943d26a88e70"
export const src45="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques45.png?alt=media&token=8c74defb-db73-41a8-80cc-58c6c1965ba2"
export const src46="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques46.png?alt=media&token=63bb611f-c2f9-40f6-a4ee-2a16849e1cc6"
export const src47="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftechniques%2Ftechniques47.png?alt=media&token=e32e0129-c6f4-458b-a505-b91a956d4590"






