import RidOfTheGag from "../screens/app/Courses/PathToPleasure/RidOfTheGag";
import {PlayWithSperm} from "../screens/app/PremiumItem/BDSM/PlayWithSperm";
import {Languages} from "../screens/app/Profile/Languages";
import Subscription from "../screens/app/Profile/Subscription";

export const START_QUIZ_ROUTE='/age-check';
export const HONEST_CHECK_ROUTE='/honest-check';
export const TEST_BEGINNING_ROUTE='/test-beginning';
export const CONFIDENT_CHECK_ROUTE='/confident-check';
export const CURRENT_RELATIONSHIP_ROUTE='/current-relationship';
export const CANDOR_CHECK_ROUTE='/candor-check';
export const RELATIONSHIP_ISSUE_ROUTE='/relationship-issue';
export const BOREDOM_DISCUSSION_ROUTE='/boredom-discussion';
export const PARTNER_OPENNESS_CHECK_ROUTE='/partner-openness-check';
export const TRUSTING_RELATIONSHIP_ROUTE='/trusting-relationship';
export const DISTANT_RELATIONSHIP_ROUTE='/distant-relationship';
export const EDUCATION_TIME_CHECK_ROUTE='/education-time-check';
export const NEW_READINESS_ROUTE='/new-readiness';
export const SEXUAL_POTENTIAL_ROUTE='/elevate-sexual-potential';
export const ENTICING_ONE_ROUTE='/be-enticing-one';
export const INTIMATE_LIFE_ROUTE='/intimate-life-check';
export const EMAIL_CHECK_ROUTE='/email-check';
export const GOOD_TEST_RESULT_ROUTE='/good-test-result';
export const AVERAGE_TEST_RESULT_ROUTE='/average-test-result';
export const UNSATISFACTORY_TEST_RESULT_ROUTE='/unsatisfactory-test-result';
export const MAIN='/main';
export const COURSES='/courses';
export const GAMES_ROUTE='/games';
export const SCENARIOS_ROUTE='/scenarios';
export const PROFILE_ROUTE='/profile';
export const LANGUAGES_ROUTE='/Languages';
export const SUBSCRIPTION_ROUTE='/subscription';
export const SUBSCRIPTION_CANCELLED_ROUTE='/subscription-cancelled';
export const SUBSCRIPTION_FAILED_ROUTE='/subscription-failed';
export const SUBSCRIPTION_SUCCESS_ROUTE='/subscription-success';
export const SUCCESS_ROUTE='/success';
export const SUCCESS_CHANGE_ROUTE='/success-change';
export const PROGRESS_ROUTE='/progress';
export const FAVORITES_ROUTE='/favourites';
export const PREMIUM_ROUTE='/premium';

export const PAY_INFO_ROUTE='/pay-info';
export const CREATE_ACCOUNT_ROUTE='/create-account';
export const SIGN_UP_OFFER_ROUTE='/sign-up-offer';
export const NEW_LIFE_JOURNEY_ROUTE='/new-life-journey';
export const LOG_IN_ROUTE='/log-in';
export const EMAIL_VERIFICATION_ROUTE='/email-verification';
export const EXISTED_EMAIL_ROUTE='/existed-email';
export const EDIT_PASSWORD_ROUTE='/edit-password';
export const EDIT_NICKNAME_ROUTE='/edit-nickname';

export const COURSE_ROUTES = {
    FIRST_DATE_RULE: '/first-date-rule',
    POSING: '/posing',
    STORIES: '/stories',
    SIGN_OF_A_WORTHY_MAN: '/sign-of-a-worthy-man',
    APPROACHES_TO_CONNECT: '/approaches-to-connect',
    MAINTAIN_CONVERSATION: '/maintain-conversation',
    PATH_CONNECTION: '/path-connection',
    BEHAVE_ON_A_DATE: '/behave-on-a-date',
    TIPS: '/tips',
    AFFIRMATIONS:'/affirmations',
    RULES_OF_THE_PERFECT_LOVER: '/rules-of-the-perfect-lover',
    ATTITUDE_TOWARDS_SEX:"/attitude-towards-sex",
    MASTURBATION: "/masturbation",
    LIBIDO:"/libido",
    SOUNDS: '/sounds',
    MISCONCEPTIONS:'/misconceptions',
    PREPARATION: '/preparation',
    EROGENOUS_ZONES:'/erogenous-zones',
    PHRASES_FOR_HOT_MESSAGING: '/phrases-for-hot-messaging',
    MALE_EROGENOUS_ZONES:'/male-erogenous-zones',
    KISSING_TECHNIQUES:'/kissing-techniques',
    TABOO:'/taboo',
    SECRETS_TECHNIQUES:'/secrets-techniques',
    BLOW_JOB:'/blow-job',
    RID_OF_THE_GAG:'/rid-of-the-gag',
    HOW_TO_RELAX:"/how-to-relax",
    SEX_TOYS:"/sex-toys",
    MANUAL_TECHNIQUES:"/manual-techniques",
    SEX_GAMES:"/sex-games",
    ANAL_SEX:"/anal-sex",
    LIBERATION:"/liberation",
    THREESOME:"/threesome",
    TOP_POSES:"/top-poses",
    SEX_SCENARIOS:"/sex-scenarios",
    BDSM:"/bdsm",
    HOT_PHRASES:"/hot-phrases",
    GUEST:"/guest",
    ROLE_PLAYING: "/role-playing",
    TESTS: "/tests",
    COMMON_TECHNIQUES: "/common-techniques",
    POSES:"/poses",
    BOTTLE:'/bottle',
    TRUTH_OR_DARE:'/truth-or-dare',
    SEX:'/sex',
    ORAL_SEX:'/oral-sex',
    HARD_MODE:'/hard-made',
    TRUTH:'/truth',
    DARE:'/dare',
    HOT_TASKS:'/hot-tasks',
    GENTILE:'/gentile',
    WEBCAM_MODEL:'/webcam-model',
    HOME_VIDEO:'/home-video',
    GAME_WITH_STORY:'/game-with-story',
    FANTASY:'/fantasy',
    STRANGERS:'/strangers',
    FORBIDDEN_DEVICE:'/forbidden-device',
    DIRTY_GAMES:'/dirty-games',
    PANTY_METHOD:'/panty-method',
    FUGITIVES:'/fugitives',
    PARENT_CONTROL:'/parent-control',
    CINDERELLA_EFFECT:'/cinderella-effect',
    VIRGIN:'/virgin',
    ALIEN_TERRITORY:'/alien-territory',
    CODE_WORD:'/code-word',
    PHONE_SEX:'/phone-sex',
    COMFORT_ZONE:'/comfort-zone',
    INTERVIEW:'/interview',
    BUSINESSWOMAN:'/businesswoman',
    PRETTY_WOMAN:'/pretty-woman',
    LOCKER_ROOM:'/locker-room',
    ROCK_STAR:'/rock-star',
    BEACH:'/beach',
    FIRST_SIGHT:'/first-sight',
    INSULTED_INNOCENCE:'/insulted-innocence',
    SMS:'/sms',
    PRACTICES:'/practices',
    DAYS:'/days',
    SCENARIOS_BDSM:"/scenarios-bdsm",
    LEVEL_1:"/level-1",
    LEVEL_2:"/level-2",
    LEVEL_3:"/level-3",
    LEVEL_4:"/level-4",
    LEVEL_5:"/level-5",
    LEVEL_6:"/level-6",
    PLAY_WITH_HIS_SPERM:"/play-with-his-sperm",
    UNFORGETTABLE_BLOWJOB_SCENARIOS:"/unforgettable-blowjob-scenarios",
    TOP_POSITIONS:"/top-positions",
    CUNNILINGUS_POSITIONS: "/cunnilingus-positions",
    MAN_TRUTH: "/man-truth",
    MAN_TRUTH_HARD_MODE: "/man-truth-hard-mode",
    MAN_DARE: "/man-dare",
    MAN_DARE_HARD_MODE:"/man-dare-hard-mode",
    GIRL_TRUTH: "/girl-truth",
    GIRL_TRUTH_HARD_MODE: "/girl-truth-hard-mode",
    GIRL_DARE:"/girl-dare",
    GIRL_DARE_HARD_MODE: "/girl-dare-hard-mode",
    MONTHLY_CHALLENGES: '/monthly-challenges',
    DAYS_OF_SEX_CALENDAR:'/days-of-sex-calendar'
}
