import './Input.scss';
import {HTMLInputTypeAttribute, useContext} from "react";
import {ThemeContext} from "../../../context/themeContext";
interface IInputProps {
    labelText?: string;
    placeholderText: string;
    onChange : (value:string) => void;
    inputType: HTMLInputTypeAttribute;
    id?:string;
    errorMessage?: string;
    maxLength?: number;
    value?: string;
}
export const Input =({id, labelText,placeholderText,onChange,inputType, errorMessage, maxLength, value}:IInputProps)=>{
    const { theme } = useContext(ThemeContext);

    return (
        <div className="form-group">
            {labelText ? <label className={`${theme} ${errorMessage?'labelErrorBoundary':''}`}>{labelText}</label> : null}
            <input id={id} type={inputType}
                   onChange={(event)=>onChange(event.target.value)}
                   maxLength={maxLength || undefined}
                   className={`form-control input-lg ${theme} ${errorMessage?'inputErrorBoundary':''}`} placeholder={placeholderText ? placeholderText : ''}
                   value={value}
            />
            {errorMessage? <p className={'inputErrorMessage'}>{errorMessage}</p>: null}
        </div>
    )
}
