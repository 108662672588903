export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.1.png?alt=media&token=7e55053e-4644-4681-b97c-b834c1aabc6f",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.20.png?alt=media&token=7300abb0-5c06-464b-b08f-7d71c8c2fd57",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.3.png?alt=media&token=641ef8c4-756c-4ee7-b891-a7fd92651c40",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.4.png?alt=media&token=11395e11-5981-4e0a-815e-2ee04d9e0aa1",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.5.png?alt=media&token=9d2a4382-359a-4ac2-9dd3-e3375ab22d23",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.6.png?alt=media&token=ae9a9b0c-881d-47cd-8f1d-6ce80e41c183",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.7.png?alt=media&token=fe46dce3-83f0-4019-8ff7-79ad9e56f14d",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.8.png?alt=media&token=db94ad74-1d65-475a-b20c-ad20ab688d5f",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.9.png?alt=media&token=6fc2aec9-efe8-457f-8a9e-f568b3216064",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.10.png?alt=media&token=2623d99b-15d3-47de-90e1-af539e058713",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.11.png?alt=media&token=b67c0975-8959-4eb6-a7fb-4cdea3362efe",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.12.png?alt=media&token=053b15aa-2acf-4351-a00f-7c7a2c9699d0",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.13.png?alt=media&token=c022de48-a307-48b6-bb89-e40b1a8e6f32",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.14.png?alt=media&token=6a900cd0-2ea2-4019-963d-0676b8c0be5e",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.15.png?alt=media&token=5cbe5495-085c-4f1e-bf91-3d198f4927ac",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.16.png?alt=media&token=ace1fe3a-7c68-4200-8b2c-757171a3092b",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.17.png?alt=media&token=a666aed7-4fdc-498c-8dfb-c818329e6522",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.18.png?alt=media&token=5505c21d-af93-4c6b-a24d-04f04a4b4359",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.19.png?alt=media&token=c84233bb-39d6-4ef8-b8b8-4fe8c943693e",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.2.png?alt=media&token=24f50d36-ce19-4ac3-8d6e-acf3ceba6395",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-7%2F7.2.21.png?alt=media&token=c4d0e883-5de0-491d-9079-e8afb261abe6",

]





