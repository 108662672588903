import './LocationBackArrow.scss';
import {useNavigate} from "react-router";
import {useTestElementsVisibility} from "../../hooks/onboarding/useTestElementsVisibility";
export const LocationBackArrow = ({routesWithBackArrow}:{routesWithBackArrow: string[]})=>{
    const navigate=useNavigate();
    const isBackArrowVisible=useTestElementsVisibility(routesWithBackArrow)

    return (
        <>
            {isBackArrowVisible? <i onClick={()=>navigate(-1)} className={'backArrow'}></i>
                :null}
        </>
    )
}
