export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
]
export const imgUrl = {
    src1 : "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes1.png?alt=media&token=c21a947a-bd46-4ad4-bbee-1d809dc6a0d1",
        src2: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes2.png?alt=media&token=454f9b23-83de-47b5-be0a-868aae3d30b4",
        src3: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes3.png?alt=media&token=c6f3168c-0096-45b3-842c-4f934e81ee15",
        src4: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes4.png?alt=media&token=2a838f94-2853-40c2-84da-0f5fa9fdf71f",
    src5:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes5.png?alt=media&token=9bae744b-92ae-4c63-8dd7-62f6df01454f",
        src6:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes6.png?alt=media&token=6eb5e258-58c8-4055-9670-fd1eee821d26",
        src7: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes7.png?alt=media&token=2c3bbb00-f544-4a54-b10d-282b0e5e9ce8",
        src8: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes8.png?alt=media&token=b1df2215-afe0-496f-9990-2c6d58537ee7",
        src9: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes9.png?alt=media&token=0dbbd780-949c-49b0-82b8-eaeecc580535",
        src10: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes10.png?alt=media&token=d0382557-e4a1-49b3-8545-1a91e8afa352",
        src11: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes11.png?alt=media&token=b0bfabc8-2c93-4504-a244-fb010a6374a3",
        src12: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes12.png?alt=media&token=5157ae08-9b28-46f1-a04f-d4fba9c11bab",
        src13: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes13.png?alt=media&token=02166d15-94b1-49c8-b291-0664af4bbd34",
        src14: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes14.png?alt=media&token=dcc2b4ca-5972-4c83-a200-510475e14511",
        src15: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes15.png?alt=media&token=def1c0ba-835a-43a8-a615-abf8fbac2d86",
        src16: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes16.png?alt=media&token=040c03be-80dd-4a27-8e16-52a36286e304",
        src17: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes17.png?alt=media&token=7b8da01d-d9e7-486c-a0e8-e8047803a752",
        src18: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes18.png?alt=media&token=30fcc47c-3a54-4399-9b76-62a7d62be92e",
        src19: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes19.png?alt=media&token=5b17a1ce-5817-4abf-bb06-b97d10983460",
        src20: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes20.png?alt=media&token=84367722-9b8c-46cc-b9b7-18293c7b01a7",
        src21: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes21.png?alt=media&token=9842716c-8a1f-4033-8096-a3f185277ebe",
        src22:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes22.png?alt=media&token=7cdd219e-e4d8-49cc-8ca3-cf514c65aca5",
        src23:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes23.png?alt=media&token=cfd0ad5d-0883-4282-842b-e885d7781fef",
        src24:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes24.png?alt=media&token=a931ebd1-eaeb-43b4-ad88-51711a4fa6fd",
        src25:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes25.png?alt=media&token=2fe5b0cc-0b30-4b77-a2c7-271add182db3",
        src26:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes26.png?alt=media&token=cfe37ef5-b0fd-4879-b19f-1867a06f4c5b",
        src27:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes27.png?alt=media&token=1a369e69-d25e-4a06-b6f9-5654ac562040",
        src28:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes28.png?alt=media&token=2e92b4ab-7d33-4779-864d-b11c162c3681",
        src29:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes29.png?alt=media&token=a6ffc695-ebc2-4d6a-b381-577dce00c96b",
        src30:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes30.png?alt=media&token=282c70e4-ee22-4c90-b978-0652ececf696",
        src31:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes31.png?alt=media&token=ff55cf30-736c-4c3e-aecf-3fdcae642034",
        src32:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes32.png?alt=media&token=4f755938-dd6f-4c07-bd6a-880f8886e499",
        src33:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes33.png?alt=media&token=9099304d-f1eb-4653-a95f-822ab18fdb83",
        src34:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes34.png?alt=media&token=5dde7e76-6a0c-43c0-9f35-b0d846019461",
        src35:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes35.png?alt=media&token=474dfba3-41cf-4cf0-a727-888f483b9851",
        src36:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes36.png?alt=media&token=880fa3eb-89b0-419c-ba5c-613b847bf283",
        src37:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes37.png?alt=media&token=f2c5ee35-ae91-4b5a-9250-80782f27fac6",
        src38:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes38.png?alt=media&token=ce69335c-84ab-4e15-be15-1f9c7aaa9b0a",
        src39:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes39.png?alt=media&token=dbedfef4-1063-4ccc-88f4-d99aca166a6c",
        src40:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes40.png?alt=media&token=cfc66428-605b-473f-8de3-31dd674ba3c0",
        src41:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes41.png?alt=media&token=11530c89-2b86-476f-86d3-562928abd194",
        src42:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes42.png?alt=media&token=2074a8dc-fd94-4d06-ba9e-52ca24ee098b",
        src43:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes43.png?alt=media&token=791943b3-a520-4418-bdf3-5963fa7503d6",
        src44:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes44.png?alt=media&token=b6f07376-1946-4dae-9e95-227a4f55eca3",
        src45:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes45.png?alt=media&token=aadb9e3c-2edd-4c6b-872c-c221a5258e78",
        src46:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes46.png?alt=media&token=41bc3f59-75db-42a9-8476-17414be92293",
        src47:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes47.png?alt=media&token=31b9d512-a15c-4757-9964-aafd6444b61d",
        src48:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes48.png?alt=media&token=4fca797b-59ca-4c0a-8b12-4774a947c996",
        src49:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fposes%2Fposes49.png?alt=media&token=d13b766c-4392-49ee-8ced-14b6adf2c15d",
} as {[key:string]: string}






