import {useTranslation} from "react-i18next";
import {src1, src2} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("course2_anal_sex_title")
    const subtitle = t("course2_anal_sex_subtitle")
    const subtitle1 = t("course2_anal_sex_subtitle2")
    const list = t("course2_anal_sex_lis", { returnObjects: true })
    const list1 = t("course2_anal_sex_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src2} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro