import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './ExistedEmailScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {Footer} from "../../../components/lego/Footer/Footer";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import React from "react";
import {useNavigate} from "react-router";
import {Button} from "../../../components/lego/Button/Button";

export const ExistedEmailScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');
    const navigate = useNavigate()
    return (
        <>
            <div>
                <ContentContainer>
                    <h5 className={'emailVerificationText'}>{t('existedEmail.text')}</h5>
                    <div className={"mb-24"} />
                </ContentContainer>
                <Button text={t('existedEmail.continueButton')}
                        onClick={() => navigate(nextRoute)}
                    buttonTheme={ButtonThemes.DARK}
                    width={100}
                    className={'EmailVerificationButton'} />

            </div>
            <Footer text={<span className="">
                    {t("privatePolicyText")}
                <a href={PolicyAnchors.TERMS}>{t("createAccountScreen.policyAnchors.terms")}</a>      &nbsp;
                <a href={PolicyAnchors.PRIVACY}>{t("createAccountScreen.policyAnchors.privacy")}</a>{' '}      &nbsp;
                <a href={PolicyAnchors.SUBSCRIPTION}>{t("createAccountScreen.policyAnchors.subscription")}</a>      &nbsp;
                </span>}/>

        </>
    )
}