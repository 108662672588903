import {useTranslation} from "react-i18next";
import {affirmations2Src} from "./contstants";

const Tasks = () => {
    const { t } = useTranslation('app');
    const subTitle = t("affirmations_title2")
    const list = t("affirmations_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__list">
                    {list.map((i: string, idx: number) => <div className="child-container__list mt-16 typography-14">{idx + 1}. {i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={affirmations2Src} alt="course-preview" />
            </div>
        </div>
    )
}
export default Tasks