import {useTranslation} from "react-i18next";
import {src1} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("blow_job_title")
    const subtitle = t("blow_job_subtitle")
    const list = t("blow_job_list", { returnObjects: true })

    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string, idx: number) => <div className="child-container__list mt-16 typography-16">{idx + 1}. {i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro