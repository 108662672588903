import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {YOU_CUM} from "../../GameItem/contants";


const OralSex = () => {
    const { t } = useTranslation('app');
    const title = t("games4_oral_sex_title")
    const subtitle = t("games4_oral_sex_title1")
    const list = t("games4_oral_sex_titles", { returnObjects: true })
    const list1 = t("games4_oral_sex_articles", { returnObjects: true })
    const titleMain = t("you_cum_game")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("you_cum") + 1]}
                        objectToSave={{
                            img: YOU_CUM[1].img,
                            value: 'you_cum',
                            route: '/oral-sex',
                            index: 1
                        }}
                        uuid={"you_cum1"}
                        route={"/games/you-cum"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            {new Array(15).fill("").map((_,idx) => {
                return <> <div className="child-container__red-title mt-24 typography-18 typography-bold">
                    {list[idx]}
                </div>
                    <div className="child-container__article-title mt-24 typography-16">
                        {list1[idx]}
                    </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={imgUrl[1+idx]} alt="course-preview" />
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[imgUrl.length - 1]} alt="course-preview" />
            </div>
        </div>
            </>
    )
}

export default OralSex