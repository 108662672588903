import {useTranslation} from "react-i18next";
import {src2, src3} from "./constants";

export const AtHome = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course3_guest_title1")
    const article1 = t("how_to_relax_title")
    const list = t("course3_guest_list1", { returnObjects: true })
    const list1 = t("course3_guest_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={src2} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={src3} alt="course-preview" />
            </div>
        </div>
    )
}