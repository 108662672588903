import {useTranslation} from "react-i18next";
import { masturbation5Src, masturbation6Src} from "./constants";

const Step4 = () => {
    const { t } = useTranslation('app');
    const title = t("course2_masturbation_title5")
    const article = t("course2_masturbation_article5")

    const title1 = t("course2_masturbation_title6")
    const list1 = t("course2_masturbation_list6", { returnObjects: true })
    return (
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={masturbation5Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={masturbation6Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Step4