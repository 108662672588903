export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const phrases1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fphrases_for_messaging%2Fphrases1.png?alt=media&token=fc8ce819-63fa-4c49-9513-326e0e9867f9"
export const phrases2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fphrases_for_messaging%2Fphrases2.png?alt=media&token=9890bf52-ae10-4c29-a611-3c7532db94c4"


