export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys1.png?alt=media&token=a2c68e1f-454c-4706-9415-23312e02ca1f"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys2.png?alt=media&token=92422643-b64b-4596-8632-042bf8730cfa"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys3.png?alt=media&token=d6ea2432-03a1-4bfb-ba12-f4d96f14ff75"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys4.png?alt=media&token=3691b1df-a069-403c-92a4-d66f2f472f7a"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys5.png?alt=media&token=b83f68e5-c154-44a0-862c-3aef43592c4b"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys6.png?alt=media&token=98f74d8c-6734-4434-9655-31cf4af88d8a"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys7.png?alt=media&token=cd6bb9a4-7787-4ccf-80f0-e06765976d99"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys8.png?alt=media&token=1f51edbf-2719-4555-8374-053ccc4c6d4e"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-toys%2Fsex-toys9.png?alt=media&token=72eb4abe-41c1-4236-a792-ec8b9ce95ffd"

