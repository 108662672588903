import './Reviews.scss';
import {TFunction} from "i18next";
import {CarouselComponent} from "../../Carousel/Carousel";
import {ShadowContainer} from "../ShadowContainer/ShadowContainer";
import {
    fifthPersonPhoto,
    firstPersonPhoto, fiveStarsRate,
    fourthPersonPhoto,
    secondPersonPhoto, sixthPersonPhoto,
    thirdPersonPhoto
} from "../../../assets/onBoardingImages/commentsPhoto";
import {Trans} from "react-i18next";
import {useAppContainerWidth} from "../../../hooks/onboarding/useAppContainerWidth";

export const Reviews =({t}: { t: TFunction })=>{
    const appContainerWidth=useAppContainerWidth()

    const reviewersData=[
        {photo: firstPersonPhoto, name:t("payInfoScreen.stillDoubting.reviews.firstPerson.name"),
            review: t("payInfoScreen.stillDoubting.reviews.firstPerson.review")},
        {photo: secondPersonPhoto, name:t("payInfoScreen.stillDoubting.reviews.secondPerson.name"),
            review: t("payInfoScreen.stillDoubting.reviews.secondPerson.review")},
        {photo: thirdPersonPhoto, name:t("payInfoScreen.stillDoubting.reviews.thirdPerson.name"),
            review: t("payInfoScreen.stillDoubting.reviews.thirdPerson.review")},
        {photo: fourthPersonPhoto, name:t("payInfoScreen.stillDoubting.reviews.fourthPerson.name"),
            review: t("payInfoScreen.stillDoubting.reviews.fourthPerson.review")},
        {photo: fifthPersonPhoto, name:t("payInfoScreen.stillDoubting.reviews.fifthPerson.name"),
            review: t("payInfoScreen.stillDoubting.reviews.fifthPerson.review")},
        {photo: sixthPersonPhoto, name:t("payInfoScreen.stillDoubting.reviews.sixthPerson.name"),
            review: t("payInfoScreen.stillDoubting.reviews.sixthPerson.review")},
    ]

    return (
        <div className={'reviewsContainer'} style={{maxWidth:!!appContainerWidth ? appContainerWidth : '100%', width:!!appContainerWidth ? appContainerWidth : '100%'}}>
            <h4 className={'reviewsTitle'}>{t("payInfoScreen.stillDoubting.title")}</h4>
            <h6 className={'reviewsText'}>{t("payInfoScreen.stillDoubting.text")}</h6>
                <CarouselComponent itemPadding={50}>
                    {reviewersData.map(({photo,name,review})=>{
                        return <div key={name} className={'reviewContainer'}>
                            <ShadowContainer>
                                <div className={'review'}>
                                    <img loading="lazy" className='reviewerPhoto' src={photo} alt={''}/>
                                    <img loading="lazy" className={'rate'} src={fiveStarsRate} alt={''}/>
                                    <h6>{name.toUpperCase()}</h6>
                                    <Trans t={t}>
                                        <p>{review}</p>
                                    </Trans>

                                </div>
                             </ShadowContainer>
                        </div>
                    })}
                </CarouselComponent>
        </div>
    )
}