export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fdirty-games%2F8.2.3.1.png?alt=media&token=4198ded2-7d36-4316-b0f4-ade6e390a2ce",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fdirty-games%2F8.2.3.2.png?alt=media&token=9693df87-4d49-468e-afb3-18f7d96f4e6e",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fdirty-games%2F8.2.3.3.png?alt=media&token=f60ef15c-ce17-4325-920d-c7be543bd3ac"
]





