import './style.scss'
import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";
import {ReactComponent as StarIcon} from "assets/app/starWothouBG.svg";
import {useNavigate} from "react-router";
import cn from "classnames";
import {MouseEvent, useEffect, useState} from "react";
import {getListOfFavorites, setFavoriteToList} from "../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";
import {TFavoritesList} from "../../screens/app/FavoritesPage/FavoritesPage";

export type TProps = {
    title: string;
    isActive?: boolean;
    objectToSave: any;
    uuid: string
    route?: string
}
const TabsHeader = ({title, isActive, uuid, objectToSave, route}: TProps) => {
    const navigate = useNavigate();
    const user = getAuth();
    const [active, setActive] = useState(isActive)
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() => {
        getFavorites()
    }, [])

    useEffect(() => {
        setActive(!!list[uuid])
    }, [list])
    const handleFavorites = async(e:MouseEvent) => {
        if(!uuid) return
        e.preventDefault()
        e.stopPropagation()
        await  setFavoriteToList(user?.currentUser?.accessToken, uuid, objectToSave)
        setActive(!active)
    }
    const handleNavigate = () => {
        const path = !!route ? route :  -1
        // @ts-ignore
        navigate(path)
    }
    return(
        <div className="tabs-header-container row-container space-between align-center">
            <ArrowIcon className="tabs-header-container__back" onClick={handleNavigate} />
            <div className="tabs-header-container__title typography-14 typography-bold">
                "{title}"
            </div>
            <StarIcon className={cn("tabs-header-container__star", {active: active})} onClick={handleFavorites} />
        </div>
    )
}

export default TabsHeader
