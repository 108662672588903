export const dateIdeas='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FDateIdeas.png?alt=media&token=785ce121-4209-4352-a5be-9ecf59181cec&_gl=1*1x9andb*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDI5NjEuMjMuMC4w'
export const arousingSMS='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FarousingSMS.png?alt=media&token=ba858bf5-453d-4252-82aa-65e0140407d3&_gl=1*1ud49bw*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMyMzUuNjAuMC4w'
export const awakenSexuality='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FawakenSexuality.png?alt=media&token=a80fec23-9e1b-4407-9b02-b3643798c9e4&_gl=1*156yn3z*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMyNDkuNDYuMC4w'
export const boostingLibido='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FboostingLibido.png?alt=media&token=981a987b-3461-490b-b5aa-5c3794db96bd&_gl=1*5on115*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMyODcuOC4wLjA.'
export const howToEngage='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FhowToEngage.png?alt=media&token=0ffe2dc6-e722-4584-bc3c-3a3917cdcf77&_gl=1*s6d7dn*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMzMDIuNjAuMC4w'
export const methodsOfConnection='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FmethodsOfConnection.png?alt=media&token=c961c3bf-263b-48e3-8c28-106cb8d9439e&_gl=1*t0l0cn*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMzMTkuNDMuMC4w'
export const sexScenarios='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FsexScenarios.png?alt=media&token=92aa69f2-21f3-46be-9743-ed6a14b10303&_gl=1*1s2hjcp*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMzMzUuMjcuMC4w'
export const signsOfMan='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FsignsOfMan.png?alt=media&token=68fe58ae-d3f6-4ae7-a2f4-01d06fd7556d&_gl=1*d7r6z2*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMzNTEuMTEuMC4w'
export const socialMediaMan='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FsocialMediaMan.png?alt=media&token=1e4afe1e-73d3-481f-9e74-2e46c9678d3c&_gl=1*1vinvur*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMzNjguNjAuMC4w'
export const waysToMeetMan='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FwhatWillLearnImages%2FwaysToMeetMan.png?alt=media&token=5536b943-5b3e-454d-9747-c9a92952a2df&_gl=1*1r5rrvs*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDMzODMuNDUuMC4w'


