import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import { maintain4Src} from "./contstants";
const SixRules = () => {
    const { t } = useTranslation('app');
    const subTitle = t("maintain_conversation_6_title")
    const article = t("maintain_conversation_6_text")
    const list = t("maintain_conversation_6_article", { returnObjects: true })
    const list2 = t("maintain_conversation_6_list", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__list">
                <ul>
                    {list2.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {article}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={maintain4Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default SixRules