export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Ffugitives%2F8.2.5.1.png?alt=media&token=79415d0b-e357-4ebd-8616-4eaed07722ac",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Ffugitives%2F8.2.5.2.png?alt=media&token=f6d97abc-3db8-46a5-b566-8b1578fdd23e",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Ffugitives%2F8.2.5.3.png?alt=media&token=d2b6b3b5-3781-4a26-b10e-b85ab1ae09cc"
]





