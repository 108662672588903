import {useTranslation} from "react-i18next";
import {zones1Src} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("course2_erogenous_title")
    const list = t("course2_erogenous_list", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={zones1Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro