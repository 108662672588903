import {useTranslation} from "react-i18next";
import {
    src19,
    src20,
    src21,
    src22,
    src23,
    src24,
    src25,
    src26,
    src27,
    src28,
    src29,
    src30,
    src31,
    src321,
} from "./constants";

export const Flirting = () => {
    const { t } = useTranslation('app');
    const title1 = t("course3_techniques_title23")
    const subtitle24 = t("course3_techniques_title24")
    const subtitle25 = t("course3_techniques_title25")
    const subtitle26 = t("course3_techniques_title26")
    const subtitle27 = t("course3_techniques_title27")
    const subtitle28 = t("course3_techniques_title28")
    const subtitle29 = t("course3_techniques_title29")
    const subtitle30 = t("course3_techniques_title30")
    const subtitle31 = t("course3_techniques_title31")
    const subtitle32 = t("course3_techniques_title32")
    const subtitle33 = t("course3_techniques_title33")
    const subtitle34 = t("course3_techniques_title34")
    const subtitle35 = t("course3_techniques_title35")
    const subtitle36 = t("course3_techniques_title36")
    const subtitle37 = t("course3_techniques_title37")
    const subtitle38 = t("course3_techniques_title38")
    const subtitle39 = t("course3_techniques_title39")
    const subtitle40 = t("course3_techniques_title40")
    const article24 = t("course3_techniques_article24")
    const article25 = t("course3_techniques_article25")
    const article26 = t("course3_techniques_article26")
    const article27 = t("course3_techniques_article27")
    const article28 = t("course3_techniques_article28")
    const article29 = t("course3_techniques_article29")
    const article30 = t("course3_techniques_article30")
    const article31 = t("course3_techniques_article31")
    const article32 = t("course3_techniques_article32")
    const article33 = t("course3_techniques_article33")
    const article34 = t("course3_techniques_article34")
    const article35 = t("course3_techniques_article35")
    const article36 = t("course3_techniques_article36")
    const article37 = t("course3_techniques_article37")
    const article38 = t("course3_techniques_article38")
    const article39 = t("course3_techniques_article39")
    const list6 = t("course3_techniques_list6", { returnObjects: true })
    const list7 = t("course3_techniques_list7", { returnObjects: true })
    const list8 = t("course3_techniques_list8", { returnObjects: true })
    const list8_1 = t("course3_techniques_list8_1", { returnObjects: true })
    const list9 = t("course3_techniques_list9", { returnObjects: true })
    const list10 = t("course3_techniques_list10", { returnObjects: true })
    const list11 = t("course3_techniques_list11", { returnObjects: true })
    const list12 = t("course3_techniques_list12", { returnObjects: true })
    const list13 = t("course3_techniques_list13", { returnObjects: true })
    const list14 = t("course3_techniques_list14", { returnObjects: true })
    const list15 = t("course3_techniques_list15", { returnObjects: true })
    const list16 = t("course3_techniques_list16", { returnObjects: true })
    const list17 = t("course3_techniques_list17", { returnObjects: true })
    const list18 = t("course3_techniques_list18", { returnObjects: true })
    const list19 = t("course3_techniques_list19", { returnObjects: true })

    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle24}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article24}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src19} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article25}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle25}
            </div>
            <div className="child-container__list">
                {list6.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src20} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list7.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle26}
            </div>
            <div className="child-container__list">
                {list8.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src21} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list8_1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle27}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article26}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src22} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list9.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle28}
            </div>
            <div className="child-container__list">
                {list10.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src23} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article27}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle29}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article28}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src24} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list11.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle30}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article29}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src25} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article30}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle31}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article31}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle32}
            </div>
            <div className="child-container__list">
                {list12.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle33}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article32}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src26} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list13.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle34}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article33}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src27} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list14.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle35}
            </div>
            <div className="child-container__list">
            {list15.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src28} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list16.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle36}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article34}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src29} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article35}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle37}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article36}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle38}
            </div>
            <div className="child-container__list">
                {list17.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src30} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list18.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle39}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article37}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src31} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article38}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle40}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article39}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src321} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list19.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
    )
}