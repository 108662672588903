import {useTranslation} from "react-i18next";
import {modelSrc} from "./constants";

const TheModel = () => {
    const { t } = useTranslation('app');
    const title = t("man_of_my_dreams_rules_article2")
    const list = t("man_of_my_dreams_rules_article2_content", { returnObjects: true })
    const note = t("man_of_my_dreams_rules_article2_note")
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__list">
                    {list.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={modelSrc} alt="course-preview" />
            </div>
        </div>
    )
}
export default TheModel