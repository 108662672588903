import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {TRUTH_OR_DARE_ADVANCED} from "../../contants";
export const TopPositions = () => {
    const { t } = useTranslation('app');
    const title = t("premium2_positions_title1")
    const subtitle = t("premium2_positions_title2")
    const subtitle3 = t("premium2_positions_title3")
    const subtitle4 = t("premium2_positions_title4")
    const subtitle5 = t("premium2_positions_title5")
    const subtitle6 = t("premium2_positions_title6")
    const article1 = t("premium2_positions_article1")
    const article2 = t("premium2_positions_article2")
    const article3 = t("premium2_positions_article3")
    const article4 = t("premium2_positions_article4")
    const article5 = t("premium2_positions_article5")
    const article6 = t("premium2_positions_article6")
    const list = t("premium2_positions_list1", { returnObjects: true })
    const list1 = t("premium2_positions_list2", { returnObjects: true })
    const titleMain = t("truth_or_dare_advanced_premium")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("truth_or_dare_advanced") + 1]}
                        objectToSave={{
                            img: TRUTH_OR_DARE_ADVANCED[1].img,
                            value: 'truth_or_dare_advanced',
                            route: '/top-positions',
                            index: 1
                        }}
                        uuid={"truth_or_dare_advanced1"}
                        route={"/premium/truth-or-dare-advanced"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle3}
            </div>
            {list.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article3}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            {list1.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article4}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle5}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article5}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[3]} alt="course-preview" />
            </div>

            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle6}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article6}
            </div>
        </div>
            </>
            )
}