import {useTranslation} from "react-i18next";
import { src13} from "./constants";

export const Husband = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course3_rules_title14")
    const article = t("course3_rules_article18")
    const article1 = t("course3_rules_article19")
    const subtitle1 = t("course3_rules_article20")
    const list2 = t("course3_rules_list24", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src13} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list2.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
        </div>
    )
}