import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import {approach3Src} from "./contstants";
const SocialMedia = () => {
    const { t } = useTranslation('app');
    const title = t("approaches_social_media_title")
    const list = t("approaches_social_media_content", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__list">
                    {list.map((i: string, idx: number) => <div
                        key={idx}
                        className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={approach3Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default SocialMedia