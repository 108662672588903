import {useTranslation} from "react-i18next";
import {masturbation15Src} from "./constants";

const Beads = () => {
    const { t } = useTranslation('app');
    const title = t("course2_masturbation_title16")
    const article = t("course2_masturbation_article16")
    return (
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={masturbation15Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Beads