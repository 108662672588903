import {useTranslation} from "react-i18next";
import {src10, src11, src9, src12, src13} from "./constants";

export const Outdoor2 = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course3_guest_title6")
    const subtitle1 = t("course3_guest_title7")
    const subtitle2 = t("course3_guest_title8")
    const subtitle3 = t("course3_guest_title9")
    const subtitle4 = t("course3_guest_title10")
    const article = t("course3_guest_article3")
    const article1 = t("course3_guest_article4")
    const article2 = t("course3_guest_article5")
    const article3 = t("course3_guest_article6")
    const article4 = t("course3_guest_article7")
    const list = t("course3_guest_list10", { returnObjects: true })
    const list1 = t("course3_guest_list11", { returnObjects: true })
    const list3 = t("course3_guest_list12", { returnObjects: true })
    const list4 = t("course3_guest_list13", { returnObjects: true })

    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src9} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src10} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src11} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article3}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle3}
            </div>
            <div className="child-container__list">
                {list3.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article4}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src12} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list4.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src13} alt="course-preview" />
            </div>
        </div>
    )
}