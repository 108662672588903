export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
]

export const imgUrl = [
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpractices%2F4.2.1.png?alt=media&token=c8195f99-41a4-4eed-85bf-31e195113cfd",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpractices%2F4.2.2.png?alt=media&token=aa6164a4-d928-47fe-8181-3b8a8e4712a0",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpractices%2F4.2.3.png?alt=media&token=f5d46659-1020-4c18-890d-2dc247566ea7",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpractices%2F4.2.4.png?alt=media&token=6fd6ffdb-9a32-4f45-ac01-68a25604d4c0",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpractices%2F4.2.5.png?alt=media&token=c386a08d-dc25-4c10-b690-e92cd11b4992",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpractices%2F4.2.6.png?alt=media&token=eae5a0bf-e2a8-45ba-b66f-3e9c094c0f49",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpractices%2F4.2.7.png?alt=media&token=7861197d-52f3-4e7c-878d-9a21f53b8721",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fpractices%2F4.2.8.png?alt=media&token=37fa3013-db22-417d-b203-72a10ad8769d"
]