export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fbdsm%2Fbdsm1.png?alt=media&token=1c75cbc6-5641-4e38-a74e-c1ec9a9004fc"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fbdsm%2Fbdsm2.png?alt=media&token=1521dda9-41cf-455f-ab02-7aa49bda3777"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fbdsm%2Fbdsm3.png?alt=media&token=87631ff5-dfbe-4670-98c7-500ce8777a7e"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fbdsm%2Fbdsm4.png?alt=media&token=ca0bdb48-54c9-4406-b811-fbd4732dae4d"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fbdsm%2Fbdsm5.png?alt=media&token=f2e194b8-f7e8-4e8e-ae7a-ff8aff9af346"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fbdsm%2Fbdsm6.png?alt=media&token=5fd86696-b9b8-47a1-a8b7-42a967f189d5"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fbdsm%2Fbdsm7.png?alt=media&token=589891de-1e29-427b-852c-66b8d7bf70ba"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fbdsm%2Fbdsm8.png?alt=media&token=ea0b9b02-44e9-49a0-90c7-4204c2e4d576"

