export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fanal-sex%2Fanal1.png?alt=media&token=3d7d0538-a981-461a-bc38-0e97b3c23654"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fanal-sex%2Fanal2.png?alt=media&token=555fc753-306f-4936-a04d-db22630010fa"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fanal-sex%2Fanal3.png?alt=media&token=558b13b8-cfa5-4c4d-bd1b-3677e4cdaac1"


