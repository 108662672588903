import {useTranslation} from "react-i18next";
import { imgUrl } from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("course3_poses_title1")
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            {new Array(10).fill("").map((_,idx) => {
                return <> <div className="child-container__red-title mt-24 typography-18 typography-bold">
                    {t(`course3_poses_title${2+idx}`)}
                </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={!!imgUrl['src'+(1+idx)] ? imgUrl['src'+(1+idx)] : imgUrl.src11} alt="course-preview" />
                    </div>
                    <div className="child-container__article-title mt-16 typography-16">
                        {t(`course3_poses_article${1+idx}`)}
                    </div>
                </>
            })
            }
        </div>
    )
}

export default Intro