import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import {maintain2Src, maintain3Src} from "./contstants";
const SecondRules = () => {
    const { t } = useTranslation('app');
    const subTitle = t("maintain_conversation_3_rule")
    const article = t("maintain_conversation_3_article")
    const subTitle1 = t("maintain_conversation_4_rule")
    const article1 = t("maintain_conversation_4_article")
    const subTitle2 = t("maintain_conversation_5_rule")
    const article2 = t("maintain_conversation_5_article")
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={maintain2Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle1}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle2}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={maintain3Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default SecondRules