import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './ConfidentCheckScreen.scss';
import {feelPhysicallyConfident} from "../../../assets/onBoardingImages";
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {MIXPANEL_API} from "../../../components/onboarding/PayInfoContainer/constants";

export const ConfidentCheckScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const confidentCheckAnswerOptions:string[]= [
        t("confidentCheckScreen.agreeButton"),
        t("confidentCheckScreen.disagreeButton")];
    const trackMixpanel = async(option: number) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const email = localStorage.getItem("email")
        const data = {
            event: 'onboarding_question_responded',
            data:{
                onboarding_step_number: 1,
                question: 'Do you feel physically confident and satisfied?',
                respond: option === 1 ? 'Things could be better' : 'Yes, totally',
            },
            fbclid: fbclid,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }
    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={feelPhysicallyConfident} /></ContentContainer>
            <p className={'confidentCheckQuestion'}>{t("confidentCheckScreen.confidentFeelQuestion")}</p>

            <div>
                {confidentCheckAnswerOptions.map((optionText, idx)=>{
                    return  <NextRouteButton key={optionText} option={optionText}
                                             route={nextRoute}
                                             onClick={() => trackMixpanel(idx)}
                                             buttonTheme={ButtonThemes.DARK}
                                             className={'confidentCheckButton'} />
                })}
            </div>
        </>
    )
}
