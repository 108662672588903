import React, {useEffect, useMemo, useState} from 'react';

interface PieChartProps {
    width: number;
    height: number;
    items: { value: number, color: string, id: string }[];
    outerRadius: number;
    innerRadius: number;
}
const getArcPath = (start, end, innerRadius, outerRadius) => {
    const startAngle = start * Math.PI * 2;
    const endAngle = end * Math.PI * 2;
    const x1 = innerRadius * Math.sin(startAngle);
    const y1 = innerRadius * -Math.cos(startAngle);
    const x2 = outerRadius * Math.sin(startAngle);
    const y2 = outerRadius * -Math.cos(startAngle);
    const x3 = outerRadius * Math.sin(endAngle);
    const y3 = outerRadius * -Math.cos(endAngle);
    const x4 = innerRadius * Math.sin(endAngle);
    const y4 = innerRadius * -Math.cos(endAngle);
    const bigArc = end - start >= 0.5;
    const outerFlags = bigArc ? '1 1 1' : '0 0 1';
    const innerFlags = bigArc ? '1 1 0' : '1 0 0';
    return `M ${x1},${y1} 
        L ${x2},${y2} 
        A ${outerRadius} ${outerRadius} ${outerFlags} ${x3},${y3} 
        L ${x4},${y4} 
        A ${innerRadius} ${innerRadius} ${innerFlags} ${x1},${y1}
        Z`;
};

const PieChart = ({    width, height, items, innerRadius, outerRadius}: PieChartProps) => {
    const [visiblePart, setVisiblePart] = useState(0);
    useEffect(() => {
        if (visiblePart < 1) {
            setTimeout(() => setVisiblePart(visiblePart + 0.01), 5);
        }
    }, [visiblePart]);
    const segments = useMemo(() => {
        const sum = items.reduce((sum, item) =>
            sum + item.value, 0);
        let start = 0;
        return items.map((item) => {
            const delta = (item.value / sum) * visiblePart;
            const path = getArcPath(start, start + delta,
                innerRadius, outerRadius);
            start += delta;
            return {...item, path};
        });
    }, [items, innerRadius, outerRadius, visiblePart]);

    return (
        <svg width={width} height={height} className="chart-icon">
            <g transform={`translate(${width / 2},${height / 2})`}>
                {segments.map((segment,idx) => {
                    return(
                    <path
                        id={segment.id}
                        key={segment.id}
                        stroke={segment.color}
                        fill={segment.color}
                        d={segment.path}
                        rx={segments[idx]?.color !== segments[idx - 1]?.color || idx === 0 || idx === segments.length - 1 ? 50 : 0}
                        ry={segments[idx]?.color !== segments[idx - 1]?.color || idx === 0 || idx === segments.length - 1 ? 50 : 0}
                    />
                )})}
            </g>
        </svg>
    );
}

export default PieChart;