import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import {Part2} from "./Part2";
import {Massage} from "./Massage";
import {Flirting} from "./Flirting";
import {BlowJob} from "./BlowJob";
import {MaleGaze} from "./MaleGaze";
import Intro from "./Intro";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { EMANCIPATION } from "screens/app/CourseItem/contants";
import { TFavoritesList } from "screens/app/FavoritesPage/FavoritesPage";
import { getListOfFavorites } from "utils/firebase/userData.utils";
const CommonTechniques = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const titleMain = t("emancipation_course")?.split(' ')[0]
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("emancipation") + 4]}
                        objectToSave={{
                            img: EMANCIPATION[4].img,
                            value: 'emancipation',
                            route: '/common-techniques/1',
                            index: 4
                        }}
                        uuid={"emancipation4"}
                        route={"/courses/emancipation"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Part2 />} />
                <Route path={'/3'} element={<Massage />} />
                <Route path={'/4'} element={<Flirting />} />
                <Route path={'/5'} element={<BlowJob />} />
                <Route path={'/6'} element={<MaleGaze />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.COMMON_TECHNIQUES}  />
        </>
    )
}

export default CommonTechniques