export const imgUrl = [
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.1.png?alt=media&token=c6908ced-4126-4cc5-a589-740a68c818f4",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.2.png?alt=media&token=35b98c4e-1089-48c8-8695-995f4d6f8fc4",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.3.png?alt=media&token=b688c5a0-6262-4d92-8039-1e799cf97646",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.4.png?alt=media&token=ad631e32-a15d-46fd-b78a-c234c46cfdce",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.5.png?alt=media&token=0b329269-a575-4ec7-8aa1-f4cc6f9ba4de",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.6.png?alt=media&token=5ad24cb2-4fe2-43b3-8a20-b22b0ddc1f49",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.7.png?alt=media&token=000ec533-9699-418a-8bf6-690f0156cbde",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.8.png?alt=media&token=6f08233f-45be-4620-9614-6c09bfbceba2",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.9.png?alt=media&token=c677a35a-2b1a-498d-b5a3-466ba3a5c5f2",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.10.png?alt=media&token=84470b2a-74a9-4dfc-b7c1-ebdd334a0d51",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.11.png?alt=media&token=9f322004-19a4-47b3-a829-5812080acead",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.12.png?alt=media&token=6c5c8cf2-7273-47c4-96e6-05a5e0058a19",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.13.png?alt=media&token=5fe33469-1198-4f76-b232-15f2ac35375d",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.14.png?alt=media&token=0dfee21b-1ac5-43d5-be61-0f4d6451fbc0",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.15.png?alt=media&token=c00a6786-a4dd-4c08-8a1e-6f2bad16358b",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.16.png?alt=media&token=1573fd39-60a9-4afd-899d-2af9a9d9f554",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.17.png?alt=media&token=8c8ee3c7-4dad-4798-a71d-6310a0261bad",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.18.png?alt=media&token=6f66c032-adf2-4a36-afbb-68424496cdeb",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.19.png?alt=media&token=45484efb-f53c-46a8-bae7-98c2a87a46a3",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.20.png?alt=media&token=5ea58149-97d4-408d-9264-4727c30b3b53",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.21.png?alt=media&token=f8340588-0894-484d-a6c8-9903e1acdfeb",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.22.png?alt=media&token=c75663dd-6231-4f61-807c-727a332f19e0",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.23.png?alt=media&token=f10e8eb4-ae39-44f8-a112-89728052f79d",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.24.png?alt=media&token=90db2633-c570-4544-864e-8f0208735eb2",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.25.png?alt=media&token=805b502e-8c00-4d8f-a9e8-97d85030a24e",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.26.png?alt=media&token=8aa14fa1-09ba-499c-bdac-882d453fda3c",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.27.png?alt=media&token=b4ce9ce0-5a25-4b5a-94e0-335275fed210",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.28.png?alt=media&token=ab40ab73-d72e-4807-be83-6053de5ca5b9",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.29.png?alt=media&token=176cd999-db08-4640-973d-33ff5da7ee6b",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.30.png?alt=media&token=d3345591-b7db-4169-b832-920ccccbd8e5",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.4.png?alt=media&token=ad631e32-a15d-46fd-b78a-c234c46cfdce",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.5.png?alt=media&token=0b329269-a575-4ec7-8aa1-f4cc6f9ba4de",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.6.png?alt=media&token=5ad24cb2-4fe2-43b3-8a20-b22b0ddc1f49",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.7.png?alt=media&token=000ec533-9699-418a-8bf6-690f0156cbde",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.8.png?alt=media&token=6f08233f-45be-4620-9614-6c09bfbceba2",
    "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2Fmonthly-challenges%2Fcontent%2F3.9.png?alt=media&token=c677a35a-2b1a-498d-b5a3-466ba3a5c5f2"
]