export const SCENARIOS_NAMES_ROUTES = [
    "at-home",
    "in-restaurant",
    "guest",
    "work",
    "public"
]

export const AT_HOME = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario1%2F8.1.1%20%20Gentile.png?alt=media&token=34c66da7-a27f-4453-bbb5-63888c15f46e&_gl=1*2vvawl*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTY2Ni4zMS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario1%2F8.1.2%20%20Webcam%20model.png?alt=media&token=706bd15a-e7a5-4494-b878-f3aa942b046c&_gl=1*1bofzcd*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTY0Mi41NS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario1%2F8.1.3%20%20Home%20video.png?alt=media&token=a8d9a169-8acf-4477-8c17-ac74bc5e9ba6&_gl=1*179kx4r*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTY0Ny41MC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario1%2F8.1.4%20Game%20with%20a%20story.png?alt=media&token=c7c8088f-1f9d-47a0-ae70-df7b6afee3a7&_gl=1*dmzoop*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTY0OS40OC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario1%2F8.1.5%20%20Fantasy.png?alt=media&token=62fe21c5-7867-45bb-a601-26222536eabf&_gl=1*uqhbze*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTY1MC40Ny4wLjA.'
    },
] as const
export const IN_RESTAURANT = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario2%2F8.2.1%20Strangers.png?alt=media&token=eb7e5c1e-d0fb-4cb3-af38-cc77efcb7201&_gl=1*ll9r5o*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTcxNi41Ny4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario2%2F8.2.2%20Forbidden%20device.png?alt=media&token=f819cc19-e23c-4058-950a-48422cd42ad6&_gl=1*1oczpea*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTcxOC41NS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario2%2F8.2.3%20Dirty%20games.png?alt=media&token=e17b4189-65c9-4878-9176-42d05c15baa8&_gl=1*18puwpj*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTcyMC41My4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario2%2F8.2.4%20Panty%20method.png?alt=media&token=2a2b6ad4-605e-4731-85d6-60ecaa8bc5c5&_gl=1*1cibn2u*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTcyMi41MS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario2%2F8.2.5%20Fugitives.png?alt=media&token=7d123430-9e05-43e5-be02-838f63d18025&_gl=1*1sex05n*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTcyNC40OS4wLjA.'
    }
] as const
export const GUEST = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario3%2F8.3.1%20Parental%20control.png?alt=media&token=d3a9858b-bc15-44e1-a322-960beaf37c17&_gl=1*189w6o*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTc2NC45LjAuMA..'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario3%2F8.3.2%20Cinderella%20effect.png?alt=media&token=23a9c5a5-a45e-47f3-add8-00c403c56eac&_gl=1*1bdztk1*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTc2Ny42LjAuMA..'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario3%2F8.3.3%20%20Virgin.png?alt=media&token=2c98af27-615f-42ad-b3bd-26dd56a15844&_gl=1*1hvcy1f*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTc2OC41LjAuMA..'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario3%2F8.3.4%20Alien%20territory.png?alt=media&token=b1f7293f-077e-4b82-94b6-f7b060535a0a&_gl=1*1cn1pxv*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTc3MC4zLjAuMA..'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario3%2F8.3.5%20Code%20word.png?alt=media&token=de78a46d-fe57-4dba-a1f2-0eeef2700a70&_gl=1*1roqu6e*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTc3Mi4xLjAuMA..'
    }
] as const
export const WORK = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario4%2F8.4.1%20Phone%20sex.png?alt=media&token=5250bc97-602e-4b2f-af11-e619eb6ac2d1&_gl=1*18bm6tr*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTg0NC41Ni4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario4%2F8.4.2%20Getting%20out%20of%20your%20comfort%20zone.png?alt=media&token=b82f92d4-6881-444e-807c-7ea608c7770e&_gl=1*141049a*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTg0Ni41NC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario4%2F8.4.3%20Interview.png?alt=media&token=8b2ad9dd-0cfb-44b4-bf80-f953e7c576fe&_gl=1*12gd0n7*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTg0OC41Mi4wLjA.'
    },

    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario4%2F8.4.4%20Businesswoman.png?alt=media&token=75268034-f639-4b3f-bed7-1fba3aa4534c&_gl=1*b80oyc*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTg0OS41MS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario4%2F8.4.5%20Pretty%20Woman.png?alt=media&token=b802e181-3c22-4cb0-8df0-78b7d878a68d&_gl=1*l87fjk*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTg1MS40OS4wLjA.'
    },
] as const
export const PUBLIC = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario5%2F8.5.1%20Men\'s%20locker%20room.png?alt=media&token=ff3104cc-3cb0-4026-ae0c-4e19ae538c5b&_gl=1*ilb0bl*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTkwOS41Ny4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario5%2F8.5.2%20Rock%20Star.png?alt=media&token=5bc5e7ca-e65f-4f29-b194-68522d7ead77&_gl=1*1r4qi3d*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTkxMS41NS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario5%2F8.5.3%20Beach.png?alt=media&token=edd34144-69e4-40a0-9332-12e450cab86a&_gl=1*rghsvv*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTkxMy41My4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario5%2F8.5.4%20Sex%20at%20first%20sight.png?alt=media&token=774fee70-f824-4a7d-9954-e4b2837625f1&_gl=1*1f6faot*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTkxNC41Mi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenario5%2F8.5.5%20Insulted%20innocence.png?alt=media&token=27ad10f3-f472-492f-8d1d-a6272a58700a&_gl=1*1exh07w*_ga*NDQyODEyNDI4LjE2OTkzMDEwNTA.*_ga_CW55HF8NVT*MTY5OTMwNTYzNy4yLjEuMTY5OTMwNTkxNi41MC4wLjA.'
    }
] as const


export const AT_HOME_ROUTES = [
    '/gentile',
    '/webcam-model',
    '/home-video',
    '/game-with-story',
    '/fantasy'
]
export const IN_RESTAURANT_ROUTES = [
    '/strangers',
    '/forbidden-device',
    '/dirty-games',
    '/panty-method',
    '/fugitives'
]

export const GUEST_ROUTES = [
    '/parent-control',
    '/cinderella-effect',
    '/virgin',
    '/alien-territory',
    '/code-word',
]
export const WORK_ROUTES = [
    '/phone-sex',
    '/comfort-zone',
    '/interview',
    '/businesswoman',
    '/pretty-woman',
]
export const PUBLIC_ROUTES = [
    '/locker-room',
    '/rock-star',
    '/beach',
    '/first-sight',
    '/insulted-innocence',
]

export const AT_HOME_NUMBER = '0'
export const IN_RESTAURANT_NUMBER = '1'

export const GUEST_NUMBER = '2'
export const WORK_NUMBER = '3'
export const PUBLIC_NUMBER = '4'