export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest1.png?alt=media&token=ac715647-bf95-4583-8152-39e997a3e94b"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest2.png?alt=media&token=ad7072d5-e555-48e6-8231-b9c012bcb699"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest3.png?alt=media&token=f5688719-c6ce-4619-9741-6d796ede2803"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest4.png?alt=media&token=9d5ae1a6-7b6d-48e0-b0a8-7b7571c5244b"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest6.png?alt=media&token=b7ba5f6b-d65a-4528-bfce-26701e1cca35"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest7.png?alt=media&token=a8837ac4-9234-4a89-9083-acf40c27cf96"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest8.png?alt=media&token=210e6809-408b-447a-97de-346b4010e73b"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest9.png?alt=media&token=df08ae3a-00b1-4d32-b51b-0bf26bcca60c"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest10.png?alt=media&token=d2774af2-ffd6-4c95-9d3a-be7236c0e6e4"
export const src10="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest11.png?alt=media&token=d5b50993-664b-4fe3-926b-ffcffb0cb34f"
export const src11="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest12.png?alt=media&token=d88e4023-2786-4db9-ae35-8fedf3634d38"
export const src12="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest13.png?alt=media&token=a0239f9b-f6a9-45f3-a349-6d09142dc4d2"
export const src13="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fguest%2Fguest14.png?alt=media&token=226a5bc7-e0dc-4d0f-99ba-0fc683dca6ce"



