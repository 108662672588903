export const libidoRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
    {
        tab: 10,
    },
    {
        tab: 11,
    },
    {
        tab: 12,
    }
]

export const libido1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido1.png?alt=media&token=0850e8ae-1d48-46e9-9bf0-581bdfe3b210"
export const libido2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido2.png?alt=media&token=586e13f7-b6f0-402a-99b9-c688c8b20e59"
export const libido3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido3.png?alt=media&token=27d4c894-a456-4f10-8e26-368a519d8e71"
export const libido4Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido4.png?alt=media&token=ea5b77dc-7240-46ef-baf4-e0cd2eae2ed2"
export const libido5Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido5.png?alt=media&token=8f6e6857-f907-4656-b92c-5f8bbe05e400"
export const libido6Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido6.png?alt=media&token=05cca5bc-ce43-4708-a9f8-8b5e86ac0949"
export const libido7Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido7.png?alt=media&token=0f1de754-c79f-4817-b1b5-fc13f877d00d"
export const libido8Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido8.png?alt=media&token=d33766f0-b19e-4285-9a1f-fb7ea8503ac6"
export const libido9Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido8.png?alt=media&token=d33766f0-b19e-4285-9a1f-fb7ea8503ac6"
export const libido10Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido10.png?alt=media&token=d80209ef-dd8e-4ace-9f50-2014706cfa4e"
export const libido11Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido11.png?alt=media&token=a6df9493-f659-46dc-b874-69df45e36743"
export const libido12Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Flibido%2Flibido12.png?alt=media&token=43fcafcb-516d-4bbb-95f4-434458acacd9"