export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fwebcam%2F8.1.2.1.png?alt=media&token=94d7e398-6fc5-46ec-a162-f022ffa59ebe",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fwebcam%2F8.1.2.2.png?alt=media&token=32ea2752-4812-486f-99d3-7ebdfaa3cf8a",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fwebcam%2F8.1.2.3.png?alt=media&token=d5fede98-4ed3-424a-9c5b-ff69a8bf9fac",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fwebcam%2F8.1.2.4.png?alt=media&token=27eb4efb-3d3d-483d-a23b-d07dcf2b822e"
]





