import './InfoDarkBox.scss';
import React from "react";
import {IInfoBoxData} from "../../types/infoDarkBox.types";
import {isLastArrayElementAnOddNumber} from "../../utils/count.utils";

interface IInfoDarkBoxProps {
    title:string;
    infoData: IInfoBoxData[];
    infoFooterText?:string;
}
export const InfoDarkBox =({title,infoData,infoFooterText}:IInfoDarkBoxProps)=>{
    return (
        <div className={'darkBox'}>
            <h4 className={'darkBoxTitle'}>{title}:</h4>
            <div className={'infoBoxContainer'}>
                {infoData.map(({logoSrc, title},index)=>{
                    return <div key={title} className={`infoPointContainer 
                    ${isLastArrayElementAnOddNumber(infoData.length,index)? 'infoPointContainerLastChild':''}`}>
                        <img loading="lazy" src={logoSrc} alt={''}/>
                        <p>{title}</p>
                    </div>
                })}
            </div>
            {infoFooterText?<h6 className={'darkBoxFooter'}>{infoFooterText}...</h6>:null}
        </div>
    )
}
