import {InfoDarkBox} from "../../InfoDarkBox/InfoDarkBox";
import {IInfoBoxData} from "../../../types/infoDarkBox.types";
import {TFunction} from "i18next";
import {
    cunnilingusLogo,
    hardModeLogo,
    manualTechniquesLogo,
    masturbationTechniquesLogo,
    oralSexLogo,
    positionsForOrgasmLogo,
    sexCalendarLogo,
    talkingGuideLogo,
} from "../../../assets/onBoardingImages/whatYouGetLogo";
import {SMSGameLogo} from "../../../assets/onBoardingImages/gamesLogo";
export const SubscriptionBenefits =({t}:{t:TFunction})=>{
    const subscriptionBenefitsData:IInfoBoxData[]=[
        {logoSrc:sexCalendarLogo,title:t("payInfoScreen.whatYouGet.sexCalendarText")},
        {logoSrc:positionsForOrgasmLogo,title:t("payInfoScreen.whatYouGet.topPositionsText")},
        {logoSrc:masturbationTechniquesLogo,title:t("payInfoScreen.whatYouGet.masturbationTechniquesText")},
        {logoSrc:oralSexLogo,title:t("payInfoScreen.whatYouGet.OralSexTechniquesText")},
        {logoSrc:manualTechniquesLogo,title:t("payInfoScreen.whatYouGet.manualTechniquesText")},
        {logoSrc:SMSGameLogo,title:t("payInfoScreen.whatYouGet.guideDuringSexText")},
        {logoSrc:hardModeLogo,title:t("payInfoScreen.whatYouGet.hardModeText")},
        {logoSrc:cunnilingusLogo,title:t("payInfoScreen.whatYouGet.mindBlowingText")},
        {logoSrc:talkingGuideLogo,title:t("payInfoScreen.whatYouGet.tenTipsForExcitement")}
    ]

    return (
        <InfoDarkBox infoData={subscriptionBenefitsData} title={t("payInfoScreen.whatYouGet.title")}
                     infoFooterText={t("payInfoScreen.whatYouGet.manyMoreText")}/>
    )
}
