import {useTranslation} from "react-i18next";
import {sign1Src, sign2Src, sign3Src, sign4Src, sign5Src, sign6Src} from "./constants";

const Signs = () => {
    const { t } = useTranslation('app');
    const title = t("signs_of_worthy_man_title")
    const subText1 = t("signs_of_worthy_man_1")
    const article1= t("signs_of_worthy_man_1_desc")
    const subText2 = t("signs_of_worthy_man_2")
    const article2= t("signs_of_worthy_man_2_desc")
    const subText3 = t("signs_of_worthy_man_3")
    const article3= t("signs_of_worthy_man_3_desc")
    const subText4 = t("signs_of_worthy_man_4")
    const article4= t("signs_of_worthy_man_4_desc")
    const subText5 = t("signs_of_worthy_man_5")
    const article5= t("signs_of_worthy_man_5_desc")
    const subText6 = t("signs_of_worthy_man_6")
    const article6= t("signs_of_worthy_man_6_desc")
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText1}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={sign1Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText2}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={sign2Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText3}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article3}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={sign3Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText4}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article4}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={sign4Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText5}
            </div>
            <div className="child-container__article-title mt-16 typography-16 ">
                {article5}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={sign5Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText6}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article6}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={sign6Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Signs