import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import { maintain6Src} from "./contstants";
const Tasks = () => {
    const { t } = useTranslation('app');
    const title = t("maintain_conversation_task_title")
    const note = t("maintain_conversation_task_note")
    const list = t("maintain_conversation_task_content", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__list">
                    {list.map((i: string, idx: number) => <div
                        key={idx}
                        className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={maintain6Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Tasks