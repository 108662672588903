export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fhow-to-relax%2Fhow1.png?alt=media&token=7cf8949e-29db-4bad-b776-942a7ea9953c"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fhow-to-relax%2Fhow.png?alt=media&token=26f0571c-9fd3-452d-9b09-7d5da630e3c1"
