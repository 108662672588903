export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
    {
        tab: 10,
    },
    {
        tab: 11,
    },
    {
        tab: 12,
    }
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles1.png?alt=media&token=715d7406-3787-432b-b197-28000326e8f6"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles2.png?alt=media&token=c49e1448-7420-409c-9ee6-ed40341e2944"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles3.png?alt=media&token=0eb09561-9bde-4348-bb1f-d33aae5b0f25"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles4.png?alt=media&token=a1baca28-19b4-44ed-87eb-37cb2171c204"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles5.png?alt=media&token=506f78b3-2f90-4d7a-a291-b34adb1ec8c2"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles6.png?alt=media&token=f4fe28cb-56dc-4a52-a8af-e8e6eb4b9f56"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles7.png?alt=media&token=31d9162a-96fe-4016-93fd-0a7356de7ac5"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles9.png?alt=media&token=aaa3f645-9d94-4844-8892-f2739997b0e5"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles10.png?alt=media&token=858c7362-eb89-49b7-88c7-683e3549ea42"
export const src10="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles11.png?alt=media&token=bc15dd63-e746-41d7-b3d6-a3682e021918"
export const src11="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles12.png?alt=media&token=ab104f37-9709-48fb-9b7b-d6be20ad3a29"
export const src12="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles13.png?alt=media&token=f4187f05-245e-4fca-8790-2f0682d7bebf"
export const src13="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Frole-playing%2Froles14.png?alt=media&token=72b922bb-d90c-4155-b26a-6aacf87714b6"
