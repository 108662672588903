import {useLocation} from "react-router";
import {useEffect, useState} from "react";

export const useTestElementsVisibility=(routes: string[])=>{
    const location=useLocation();

    const [isTestElementVisible,setIsTestElementVisible]=useState(false)

    const getTestElementVisibility=(location:string,routes:string[])=>{
        return routes.includes(location)
    }

    useEffect(()=>{
        if(getTestElementVisibility(location.pathname,routes)) {
            setIsTestElementVisible(true)
        } else {
            setIsTestElementVisible(false)
        }
    },[location.pathname])

    return isTestElementVisible;
}
