import RadioCheckButton from "../../../components/RadioCheckButton";
import classNames from "classnames";

export type TProps = {
    plan: number;
    oldPrice?: string;
    newPrice: number;
    perDay: number;
    sale: string;
    total: number;
    popular?: boolean;
    selected: boolean;
    setSelected: () => void;
    t:Function
}

const SpecialOrderOption = (props: TProps) => {
    return(
        <div className={classNames("special-order__option mt-16", {popular: !!props.popular})} key={props.plan}>
            {!!props.popular && <div className="special-order__option__most-popular row-container justify-center ">
                <div className="typography-14 typography-600"> {props.t("payInfoScreen.mostPopular")}</div>
            </div>}
            <div className="special-order__option__wrapper row-container space-between mt-16">
            <div className="special-order__option__wrapper__plan__left column">
                <div className="special-order__option__wrapper__plan__left__top row-container align-center">
                    <RadioCheckButton setChecked={props.setSelected} checked={props.selected} />
                    <div className="typography-16 typography-600 common-color">
                        {props.plan}-{props.t("payInfoScreen.weekPlan")}
                    </div>
                </div>
                <div className="special-order__option__wrapper__plan__left__bottom row-container space-between">
                    {!!props.oldPrice && <div
                        className="typography-14 special-order__option__wrapper__plan__left__bottom__old common-color"> ${props.oldPrice}</div>}
                    <div className="typography-14 common-color"> ${props.newPrice}</div>
                </div>

            </div>
            <div className="special-order__option__wrapper__plan__right column">
                <div className="special-order__option__wrapper__plan__right__per-day row-container align-center">
                    <div className="special-order__option__wrapper__plan__right__per-day__left typography-600 typography-32 red-color">
                        <div> ${props.total}</div>
                    </div>
                    <div className="special-order__option__wrapper__plan__right__per-day__right column">
                        <div className="typography-16 typography-600 red-color"> {props.perDay}</div>
                        <div className="typography-8"> PER DAY</div>
                    </div>
                </div>
                {!!props.sale && <div className="special-order__option__wrapper__plan__right__sales typography-14 mt-8">
                    ${props.sale}
                </div>}
            </div>
            </div>
            {!!props.popular && <div className="red-shadow"/> }
        </div>
    )
}

export default SpecialOrderOption