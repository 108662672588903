export const MAN_OF_MY_DREAMS = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-1.png?alt=media&token=62127c63-d71e-4d6d-abea-a6e155c6c036&_gl=1*ajilao*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDc3NS42MC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-2.png?alt=media&token=9c0e10ac-aba5-4598-853d-57d219aeb662&_gl=1*ca9vzb*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDg0OS41Mi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-3.png?alt=media&token=305bd492-ad98-41d5-8177-3be8b17a6643&_gl=1*1vk9ugd*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDg1NS40Ni4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-4.png?alt=media&token=aec3d0c5-1a0c-4fcd-a12d-c911bddb935d&_gl=1*xbfvm8*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDg2Mi4zOS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-5.png?alt=media&token=4fbfe9d3-824f-494c-9c79-7b48fd84e37f&_gl=1*4oerw6*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDg2OS4zMi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-6.png?alt=media&token=7099b9ee-5c22-4f2b-ab09-e42b50f8a106&_gl=1*avvmri*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDg3Ni4yNS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-7.png?alt=media&token=dcf4b16c-0400-49a8-8e9c-c0b6bffca251&_gl=1*h5lo1c*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDg4Ni4xNS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-8.png?alt=media&token=a0ddc93b-4ec7-4e80-9937-12608250b56e&_gl=1*7pqvr7*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDg5Ny40LjAuMA..'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-9.png?alt=media&token=2383dd9a-251c-4079-b3bb-a2d5c6dd4e76&_gl=1*1eddte4*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDkwNi42MC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-10.png?alt=media&token=635e208d-287d-43ad-9254-bdbb2a1f8dbc&_gl=1*yz36jf*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDkxMy41My4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-11.png?alt=media&token=c50fe5f5-609f-435d-8eb1-d15f3544f40b&_gl=1*160u1m0*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDkyMC40Ni4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course1%2Fcourse1-12.png?alt=media&token=72b56a35-0c52-41ff-83dc-ff286b4c2346&_gl=1*18puztn*_ga*MTYxNjU0NTU1MS4xNjk3MTM3OTkz*_ga_CW55HF8NVT*MTY5NzE0NDU4NC4yLjEuMTY5NzE0NDkyNy4zOS4wLjA.'
    },
] as const
export const PATH_TO_PLEASURE = [
    {
    img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.1.png?alt=media&token=fc91122d-3c9c-4082-88cc-8e1de5a0f2e7&_gl=1*6flt9o*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTE5OS42MC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.2.png?alt=media&token=38d3e571-8646-4576-9c1b-b09ba62cc985&_gl=1*4abgnl*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTI0OS4xMC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.3.png?alt=media&token=a94ed582-8d5d-4998-be7f-df67c4255a7d&_gl=1*sej6hj*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTI2MC42MC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.4.png?alt=media&token=589630d8-c045-4880-b83b-16ba3503acf8&_gl=1*1dpjvsq*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTI3Mi40OC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.5.png?alt=media&token=60649782-fc80-41be-adef-ba5300696cc9&_gl=1*og2la0*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTI4MS4zOS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.6.png?alt=media&token=b888d843-cb9b-4880-a137-eb96712e3ff8&_gl=1*4qv7la*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTI5Mi4yOC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.7.png?alt=media&token=02da0afa-035c-4cfd-92a6-5b0764f345f5&_gl=1*wz3hix*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTMwNC4xNi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.8.png?alt=media&token=e92cd366-38cf-48be-960f-adee0eb4d04e&_gl=1*12vd0qo*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTMxMy43LjAuMA..'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.9.png?alt=media&token=ef5bf76c-aae5-40de-8315-43f2d2cb1441&_gl=1*9np3s1*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTMyNC42MC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.10.png?alt=media&token=7a17ccaa-f24e-49ee-a365-f9895840011c&_gl=1*yf41jg*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTMzMy41MS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.11.png?alt=media&token=e5e8642e-8331-4433-a909-a309b76e9b0b&_gl=1*14oznhb*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTM0NS4zOS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.12.png?alt=media&token=4de8ec94-4de2-41f1-8940-a5c0d9dc7914&_gl=1*snco1l*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTM0OC4zNi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.13.png?alt=media&token=0e7537a7-a726-49d4-9e9e-1ffaca94ca1f&_gl=1*gykl81*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTM1MC4zNC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.14.png?alt=media&token=a5cb5b8e-1da5-4de1-9e02-38fd36540648&_gl=1*1g92tqr*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTM1Mi4zMi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.15.png?alt=media&token=cef998c0-9fa4-42b4-ab4f-7115b040aa63&_gl=1*1ifq4bm*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTM1NC4zMC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.16.png?alt=media&token=16180e81-8ba7-4a63-b477-107da2dfce0f&_gl=1*1oy6i6h*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTM1Ni4yOC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.17.png?alt=media&token=ffa8ecf3-1327-42ae-91ba-8bd1b0a3a972&_gl=1*10g3f21*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTM1OC4yNi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.18.png?alt=media&token=61a952db-55cd-45ad-849b-2925c3790ea5&_gl=1*x1v1fj*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTQxOS42MC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.19.png?alt=media&token=79e20ea0-08a7-40b6-b0c4-2fe487982286&_gl=1*gye1jx*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTQyMS41OC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.20.png?alt=media&token=75ab32b2-7bfa-449c-b4b6-c90a71ba7633&_gl=1*118c8qb*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTQyNS41NC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.21.png?alt=media&token=1896a30c-b079-48c4-8a15-728dba25c8e1&_gl=1*21ma1d*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTQyNy41Mi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.22.png?alt=media&token=2033dfc5-e633-460f-86d5-82fe59058508&_gl=1*q815g5*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTQzMC40OS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.23png.png?alt=media&token=917b327d-2771-4e9a-a3d1-226edf043772&_gl=1*17sl496*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTQzMi40Ny4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.24.png?alt=media&token=4570eeeb-54f9-4105-9a2a-0f21b8f83369&_gl=1*cdo4vy*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTQzNS40NC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2%2Fcourse-2.25.png?alt=media&token=3aedab0a-5a9d-44fe-b276-bda8cf038e80&_gl=1*1c9eifw*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMDg2My4xLjEuMTY5ODcwMTQzNy40Mi4wLjA.'
    },
] as const
export const EMANCIPATION = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fcourse3.1.png?alt=media&token=d6eee81b-1f06-4734-a62d-bb8695b52104&_gl=1*1r8gjpu*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMzc3NC4yLjEuMTY5ODcwMzc5OS4zNS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fcourse3.2.png?alt=media&token=8d5a4c94-eef3-4a44-a63e-005ff05090af&_gl=1*d0cvpj*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMzc3NC4yLjEuMTY5ODcwMzgwMS4zMy4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fcourse3.3.png?alt=media&token=83e379ff-2502-451e-a853-137510458e6a&_gl=1*96sgsm*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMzc3NC4yLjEuMTY5ODcwMzgwMy4zMS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fcourse3.4.png?alt=media&token=53ceb7b7-d7d4-4449-ab4c-6b56d38e606a&_gl=1*ka5dzx*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMzc3NC4yLjEuMTY5ODcwMzgwNS4yOS4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fcourse3.5.png?alt=media&token=64ce8fe7-5bd8-4a55-beaf-34fbe7d665b4&_gl=1*1r9kc3v*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMzc3NC4yLjEuMTY5ODcwMzgwNi4yOC4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Fcourse3.6.png?alt=media&token=7ac5b264-45a5-4ea8-ac9b-dd770db742b5&_gl=1*1gfcb3v*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMzc3NC4yLjEuMTY5ODcwMzgwOC4yNi4wLjA.'
    },
] as const
export const LOVE_YOUR_SELF = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course4%2Fcourse4.1.png?alt=media&token=f34cd9e1-813e-4ac5-886d-de78c6b4db45&_gl=1*15jovfz*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMzc3NC4yLjEuMTY5ODcwNDAzNi4yNi4wLjA.'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course4%2Fcourse4.2.png?alt=media&token=b5222807-2096-4c13-b41b-e0ed58c05ec7&_gl=1*j062p*_ga*Njc5MzI1MTUuMTY5ODcwMDg2NA..*_ga_CW55HF8NVT*MTY5ODcwMzc3NC4yLjEuMTY5ODcwNDAzOC4yNC4wLjA.'
    },
] as const

export const MAN_OF_MY_DREAMS_ROUTES = [
    '/first-date-rule/1',
    '/posing',
    '/stories',
    '/sign-of-a-worthy-man/1',
    '/approaches-to-connect/1',
    '/maintain-conversation/1',
    '/path-connection/1',
    '/behave-on-a-date/1',
    '/tips/1',
    '/affirmations/1'
]

export const PATH_TO_PLEASURE_ROUTES = [
    '/rules-of-the-perfect-lover/1',
    '/attitude-towards-sex',
    '/masturbation/1',
    '/libido/1',
    '/sounds/1',
    '/misconceptions/1',
    '/preparation/1',
    '/erogenous-zones/1',
    '/phrases-for-hot-messaging/1',
    '/male-erogenous-zones/1',
    '/kissing-techniques/1',
    '/taboo/1',
    '/secrets-techniques/1',
    '/blow-job/1',
    '/rid-of-the-gag',
    '/how-to-relax/1',
    '/sex-toys/1',
    '/manual-techniques/1',
    '/sex-games/1',
    '/anal-sex/1',
    '/liberation',
    '/threesome/1',
    '/top-poses/1',
    '/sex-scenarios/1',
    '/bdsm/1',
]

export const EMANCIPATION_ROUTES = [
    '/hot-phrases',
    '/guest/1',
    '/role-playing/1',
    '/tests/1',
    '/common-techniques/1',
    '/poses/1',
]


export const LOVE_YOUR_SELF_ROUTES = [
    '/practices/1',
    '/days'
]
export const MAN_OF_MY_DREAMS_NUMBER = '0'

export const PATH_TO_PLEASURE_NUMBER = '1'

export const EMANCIPATION_NUMBER = '2'


export const LOVE_YOUR_SELF_NUMBER = '3'