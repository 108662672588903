export enum AppThemes{
    LIGHT='light',
    DARK='dark'
}

export const LIGHT_THEME= '#ffffff';
export const PRIMARY_RED_COLOR_HOVER= '#fae7e7';
export const DARK_PRIMARY_RED_COLOR_HOVER= 'rgba(211,34,34,0.1)'

export const DARK_THEME= '#1f1e1e';
export const PRIMARY_RED_COLOR= '#cf0a0a';
