import {useTranslation} from "react-i18next";
import { zones3Src} from "./constants";

const TurnOn1 = () => {
    const { t } = useTranslation('app');
    const list = t("course2_erogenous_list2", { returnObjects: true })
    const list1 = t("course2_erogenous_list3", { returnObjects: true })
    const article = t("course2_erogenous_article1")
    return(
        <div className="child-container">
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__article-title mt-16 typography-14">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={zones3Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default TurnOn1