import {useTranslation} from "react-i18next";
import {src6} from "./constants";

const Deeper = () => {
    const { t } = useTranslation('app');
    const subtitle = t("top_poses_subtitle5")
    const article = t("top_poses_article5")
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src6} alt="course-preview" />
            </div>
        </div>
    )
}

export default Deeper