

export const misconceptionRules = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    }
]

export const misconception1Src= 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmisconception%2Fmisconception1.png?alt=media&token=17cf2dc9-f19c-4349-a1c5-e8576dab0234'
export const misconception2Src= 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmisconception%2Fmisconception2.png?alt=media&token=7b659a9f-db5c-449a-b39d-693966675c2a'
export const misconception3Src= 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmisconception%2Fmisconception3.png?alt=media&token=7f9c1887-c61b-4443-9245-878baf1ec206'