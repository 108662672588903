import {useTranslation} from "react-i18next";
import { placeSrc} from "./constants";

const Place = () => {
    const { t } = useTranslation('app');
    const title = t("man_of_my_dreams_rules_article4")
    const subText = t("man_of_my_dreams_rules_article4_subtitle")
    const article = t("man_of_my_dreams_rules_article4_title")
    const list = t("man_of_my_dreams_rules_article4_content", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="mt-20 typography-14">
                {subText}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: {tab: string}) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={placeSrc} alt="course-preview" />
            </div>
        </div>
    )
}
export default Place