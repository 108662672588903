export enum FormErrorMessages {
    EMPTY_EMAIL='createAccountScreen.errorMessages.emptyEmail',
    EMPTY_PASSWORD='createAccountScreen.errorMessages.emptyPassword',
    EMPTY_CONFIRMATION_PASSWORD='createAccountScreen.errorMessages.emptyConfirmationPassword',
    EMPTY_USERNAME='createAccountScreen.errorMessages.emptyUsername',
    NOT_VALID_EMAIL='createAccountScreen.errorMessages.notValidEMail',
    SHORT_PASSWORD='createAccountScreen.errorMessages.shortPassword',
    PASSWORDS_DO_NOT_MATCH='createAccountScreen.errorMessages.passwordsDoNotMatch',
    DO_NOT_AGREE_WITH_POLICY='createAccountScreen.errorMessages.doNotAgreeWithPolicy'
}

export enum LogInWarnings {
    DO_NOT_HAVE_ACCOUNT='do not have account',
    DO_NOT_CONFIRM_EMAIL='do not confirm email'
}
