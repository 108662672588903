import {useTranslation} from "react-i18next";
import {masturbation7Src} from "./constants";

const Fancy = () => {
    const { t } = useTranslation('app');
    const title = t("course2_masturbation_title7")
    const list = t("course2_masturbation_list7", { returnObjects: true })

    const title1 = t("course2_masturbation_title8")
    const list1 = t("course2_masturbation_list8", { returnObjects: true })
    return (
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={masturbation7Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Fancy