import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import {getStorage} from "firebase/storage";
import { getAuth, updatePassword } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
        // apiKey: process.env.REACT_APP_apiKey,
        // authDomain: process.env.REACT_APP_authDomain,
        // databaseURL: process.env.REACT_APP_databaseURL,
        // projectId: process.env.REACT_APP_projectId,
        // storageBucket: process.env.REACT_APP_storageBucket,
        // messagingSenderId: process.env.REACT_APP_messagingSenderId,
        // appId: process.env.REACT_APP_appId,
        // measurementId: process.env.REACT_APP_measurementId
    apiKey: "AIzaSyBf1zp5xcyjGggR2UqIa4p-_yOdWvp6cQg",
    authDomain: "divinedesires-436c5.firebaseapp.com",
    databaseURL: "https://divinedesires-436c5-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "divinedesires-436c5",
    storageBucket: "divinedesires-436c5.appspot.com",
    messagingSenderId: "195408292524",
    appId: "1:195408292524:web:502d86350ac24761114d09",
    measurementId: "G-M69QN8FE1Y"
};

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

export const storageFirebase = getStorage();
export const db = getDatabase();

export const auth = getAuth();

export const googleProvider = new GoogleAuthProvider();

//
// {
//     "rules": {
//     "usersData": {
//         "$uid": {
//             ".read": "auth !== null",
//                 ".write": "auth.uid === $uid"
//         }
//     },
//     "usersEmails": {
//         ".read": "false",
//             ".write": "auth.uid === '9VIjZX8Mh8OeJJfkNw0dxD0fJk53'",
//     },
// },
// }

