import '../FirstDateRule/styles.scss'
import {useTranslation} from "react-i18next";
import {placeSrc} from "../FirstDateRule/constants";
import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import {chairSrc, floorSrc, inspireSrc, pairSrc, portraitSrc, selfieSrc, standingSrc, wallSrc} from './constants';
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {MAN_OF_MY_DREAMS} from "../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";
const Posing = () => {
    const { t } = useTranslation('app');
    const title = t("posing_title")
    const article = t("posing_article")
    const posing1 = t("posing_first")
    const posing2 = t("posing_second")
    const posing3 = t("posing_third")
    const posing4 = t("posing_forth")
    const posing5 = t("posing_fifth")
    const posing6 = t("posing_six")
    const posing7 = t("posing_seven")
    const posing9 = t("posing_9")
    const end = t("posing_end")
    const titleMain = t("man_of_my_dreams_course")
    const user = getAuth()
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
           <TabsHeader title={titleMain}
                       isActive={!!list[("man_of_my_dreams") + 1]}
                       objectToSave={{
                           img: MAN_OF_MY_DREAMS[1].img,
                           value: 'man_of_my_dreams',
                           route: '/posing',
                           index: 1
                       }}
                       uuid={"man_of_my_dreams1"}
                       route={"/courses/man-of-my-dreams"}
           />
        <div className="child-container without-left-padding">
            <div className="child-container__title typography-20 padding-right-24">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16 padding-right-24">
                {article}
            </div>
            <div className="mt-24 typography-18 typography-bold padding-right-24">
                {posing1}
            </div>
            <div>
                <ScrollingCarousel>
                    {selfieSrc.map(i => <div className="child-container__image mt-24 padding-right-24">
                        <img loading="lazy" src={i} alt="course-preview" />
                    </div>)}
                </ScrollingCarousel>
            </div>
            <div className="mt-24 typography-18 typography-bold padding-right-24">
                {posing2}
            </div>
            <div>
                <ScrollingCarousel>
                    {chairSrc.map(i => <div className="child-container__image mt-24 padding-right-24">
                        <img loading="lazy" src={i} alt="course-preview" />
                    </div>)}
                </ScrollingCarousel>
            </div>
            <div className="mt-24 typography-18 typography-bold padding-right-24">
                {posing3}
            </div>
            <div>
                <div className="child-container__image mt-24 padding-right-24">
                <img loading="lazy" src={standingSrc} alt="course-preview" />
                </div>
            </div>
            <div className="mt-24 typography-18 typography-bold padding-right-24">
                {posing4}
            </div>
            <div>
                <div className="child-container__image mt-24 padding-right-24">
                <img loading="lazy" src={floorSrc} alt="course-preview" />
                </div>
            </div>
            <div className="mt-24 typography-18 typography-bold padding-right-24">
                {posing5}
            </div>
            <div>
                <div className="child-container__image mt-24 padding-right-24">
                <img loading="lazy" src={wallSrc} alt="course-preview" />
                </div>
            </div>
            <div className="mt-24 typography-18 typography-bold padding-right-24">
                {posing6}
            </div>
            <div>
                <div className="child-container__image mt-24 padding-right-24">
                <img loading="lazy" src={portraitSrc} alt="course-preview padding-right-24" />
                </div>
            </div>
            <div className="mt-24 typography-18 typography-bold padding-right-24">
                {posing7}
            </div>
            <div>
                <ScrollingCarousel>
                    {pairSrc.map(i => <div className="child-container__image mt-24 padding-right-24">
                        <img loading="lazy" src={i} alt="course-preview" />
                    </div>)}
                </ScrollingCarousel>
            </div>
            <div className="mt-24 typography-18 typography-bold padding-right-24">
                {posing9}
            </div>
            <div>
                <ScrollingCarousel>
                    {inspireSrc.map(i => <div className="child-container__image mt-24 padding-right-24">
                        <img loading="lazy" src={i} alt="course-preview" />
                    </div>)}
                </ScrollingCarousel>
            </div>
            <div className="mt-16 typography-20 typography-bold padding-right-24">
                {end}
            </div>
        </div>
        </>
    )
}

export default Posing