import {ButtonThemes} from "../../../constants/button.constants";
import './Button.scss';
import {useContext} from "react";
import {ThemeContext} from "../../../context/themeContext";

interface ButtonProps {
    text:string;
    buttonTheme:ButtonThemes;
    width:number;
    onClick?: ()=>void;
    isDisabled?:boolean;
    className?:string;
    buttonLogo?:string;
}

export const Button= ({isDisabled=false,buttonLogo,className, onClick,text,buttonTheme,width}:ButtonProps)=>{
    const { theme } = useContext(ThemeContext);

    return (
        <button disabled={isDisabled} onClick={onClick} className={`button ${className} ${buttonTheme===ButtonThemes.LIGHT? `whiteButtonTheme ${theme}`:'redButtonTheme'}`}
                style={{width:`${width}%`}}>
            {buttonLogo ? <img loading="lazy" src={buttonLogo} alt={''}/> : null}
            {text}
        </button>
    )
 }
