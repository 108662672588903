import TabsFooter from "components/Bar"
import { COURSE_ROUTES } from "constants/routes.constants"
import {Route, Routes, useParams } from "react-router"
import {BehaveOnADateRoutes} from "./constants";
import Behaviour from "./Behaviour";
import TheModel from "./TheModel";
import Look from "./Look";
import Place from "./Place";
import '../FirstDateRule/styles.scss'
import Phrases from "./Phrases";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {MAN_OF_MY_DREAMS} from "../../CourseItem/contants";


const BehaveOnADate = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("man_of_my_dreams_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("man_of_my_dreams") + 7]}
                        objectToSave={{
                            img: MAN_OF_MY_DREAMS[7].img,
                            value: 'man_of_my_dreams',
                            route: '/behave-on-a-date/1',
                            index: 7
                        }}
                        uuid={"man_of_my_dreams7"}
                        route={"/courses/man-of-my-dreams"}
            />
        <Routes>
            <Route path={'/1'} element={<Behaviour />} />
            <Route path={'/2'} element={<TheModel />} />
            <Route path={'/3'} element={<Look />} />
            <Route path={'/4'} element={<Place />} />
            <Route path={'/5'} element={<Phrases />} />
        </Routes>

            <TabsFooter tab={tab || ''} routes={BehaveOnADateRoutes} routeToNavigate={COURSE_ROUTES.BEHAVE_ON_A_DATE}  />
        </>
    )
}

export default BehaveOnADate