export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const maleErogenousZones1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FmaleErogenousZones%2FmaleErogenousZones1.png?alt=media&token=45821124-a5b6-4192-8f40-780b2ba9681b"
export const maleErogenousZones2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2FmaleErogenousZones%2FmaleErogenousZones2.png?alt=media&token=ff627814-847d-441f-9ab5-8c0eb4c5a367"

