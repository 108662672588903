import {useParams} from "react-router";
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../constants/routes.constants";
import {PathConnectionRoutes} from "./constants";
import '../FirstDateRule/styles.scss'
import FirstWay from "./FirstWay";
import SecondWay from "./SecondWay";
import ThirdPartOfWays from "./ThirdPartOfWays";
import SixWay from "./SixWay";
import SecondPartOfWays from "./SecondPartOfWays";
import ForthPartOfWays from "./ForthPartOfWays";
import FifthPartOfWays from "./FifthPartOfWays";
import SeventhPartOfWays from "./SeventhPartOfWays";
import SocialSurvey from "./SocialSurvey";
import Subway from "./Subway";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {MAN_OF_MY_DREAMS} from "../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";

const PathConnection = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("man_of_my_dreams_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("man_of_my_dreams") + 6]}
                        objectToSave={{
                            img: MAN_OF_MY_DREAMS[6].img,
                            value: 'man_of_my_dreams',
                            route: '/path-connection/1',
                            index: 6
                        }}
                        uuid={"man_of_my_dreams6"}
                        route={"/courses/man-of-my-dreams"}
            />
            <Routes>
                <Route path={'/1'} element={<FirstWay />} />
                <Route path={'/2'} element={<SecondWay />} />
                <Route path={'/3'} element={<ThirdPartOfWays />} />
                <Route path={'/4'} element={<SixWay />} />
                <Route path={'/5'} element={<SecondPartOfWays />} />
                <Route path={'/6'} element={<ForthPartOfWays />} />
                <Route path={'/7'} element={<FifthPartOfWays />} />
                <Route path={'/8'} element={<SocialSurvey />} />
                <Route path={'/9'} element={<SeventhPartOfWays />} />
                <Route path={'/10'} element={<Subway />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={PathConnectionRoutes} routeToNavigate={COURSE_ROUTES.PATH_CONNECTION}  />
        </>
    )
}

export default PathConnection