import '../../FirstDateRule/styles.scss'
import {useTranslation} from "react-i18next";
import { src1, src10, src11, src12, src13, src2, src3, src4, src5, src6, src7, src8, src9 } from './constants';
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {EMANCIPATION, PATH_TO_PLEASURE} from "../../../CourseItem/contants";
const HotPhrases = () =>{
    const { t } = useTranslation('app');
    const title = t("course3_hot_phrases_title")
    const list1 = t("course3_hot_phrases_list1", { returnObjects: true })
    const list2 = t("course3_hot_phrases_list2", { returnObjects: true })
    const list3 = t("course3_hot_phrases_list3", { returnObjects: true })
    const list4 = t("course3_hot_phrases_list4", { returnObjects: true })
    const list5 = t("course3_hot_phrases_list5", { returnObjects: true })
    const list6 = t("course3_hot_phrases_list6", { returnObjects: true })
    const list7 = t("course3_hot_phrases_list7", { returnObjects: true })
    const list8 = t("course3_hot_phrases_list8", { returnObjects: true })
    const list9 = t("course3_hot_phrases_list9", { returnObjects: true })
    const list10 = t("course3_hot_phrases_list10", { returnObjects: true })
    const list11 = t("course3_hot_phrases_list11", { returnObjects: true })
    const list12 = t("course3_hot_phrases_list12", { returnObjects: true })
    const list13 = t("course3_hot_phrases_list13", { returnObjects: true })
    const list14 = t("course3_hot_phrases_list14", { returnObjects: true })
    const titleMain = t("emancipation_course").split(' ')[0]
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("emancipation") + 0]}
                        objectToSave={{
                            img: EMANCIPATION[0].img,
                            value: 'emancipation',
                            route: '/hot-phrases',
                            index: 0
                        }}
                        uuid={"emancipation0"}
                        route={"/courses/emancipation"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list2.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src2} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list3.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src3} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list4.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src4} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list5.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src5} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list6.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src6} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list7.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src7} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list8.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src8} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list9.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src9} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list10.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src10} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list11.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src11} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list12.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src12} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list13.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src13} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list14.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
        </div>
            </>
    )
}

export default HotPhrases