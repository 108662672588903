import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import Lingerie from "./Lingerie";
import Hair from "./Hair";
import Hygiene from "./Hygiene";
import Contraception from "./Contraception";
import Expectation from "./Expectation";
import PutAway from "./PutAway";
import Mindset from "./Mindset";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PATH_TO_PLEASURE} from "../../../CourseItem/contants";
const Preparation = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("path_to_pleasure_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 6]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[6].img,
                            value: 'path_to_pleasure',
                            route: '/preparation/1',
                            index: 6
                        }}
                        uuid={"path_to_pleasure6"}
                        route={"/courses/path_to_pleasure"}
            />
            <Routes>
                <Route path={'/1'} element={<Lingerie />} />
                <Route path={'/2'} element={<Hair />} />
                <Route path={'/3'} element={<Hygiene />} />
                <Route path={'/4'} element={<Contraception />} />
                <Route path={'/5'} element={<Expectation />} />
                <Route path={'/6'} element={<PutAway />} />
                <Route path={'/7'} element={<Mindset />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.PREPARATION}  />
        </>
    )
}

export default Preparation