import {useTranslation} from "react-i18next";
import {src9} from "./constants";

const Partner = () => {
    const { t } = useTranslation('app');
    const subtitle = t("sex_toys_subtitle10")
    const article = t("sex_toys_article10")
    const note = t("sex_toys_note10")
   return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src9} alt="course-preview" />
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
        </div>
    )
}

export default Partner