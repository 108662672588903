import {useParams} from "react-router";
import '../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../constants/routes.constants";
import {routes} from "./constants";
import {Part2} from "./Part2";
import Intro from "./Intro";
import {BeingShy} from "./BeingShy";
import { Myself } from "./Myself";
import { NewWays } from "./NewWays";
import { Down } from "./Down";
import { SayYourFantasies } from "./SayYourFantasies";
import {ConnectYourPartner} from "./ConnectYourPartner";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {EMANCIPATION, LOVE_YOUR_SELF} from "../../CourseItem/contants";
const Practices = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const titleMain = t("love_your_self_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("love_your_self") + 0]}
                        objectToSave={{
                            img: LOVE_YOUR_SELF[0].img,
                            value: 'love_your_self',
                            route: '/practices/1',
                            index: 0
                        }}
                        uuid={"love_your_self0"}
                        route={"/courses/love-your-self"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Part2 />} />
                <Route path={'/3'} element={<BeingShy />} />
                <Route path={'/4'} element={<Myself />} />
                <Route path={'/5'} element={<NewWays />} />
                <Route path={'/6'} element={<Down />} />
                <Route path={'/7'} element={<SayYourFantasies />} />
                <Route path={'/8'} element={<ConnectYourPartner />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.PRACTICES}  />
        </>
    )
}

export default Practices