import {useTranslation} from "react-i18next";
import {src1, src2, src3, src4} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title1 = t("course3_techniques_title1")
    const subtitle2 = t("course3_techniques_title2")
    const subtitle3 = t("course3_techniques_title3")
    const subtitle4 = t("course3_techniques_title4")
    const subtitle5 = t("course3_techniques_title5")
    const article1 = t("course3_techniques_article1")
    const article2 = t("course3_techniques_article2")
    const article3 = t("course3_techniques_article3")
    const article4 = t("course3_techniques_article4")
    const article5 = t("course3_techniques_article5")
    const article6 = t("course3_techniques_article6")
    const article7 = t("course3_techniques_article7")
    const article8 = t("course3_techniques_article8")
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>

            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle3}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article3}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src2} alt="course-preview" />
            </div>

            <div className="child-container__article-title mt-16 typography-16">
                {article4}
            </div>

            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article5}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src3} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article6}
            </div>

            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle5}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article7}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src4} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article8}
            </div>
        </div>
    )
}

export default Intro