import {useTranslation} from "react-i18next";
import {sounds2Src, sounds3Src} from "./constants";

const VaginalSounds = () =>{
    const { t } = useTranslation('app');
    const subTitle = t("course2_sounds_title2")
    const subTitle2 = t("course2_sounds_subtitle3")
    const article = t("course2_sounds_article2")
    const article2 = t("course2_sounds_article3")
    const note = t("course2_sounds_note2")
    const list = t("course2_sounds_desc2", { returnObjects: true })
    const list1 = t("course2_sounds_list2", { returnObjects: true })
    const list2 = t("course2_sounds_list2_1", { returnObjects: true })
    const list3 = t("course2_sounds_list3", { returnObjects: true })
    const list4 = t("course2_sounds_desc3", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string, idx: number) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list2.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={sounds2Src} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article2}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle2}
            </div>
            <div className="child-container__list">
                <ul>
                    {list3.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list4.map((i: string, idx: number) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={sounds3Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default VaginalSounds