export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.1.png?alt=media&token=032506ce-1909-4536-acca-c79b7d7ddda0",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.2.png?alt=media&token=af27836e-7c95-45f8-acda-792b55d222fd",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.3.png?alt=media&token=3a64281e-e770-4975-94ef-17b6919887af",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.4.png?alt=media&token=21c41928-8895-4b72-8559-91edabba0c80",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.5.png?alt=media&token=66a74ff4-887f-4489-b83c-268a7bd5fe02",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.6.png?alt=media&token=f8938c26-ff40-4532-ac38-d1ee3ad34c21",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.7.png?alt=media&token=1f5111de-f7f7-476c-95e1-86b88e45474f",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.8.png?alt=media&token=094a6b02-7314-433b-97ca-48171cd20968",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.9.png?alt=media&token=b2dd425b-8fc5-4667-9a51-321988579398",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.10.png?alt=media&token=dcd11469-6532-4868-82c4-d160e8be3b6f",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.11.png?alt=media&token=e82e02a3-0f68-47e7-b087-fc90c5a0c1f7",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.12.png?alt=media&token=10dc630e-3ee8-48e9-9eff-a40a0ab6aab9",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.13.png?alt=media&token=2fa59981-6c5c-492f-80fa-3aa4d774653d",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.14.png?alt=media&token=0b6ce614-b76a-4807-9898-80595acaf0c5",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.15.png?alt=media&token=ca7bdf83-ed29-43d5-9745-447b88e6a0d2",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.16.png?alt=media&token=0b49c29f-e004-492c-a3ee-a1b47bdefd12",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-4%2F6.2.17.png?alt=media&token=02eb2e51-d4fa-431e-ae7b-cfa46a307c10",
]






