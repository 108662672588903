import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import Intro from "./Intro";
import TurnOn from "./TurnOn";
import TurnOn1 from "./TurnOn1";
import TurnOn2 from "./TurnOn2";
import Before from "./Before";
import During from "./During";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PATH_TO_PLEASURE} from "../../../CourseItem/contants";
const ErogenousZones = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("path_to_pleasure_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 7]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[7].img,
                            value: 'path_to_pleasure',
                            route: '/erogenous-zones/1',
                            index: 7
                        }}
                        uuid={"path_to_pleasure7"}
                        route={"/courses/path_to_pleasure"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<TurnOn />} />
                <Route path={'/3'} element={<TurnOn1 />} />
                <Route path={'/4'} element={<TurnOn2 />} />
                <Route path={'/5'} element={<Before />} />
                <Route path={'/6'} element={<During />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.EROGENOUS_ZONES}  />
        </>
    )
}

export default ErogenousZones