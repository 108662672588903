import {ReactComponent as ProfileIcon} from '../../../assets/app/profileIcon.svg'
import {ReactComponent as LikeIcon} from '../../../assets/app/favoriteIcon.svg'
import {ReactComponent as AchievementIcon} from '../../../assets/app/achievmenticon.svg'
import {ReactComponent as HomeIcon} from '../../../assets/app/homeIcon.svg'
import './style.scss'
import {useLocation, useNavigate} from "react-router";
import {MAIN, PROFILE_ROUTE} from "../../../constants/routes.constants";
import {useEffect, useState} from "react";
import {GeneralProgressResponse, getAllUnits, getGeneralProgress, getScore} from "../../../utils/firebase/userData.utils";
import { getAuth } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
export const AuthHeader = () => {
    const navigate = useNavigate()
    const user = getAuth()
    const {t} = useTranslation('app')
    const all: {[key: string]: string}[] = t("progress_levels", { returnObjects: true })
    const location = useLocation()

    const [progress, setProgress] = useState(0)
    const [generalProgress, setGeneralProgress] = useState<GeneralProgressResponse>({
        courses: false,
        games: false,
        premium: false,
        scenarios: false
    })

    const getGeneralProgressFn = async() => {
        const res = await getAllUnits(user?.currentUser?.accessToken)
        setGeneralProgress(getGeneralProgress(res))
        setProgress(getScore(res))
    }

    useEffect(() => {
        getGeneralProgressFn()
    }, [user]);

    const handleHomePage = () => navigate(MAIN)
    const handleProfilePage = () => {
        if(location?.pathname === PROFILE_ROUTE){
            navigate(-1)
        } else {
            navigate(PROFILE_ROUTE)
        }

    }
    const getProgressIndex = () => (Object.keys(generalProgress) as Array<keyof GeneralProgressResponse>).reduce((acc, key, idx) => {
        if (generalProgress[key] && acc === idx) {
            acc++;
        }
    
        return acc;
    }, 1)

    return(
        <div className="header-container row-container space-between align-center">
            <div className="row-container header-container__group align-center">
                <ProfileIcon id="profile-icon-header" onClick={handleProfilePage} />
                <div className="column">
                    <div className="row-container">
                        <LikeIcon />
                        <span className="typography-12 typography-500">{progress}</span>
                    </div>
                    <div className="row-container">
                        <AchievementIcon />
                        <span className="typography-12 typography-500">{all[getProgressIndex()].title}</span>
                    </div>
                </div>
            </div>
            <HomeIcon id={"home-icon-header"} onClick={handleHomePage} />
        </div>
    )
}