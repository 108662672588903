import {useTranslation} from "react-i18next";
import {src3} from "./constants";

export const Part3 = () => {
    const { t } = useTranslation('app');
    const subtitle = t("threesome_subtitle1")
    const article = t("threesome_article1")
    const article1 = t("threesome_article2")
    const list = t("threesome_list3", { returnObjects: true })
    const list1 = t("threesome_list4", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src3} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
        </div>
    )
}