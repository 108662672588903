import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import Intro from "./Intro";
import Scissors from "./Scissors";
import Weightlessness from "./Weightlessness";
import Lotus from "./Lotus";
import Cross from "./Cross";
import Deeper from "./Deeper";
import Amazon from "./Amazon";
import Pillow from "./Pillow";
import Cowboys from "./Cowboys";
import Soaring from "./Soaring";
import Stomach from "./Stomach";
import Reverse from "./Reverse";
import Kitchen from "./Kitchen";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PATH_TO_PLEASURE} from "../../../CourseItem/contants";
const TopPoses = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("path_to_pleasure_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 22]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[22].img,
                            value: 'path_to_pleasure',
                            route: '/top-poses/1',
                            index: 22
                        }}
                        uuid={"path_to_pleasure22"}
                        route={"/courses/path_to_pleasure"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Scissors />} />
                <Route path={'/3'} element={<Weightlessness />} />
                <Route path={'/4'} element={<Lotus />} />
                <Route path={'/5'} element={<Cross />} />
                <Route path={'/6'} element={<Deeper />} />
                <Route path={'/7'} element={<Amazon />} />
                <Route path={'/8'} element={<Pillow />} />
                <Route path={'/9'} element={<Cowboys />} />
                <Route path={'/10'} element={<Soaring />} />
                <Route path={'/11'} element={<Stomach />} />
                <Route path={'/12'} element={<Reverse />} />
                <Route path={'/13'} element={<Kitchen />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.TOP_POSES}  />
        </>
    )
}

export default TopPoses