export const ApproachesRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
]

export const approach1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fapproaches1.png?alt=media&token=521c4dd3-938a-4e76-89ab-61208c6b2692"
export const approach2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fapproaches2.png?alt=media&token=7f602bd9-240a-4c87-961c-d25c516189b3"
export const approach3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fapproaches3.png?alt=media&token=b092bf6a-0866-49c1-8fda-0ddab4a19e81"