import {useTranslation} from "react-i18next";
import {tips1Src} from "./constants";

const Intrigue = () => {
    const { t } = useTranslation('app');
    const title = t("tips_title")
    const subTitle = t("tips_1_title")
    const article = t("tips_desc", { returnObjects: true })
    const list = t("tips_1_content", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {article.map((i: string) => <div className="child-container__list mt-24 typography-14">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-24 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={tips1Src} alt="course-preview" />
            </div>
        </div>
    )
}
export default Intrigue