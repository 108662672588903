import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './CreateAccountScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {useEffect, useState} from "react";
import {Input} from "../../../components/lego/Input/Input";
import {Checkbox} from "../../../components/lego/Checkbox/Checkbox";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import {Button} from "../../../components/lego/Button/Button";
import {useNavigate} from "react-router";
import {createUserAccount} from "../../../utils/firebase/auth/createUser.utils";
import {isEmailValid, isEmptyInput} from "../../../utils/login.utils";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import {FormErrorMessages} from "../../../constants/logInErrorMessages.constants";
import {loginWithGoogle} from "../../../utils/firebase/auth/googleAuth.utils";
import {googleLogo} from "../../../assets/onBoardingImages/logInLogo";
import {EMAIL_VERIFICATION_ROUTE, EXISTED_EMAIL_ROUTE} from "../../../constants/routes.constants";
import {PaymentModal} from "../../../components/onboarding/PayInfoContainer/PaymentModal";
import GiftModal from "../../onboarding/PayInfoScreen/GiftModal";
import NewDiscountModal from "../../onboarding/PayInfoScreen/NewDiscountModal";
import {priceValues} from "../../../constants/price";
import { TEN_MINUTES_TIMER_DURATION } from "screens/onboarding/PayInfoScreen/PayInfoScreen";
import {MIXPANEL_API} from "../../../components/onboarding/PayInfoContainer/constants";

const EMAIL_ID='email'
const USERNAME_ID='username'
const PASSWORD_ID='password'
const CONFIRMED_PASSWORD_ID='confirmedPassword'
const POLICY_CHECKBOX_ID='policyCheckbox'
export const CreateAccountScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');
    const navigate=useNavigate()
    const [isAgreeWithPolicy,setIsAgreeWithPolicy]=useState(false)
    const [email,setEmail]=useState('')
    const [username,setUsername]=useState('')
    const [password,setPassword]=useState('')
    const [confirmedPassword,setConfirmedPassword]=useState('')
    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()


    const [open, setIsOpen] = useState(false)
    const [isGiftOpen, setIsGiftOpen] = useState(false)
    const [isNewPrice, setIsNewPrice] = useState(false)
    const [isNewDiscount, setIsNewDiscount] = useState(false)
    const [isClosedFirsTime, setIsClosedFirsTime] = useState(false)
    const [isTimerOver, setIsTimerOver] = useState(false)
    const [price, setPrice] = useState(88.99)
    useEffect(() => {
        const localPrice = localStorage.getItem("price_plan")
        const localTimer = localStorage.getItem("isTimerOver")
        if(localPrice === '0' || localPrice === '1' || localPrice === '2') setPrice(priceValues[Number(localPrice)])
        if(localTimer === 'true') setIsTimerOver(true)
        else setIsTimerOver(false)
    }, []);
    useEffect(() => {
        if(isClosedFirsTime && !isGiftOpen){
            setIsGiftOpen(true)
        }
    }, [isClosedFirsTime])
    const trackMixpanelCredentials = async(started?: boolean) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const email = localStorage.getItem("email")
        const data = {
            event: started ? 'registration_flow_started' : "registration_flow_completed",
            data:{registration_provider: "credentials"},
            fbclid: fbclid,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }

    const onEmailFormSend=()=>{
        trackMixpanelCredentials(true)
        if(!isEmptyInput(email)) {
            setErrorState({message:t(FormErrorMessages.EMPTY_EMAIL),errorInputId:EMAIL_ID})
            return;
        }

        if(!isEmptyInput(username)) {
            setErrorState({message:t(FormErrorMessages.EMPTY_USERNAME),errorInputId:USERNAME_ID})
            return;
        }

        if(!isEmptyInput(password)) {
            setErrorState({message:t(FormErrorMessages.EMPTY_PASSWORD),errorInputId:PASSWORD_ID})
            return;
        }

        if(!isEmptyInput(confirmedPassword)) {
            setErrorState({message:t(FormErrorMessages.EMPTY_CONFIRMATION_PASSWORD),errorInputId:CONFIRMED_PASSWORD_ID})
            return;
        }

        if(!isEmailValid(email)) {
            setErrorState({message:t(FormErrorMessages.NOT_VALID_EMAIL),errorInputId:EMAIL_ID})
            return;
        }

        if(password.length<=6) {
            setErrorState({message:t(FormErrorMessages.SHORT_PASSWORD),errorInputId:PASSWORD_ID})
            return;
        }

        if(password !== confirmedPassword) {
            setErrorState({message:t(FormErrorMessages.PASSWORDS_DO_NOT_MATCH),errorInputId:CONFIRMED_PASSWORD_ID})
            return;
        }

        if(!isAgreeWithPolicy) {
            setErrorState({message:t(FormErrorMessages.DO_NOT_AGREE_WITH_POLICY),errorInputId:POLICY_CHECKBOX_ID})
            return;
        }
        localStorage.setItem("isCreation", "true")
        createUserAccount(email,password,username, (res)=>{
            localStorage.setItem("accessToken", res?.accessToken)
            localStorage.setItem("email", email)
            localStorage.removeItem("isCreation")
            trackMixpanelCredentials(false)
            navigate(EMAIL_VERIFICATION_ROUTE)
        }, () => navigate(EXISTED_EMAIL_ROUTE))
    }
    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }

    const getWithDiscount = () => {
        setIsNewDiscount(false)
        setIsNewPrice(true)
        setIsOpen(true)
    }

    const closeModal = () => setIsOpen(false)

    const trackMixpanel = async(started?: boolean) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const email = localStorage.getItem("email")
        const data = {
            event: started ? 'registration_flow_started' : "registration_flow_completed",
            data:{registration_provider: "credentials"},
            fbclid: fbclid,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }
    return (
        <>
            <div>
                <h4 className={'createAccountTitle'}>{t("createAccountScreen.title")}</h4>
                <p className={'createAccountText'}>{t("createAccountScreen.text")}</p>
            </div>

            <ContentContainer>
                <div className={'registrationForm'}>
                    <Input onChange={(value)=>clearErrorData(value,setEmail)}
                           id={EMAIL_ID}
                           inputType={'email'}
                           labelText={t("createAccountScreen.email.label")}
                           placeholderText={t("createAccountScreen.email.placeholder")}
                           errorMessage={errorState.errorInputId===EMAIL_ID?errorState.message:''}
                    />

                    <Input onChange={(value)=>clearErrorData(value,setUsername)}
                           id={USERNAME_ID}
                           inputType={'text'}
                           labelText={t("createAccountScreen.username.label")}
                           placeholderText={t("createAccountScreen.username.placeholder")}
                           errorMessage={errorState.errorInputId===USERNAME_ID?errorState.message:''}
                    />

                    <Input onChange={(value)=>clearErrorData(value,setPassword)}
                           id={PASSWORD_ID}
                           inputType={'password'}
                           placeholderText={t("createAccountScreen.passwordPlaceholder")}
                           errorMessage={errorState.errorInputId===PASSWORD_ID?errorState.message:''}
                    />

                    <Input onChange={(value)=>clearErrorData(value,setConfirmedPassword)}
                           id={CONFIRMED_PASSWORD_ID}
                           inputType={'password'}
                           placeholderText={t("createAccountScreen.confirmPasswordPlaceholder")}
                           errorMessage={errorState.errorInputId===CONFIRMED_PASSWORD_ID?errorState.message:''}
                    />

                    <Checkbox labelText={t("createAccountScreen.privacyPolicyCheckbox")}
                              checkboxTooltipText={errorState.errorInputId === POLICY_CHECKBOX_ID?errorState.message:''}
                              labelAnchors={[
                                  {text:t("createAccountScreen.policyAnchors.terms"), anchor:PolicyAnchors.TERMS},
                                  {text:t("createAccountScreen.policyAnchors.privacy"), anchor:PolicyAnchors.PRIVACY},
                                  {text:t("createAccountScreen.policyAnchors.subscription"), anchor:PolicyAnchors.SUBSCRIPTION}]}
                              isChecked={isAgreeWithPolicy}
                              setIsChecked={(isChecked)=>{
                                  errorState.message&&clearErrorMessage()
                                  setIsAgreeWithPolicy(isChecked)}} />
                </div>
            </ContentContainer>

            <div className={'createAccountButtons'}>
            <Button onClick={onEmailFormSend} text={t("createAccountScreen.createAccountButtonText")}
                    buttonTheme={ButtonThemes.DARK} width={100}
                     />
                <Button onClick={
                    ()=>{
                        trackMixpanel(true)
                        loginWithGoogle(
                        {successfulAuthRoute:()=>{
                                trackMixpanel(true)
                            setIsOpen(true)}})}}
                        text={t("createAccountScreen.googleSignInText")}
                        buttonTheme={ButtonThemes.LIGHT} width={100} buttonLogo={googleLogo}
                />

                <PaymentModal
                    setIsClosedFirsTime={setIsClosedFirsTime}
                    isClosedFirsTime={isClosedFirsTime}
                    t={t}
                    open={open}
                    setOpen={setIsOpen}
                    price={price}
                    isNewPrice={isNewPrice}
                    setIsNewPrice={setIsNewPrice}
                    isTimerOver={isTimerOver} />
                <GiftModal
                    t={t}
                    closeModal={closeModal}
                    setOpen={setIsGiftOpen}
                    onSubmit={() => {
                        setIsOpen(false)
                        setIsNewDiscount(true)
                    }}
                    isOpen={isGiftOpen} />
                <NewDiscountModal
                    t={t}
                    timerDuration={TEN_MINUTES_TIMER_DURATION}
                    isTimerOver={isTimerOver}
                    onClick={() => setIsOpen(true)}
                    setPrice={setPrice}
                    isOpen={isNewDiscount}
                    setOpen={setIsNewDiscount}
                    onSubmit={getWithDiscount}
                />
            </div>
        </>
    )
}
