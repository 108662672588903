import {useTranslation} from "react-i18next";
import {preparation4Src} from "./constants";

const Contraception = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_preparation_title4")
    const article = t("course2_preparation_article4")
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={preparation4Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Contraception