import {useTranslation} from "react-i18next";
import './SignUpOffersContainer.scss';
import {signUpOffer} from "../../../assets/onBoardingImages";
import {coursesLogo, noAdsLogo, practiceToolsLogo} from "../../../assets/onBoardingImages/signUpOffersLogo";
import {OnBoardingImage} from "../OnBoardingImage/OnBoardingImage";
import {ShadowContainer} from "../ShadowContainer/ShadowContainer";

export const SignUpOffersContainer =()=>{
    const { t } = useTranslation('onboarding');

    const offersData=[
        {offerTitle:t("sighUpOfferScreen.signInOffer.options.courses.text"),
            description:t("sighUpOfferScreen.signInOffer.options.courses.label"), logoSrc:coursesLogo},
        {offerTitle:t("sighUpOfferScreen.signInOffer.options.noAds.text"),
            description:t("sighUpOfferScreen.signInOffer.options.noAds.label"), logoSrc:noAdsLogo},
        {offerTitle:t("sighUpOfferScreen.signInOffer.options.practiceTools.text"),
            description:t("sighUpOfferScreen.signInOffer.options.practiceTools.label"), logoSrc:practiceToolsLogo}
    ]

    return (
           <ShadowContainer>
                <div className={'sighUpOfferImageContainer'}>
                    <OnBoardingImage imageSrc={signUpOffer} />
                    <h2 className={'signUpOfferImageTitle'}>{t("sighUpOfferScreen.discountAmountImageTitle")}</h2>
                </div>
                <h2 className={'signUpOfferTitle'}>{t("sighUpOfferScreen.signInOffer.title")}</h2>
                <h6 className={'signUpOfferText'}>{t("sighUpOfferScreen.signInOffer.text")}</h6>
                <div className={'offersContainer'}>
                    {offersData.map(({offerTitle,description, logoSrc})=>{
                        return <div key={offerTitle} className={'offerContainer'}>
                            <img loading="lazy" src={logoSrc} alt={''}/>
                            <div className={'offerDescription'}>
                                <h6>{offerTitle}</h6>
                                <p>{description}</p>
                            </div>
                        </div>
                    })}
                </div>
           </ShadowContainer>
    )
}
