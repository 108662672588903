import {useTranslation} from "react-i18next";
import { src5} from "./constants";

export const Pilot = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course3_rules_title6")
    const article = t("course3_rules_article4")
    const subtitle1 = t("course3_rules_article5")
    const list = t("course3_rules_list8", { returnObjects: true })
    const list1 = t("course3_rules_list9", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src5} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>

        </div>
    )
}