import {useTranslation} from "react-i18next";
import  {src13, src14, src15, src16, src17, src18, src19} from "./constants";

export const Massage = () => {
    const { t } = useTranslation('app');
    const title1 = t("course3_techniques_title14")
    const subtitle15 = t("course3_techniques_title15")
    const subtitle16 = t("course3_techniques_title16")
    const subtitle17 = t("course3_techniques_title17")
    const subtitle18 = t("course3_techniques_title18")
    const subtitle19 = t("course3_techniques_title19")
    const subtitle20 = t("course3_techniques_title20")
    const subtitle21 = t("course3_techniques_title21")
    const subtitle22 = t("course3_techniques_title22")
    const article17 = t("course3_techniques_article17")
    const article18 = t("course3_techniques_article18")
    const article19 = t("course3_techniques_article19")
    const article20 = t("course3_techniques_article20")
    const article21 = t("course3_techniques_article21")
    const article22 = t("course3_techniques_article22")
    const article23 = t("course3_techniques_article23")
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle15}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle16}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article17}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src13} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle17}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article18}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src13} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle18}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article19}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src14} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle19}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article20}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src15} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle20}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article21}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src16} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle21}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article22}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src17} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle22}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article23}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src18} alt="course-preview" />
            </div>
        </div>
    )
}