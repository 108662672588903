import {useTranslation} from "react-i18next";
import {src3} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');
    const article = t("course2_anal_sex_article2")
    const article1 = t("course2_anal_sex_article4")
    const subtitle = t("course2_anal_sex_subtitle3")
    const subtitle1 = t("course2_anal_sex_subtitle4")
    const list = t("course2_anal_sex_list3", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src3} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
        </div>

    )
}