import {useTranslation} from "react-i18next";
import {kissingTechniques1Src} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("course2_kissing_techniques_title")
    const article = t("course2_kissing_techniques_subtitle1")
    const article1 = t("course2_kissing_techniques_article1")
    const article2 = t("course2_kissing_techniques_subtitle2")
    const article3 = t("course2_kissing_techniques_article2")
    const article4 = t("course2_kissing_techniques_subtitle3")
    const article5 = t("course2_kissing_techniques_article3")
    const article6 = t("course2_kissing_techniques_subtitle4")
    const article7 = t("course2_kissing_techniques_article4")
    const list = t("course2_male_erogenous_zones_list", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article3}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article4}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article5}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article6}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article7}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={kissingTechniques1Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro