import TabsFooter from 'components/Bar'
import { COURSE_ROUTES } from 'constants/routes.constants'
import {Route, Routes, useParams } from 'react-router'
import '../FirstDateRule/styles.scss'
import { AffirmationsRoutes } from './contstants'
import Intro from './Intro'
import Tasks from './Tasks'
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {MAN_OF_MY_DREAMS} from "../../CourseItem/contants";
const Affirmations = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("man_of_my_dreams_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return (
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("man_of_my_dreams") + 9]}
                        objectToSave={{
                            img: MAN_OF_MY_DREAMS[9].img,
                            value: 'man_of_my_dreams',
                            route: '/affirmations/1',
                            index: 9
                        }}
                        uuid={"man_of_my_dreams9"}
                        route={"/courses/man-of-my-dreams"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Tasks />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={AffirmationsRoutes} routeToNavigate={COURSE_ROUTES.AFFIRMATIONS}  />
        </>
    )
}

export default Affirmations