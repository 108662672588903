export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fcode-word%2F8.3.5.1.png?alt=media&token=b2710eeb-05c7-4db8-8d97-a60c616b2cbe",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fcode-word%2F8.3.5.2.png?alt=media&token=5715c001-7352-4e72-b761-1c1fbd3d4108",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fcode-word%2F8.3.5.3.png?alt=media&token=dc36107e-cef4-4655-bc5a-623f6ac2bfac",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fcode-word%2F8.3.5.4.png?alt=media&token=b91901e9-8652-42a8-b1d2-be8e8bc0f68f"
]




