import './IntimateLifeStates.scss';
import {
    drySexImage,
    mansMoanImage, regularForeplayImage, unforgettableSexImage,
    unwillingExperimentImage,
    weekInOralImage
} from "../../../assets/onBoardingImages/goalsImages";
import { TFunction } from 'i18next';

export const IntimateLifeStates =({t}: { t: TFunction })=>{
    const GOAL_STATE_NAME=t("payInfoScreen.goalStateImagesText")

    const intimateStateData=[
        {stateName:t("payInfoScreen.currentStateImagesText"),imgSrc:drySexImage,text:t("payInfoScreen.currentStateImages.firstImageText")},
        {stateName:t("payInfoScreen.goalStateImagesText"),imgSrc:regularForeplayImage,text:t("payInfoScreen.goalStateImages.firstImageText")},
        {stateName:t("payInfoScreen.currentStateImagesText"),imgSrc:unwillingExperimentImage,text:t("payInfoScreen.currentStateImages.secondImageText")},
        {stateName:t("payInfoScreen.goalStateImagesText"),imgSrc:unforgettableSexImage,text:t("payInfoScreen.goalStateImages.secondImageText")},
        {stateName:t("payInfoScreen.currentStateImagesText"),imgSrc:weekInOralImage,text:t("payInfoScreen.currentStateImages.thirdImageText")},
        {stateName:t("payInfoScreen.goalStateImagesText"),imgSrc:mansMoanImage,text:t("payInfoScreen.goalStateImages.thirdImageText")},]

    return (
            <div className={'statesContainer'}>
                {intimateStateData.map(({imgSrc,stateName,text})=>{
                    return <div key={text} className={stateName===GOAL_STATE_NAME?'goalIntimateState':'currentIntimateState'}>
                        <h6>{stateName}</h6>
                        <img loading="lazy" src={imgSrc} alt={''}/>
                        <p>{text}</p>
                    </div>
                })}
            </div>
    )
}
