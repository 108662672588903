import {useTranslation} from "react-i18next";
import {src18, src19, src20, src21, src22, src23, src24, src25, src26, src27, src28, src29, src30, src31, src32, src33} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');
    const subtitle36 = t("course3_tests_title36")
    const subtitle37 = t("course3_tests_title37")
    const subtitle38 = t("course3_tests_title38")
    const subtitle39 = t("course3_tests_title39")
    const subtitle40 = t("course3_tests_title40")
    const subtitle41 = t("course3_tests_title41")
    const subtitle42 = t("course3_tests_title42")
    const subtitle43 = t("course3_tests_title43")
    const subtitle44 = t("course3_tests_title44")
    const subtitle45 = t("course3_tests_title45")
    const subtitle46 = t("course3_tests_title46")
    const subtitle47 = t("course3_tests_title47")
    const subtitle48 = t("course3_tests_title48")
    const subtitle49 = t("course3_tests_title49")
    const subtitle50 = t("course3_tests_title50")
    const subtitle51 = t("course3_tests_title51")
    const subtitle52 = t("course3_tests_title52")
    const subtitle53 = t("course3_tests_title53")
    const subtitle54 = t("course3_tests_title54")
    const subtitle55 = t("course3_tests_title55")
    const subtitle56 = t("course3_tests_title56")
    const subtitle57 = t("course3_tests_title57")
    const subtitle58 = t("course3_tests_title58")
    const subtitle59 = t("course3_tests_title59")
    const subtitle60 = t("course3_tests_title60")
    const subtitle61 = t("course3_tests_title61")
    const subtitle62 = t("course3_tests_title62")
    const subtitle63 = t("course3_tests_title63")
    const subtitle64 = t("course3_tests_title64")
    const subtitle65 = t("course3_tests_title65")
    const subtitle66 = t("course3_tests_title66")
    const subtitle67 = t("course3_tests_title67")
    const subtitle68 = t("course3_tests_title68")
    const subtitle69 = t("course3_tests_title69")
    const article5 = t("course3_tests_article5")
    const article6 = t("course3_tests_article6")
    const article7 = t("course3_tests_article7")
    const article8 = t("course3_tests_article8")
    const article9 = t("course3_tests_article9")
    const list17 = t("course3_tests_list17", { returnObjects: true })
    const list18 = t("course3_tests_list18", { returnObjects: true })
    const list19 = t("course3_tests_list19", { returnObjects: true })
    const list20 = t("course3_tests_list20", { returnObjects: true })
    const list21 = t("course3_tests_list21", { returnObjects: true })
    const list22 = t("course3_tests_list22", { returnObjects: true })
    const list23 = t("course3_tests_list23", { returnObjects: true })
    const list24 = t("course3_tests_list24", { returnObjects: true })
    const list25 = t("course3_tests_list25", { returnObjects: true })
    const list26 = t("course3_tests_list26", { returnObjects: true })
    const list27 = t("course3_tests_list27", { returnObjects: true })
    const list28 = t("course3_tests_list28", { returnObjects: true })
    const list29 = t("course3_tests_list29", { returnObjects: true })
    const list30 = t("course3_tests_list30", { returnObjects: true })
    const list31 = t("course3_tests_list31", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle36}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article5}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle37}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src18} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle38}
            </div>
            <div className="child-container__list">
                {list17.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle39}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src19} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle40}
            </div>
            <div className="child-container__list">
                {list18.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle41}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src20} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle42}
            </div>
            <div className="child-container__list">
                {list19.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle43}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src21} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle44}
            </div>
            <div className="child-container__list">
                {list20.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle45}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src22} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle46}
            </div>
            <div className="child-container__list">
                {list21.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle47}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src23} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle48}
            </div>
            <div className="child-container__list">
                {list22.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle49}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src24} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle50}
            </div>
            <div className="child-container__list">
                {list23.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle51}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src25} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle52}
            </div>
            <div className="child-container__list">
                {list24.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle53}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src26} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle54}
            </div>
            <div className="child-container__list">
                {list25.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle55}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src27} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle56}
            </div>
            <div className="child-container__list">
                {list26.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle57}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src28} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle58}
            </div>
            <div className="child-container__list">
                {list27.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle59}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src29} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle60}
            </div>
            <div className="child-container__list">
                {list28.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle61}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src30} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle62}
            </div>
            <div className="child-container__list">
                {list29.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle63}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src31} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle64}
            </div>
            <div className="child-container__list">
                {list30.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle65}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src32} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle66}
            </div>
            <div className="child-container__list">
                {list31.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article6}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle67}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article7}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src33} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle68}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article8}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle69}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article9}
            </div>
        </div>
    )
}