import {useTranslation} from "react-i18next";
import {masturbation3Src, masturbation4Src} from "./constants";

const Step2 = () => {
    const { t } = useTranslation('app');
    const title = t("course2_masturbation_title3")
    const list = t("course2_masturbation_list3", { returnObjects: true })

    const title1 = t("course2_masturbation_title4")
    const list1 = t("course2_masturbation_list4", { returnObjects: true })
    return (
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={masturbation3Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {title1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={masturbation4Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Step2