export const AffirmationsRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const affirmations1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Faffirmations1.png?alt=media&token=d4087b51-81a0-40a4-9ffa-1146079b19df"
export const affirmations2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Faffirmations2.png?alt=media&token=8856f76c-2596-49d9-b7e0-01578a5d8419"
