export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
    {
        tab: 10,
    },
    {
        tab: 11,
    },
    {
        tab: 12,
    }
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses4.png?alt=media&token=f7878193-b033-4de1-8569-002556a4cc99"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses5.png?alt=media&token=4c66274d-4ecd-4f23-a7ff-33c908189935"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses6.png?alt=media&token=c94894e0-8ea0-4768-abf8-ac53cb5bb9b5"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses7.png?alt=media&token=ad52cc32-3618-4f55-8b1b-5ba6b45273cb"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses8.png?alt=media&token=45da11f1-dd62-4629-9473-516361dc5a52"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses9.png?alt=media&token=bede9db2-ad43-4aed-bc3e-8f1ad454bb13"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses10.png?alt=media&token=81f60fba-9e97-4c6e-a506-4d82a3f850ce"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses11.png?alt=media&token=4135f178-01be-4cf3-b44f-8626448cb2f2"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses12.png?alt=media&token=54d3f193-eb68-4e02-842c-f7e27ea81a59"
export const src10="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses13.png?alt=media&token=44c22168-840c-412c-b581-76f7c37c6594"
export const src11="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses14.png?alt=media&token=90e6f8e4-33d2-4fed-8020-4ca15e100f6d"
export const src12="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses15.png?alt=media&token=6f4f4a09-d42a-4a60-bd7c-907b797a240b"
export const src13="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftop-poses%2Ftop-poses16.png?alt=media&token=1f59c6b7-228c-4cf4-a5af-844e7d8344b5"
