import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course4_sexuality_title4")
    const subtitle1 = t("course4_sexuality_title5")
    const subtitle2 = t("course4_sexuality_title6")
    const subtitle3 = t("course4_sexuality_title7")
    const subtitle4 = t("course4_sexuality_title8")
    const article = t("course4_sexuality_article1")
    const article1 = t("course4_sexuality_article2")
    const list = t("course4_sexuality_list3", { returnObjects: true })
    const list1 = t("course4_sexuality_list4", { returnObjects: true })
    const list2 = t("course4_sexuality_list5", { returnObjects: true })
    const list3 = t("course4_sexuality_list6", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-24 mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle3}
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__list">
                {list3.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
    )
}