export const SignsRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]
export const sign1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fsign1.png?alt=media&token=f47f0967-fda5-4729-91d7-4e5594a5fe97"
export const sign2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fsign2.png?alt=media&token=98bb4ef4-39e5-489f-b785-67819da15191"
export const sign3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fsign3.png?alt=media&token=5fd54733-5a1f-459d-901a-0434357fbe6f"
export const sign4Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fsign4.png?alt=media&token=d34a65dc-d2a6-456d-8e18-9d2059c95ea4"
export const sign5Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fsign5.png?alt=media&token=3ad9b1bd-07f1-4b23-ba73-71509c28b1cd"
export const sign6Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fsign6.png?alt=media&token=c55e3ea7-c1b1-489f-937c-d3ba99592eb5"