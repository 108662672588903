import {useTranslation} from "react-i18next";
import {taboo4Src} from "./constants";

export const Flaws = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_taboo_subtitle3")
    const article2 = t("course2_taboo_article2")
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={taboo4Src} alt="course-preview" />
            </div>
        </div>
    )
}