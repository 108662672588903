export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
]



export const src1 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsecrets-techniques%2Fsecrtes1.png?alt=media&token=417eacca-cc0a-4475-a861-c2f5476422e2"
export const src2 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsecrets-techniques%2Fsecrtes2.png?alt=media&token=4257861b-7ef1-47b6-bff5-94e2f0a955a5"
export const src3 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsecrets-techniques%2Fsecrtes3.png?alt=media&token=6bbff40d-f4b4-492f-965b-846af50f49b7"
export const src4 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsecrets-techniques%2Fsecrtes4.png?alt=media&token=ecc2e984-5a5f-43ba-83c8-364b3f5760a6"
export const src5 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsecrets-techniques%2Fsecrtes5.png?alt=media&token=68a740f8-06ad-426d-a9fb-0db3b06ca1c6"
export const src6 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsecrets-techniques%2Fsecrtes6.png?alt=media&token=d6eb6848-d0d8-4447-b437-1f08f5eb8e66"
