import { Route, Routes} from "react-router-dom";
import {COURSE_ROUTES} from "../../../../constants/routes.constants";
import { useParams} from "react-router";
import { SignsRoutes} from "./constants";
import '../FirstDateRule/styles.scss'
import Signs from "./Signs";
import Keys from "./Keys";
import TabsFooter from "components/Bar";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {getAuth} from "firebase/auth";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {MAN_OF_MY_DREAMS} from "../../CourseItem/contants";


const SignOfAWorthyMan = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("man_of_my_dreams_course")
    const user = getAuth()
    const [listFav, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("man_of_my_dreams") + 3]}
                        objectToSave={{
                            img: MAN_OF_MY_DREAMS[3].img,
                            value: 'man_of_my_dreams',
                            route: '/sign-of-a-worthy-man/1',
                            index: 3
                        }}
                        uuid={"man_of_my_dreams3"}
                        route={"/courses/man-of-my-dreams"}
            />
            <Routes>
                <Route path={'/1'} element={<Signs />} />
                <Route path={'/2'} element={<Keys />} />
            </Routes>
            <TabsFooter tab={tab || ''} routes={SignsRoutes} routeToNavigate={COURSE_ROUTES.SIGN_OF_A_WORTHY_MAN}  />
        </>
    )
}

export default SignOfAWorthyMan