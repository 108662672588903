import {useTranslation} from "react-i18next";
import {modelSrc} from "./constants";

const Look = () => {
    const { t } = useTranslation('app');
    const title = t("man_of_my_dreams_rules_article3")
    const article = t("man_of_my_dreams_rules_article3_content2_title")
    const simpleList = t("man_of_my_dreams_rules_article3_content", { returnObjects: true })
    const list = t("man_of_my_dreams_rules_article3_content2_options", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {simpleList.map((i: string) => <div className="child-container__list mt-24 typography-14">{i}</div>)}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
        </div>
    )
}
export default Look