import {useTranslation} from "react-i18next";
import {kissingTechniques6Src} from "./constants";

export const Triangle = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_kissing_techniques_title6")
    const list = t("course2_kissing_techniques_list1", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={kissingTechniques6Src} alt="course-preview" />
            </div>
        </div>
    )
}