import './Checkbox.scss';
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface ILabelAnchor {
    text: string,
    anchor: PolicyAnchors,
}
interface ICheckboxProps {
    labelText:string;
    labelAnchors?: ILabelAnchor[];
    isChecked:boolean;
    checkboxTooltipText?: string;
    setIsChecked: (isChecked: (isChecked:boolean) => boolean)=>void;
}
export const Checkbox = ({checkboxTooltipText, labelText, labelAnchors, isChecked, setIsChecked}:ICheckboxProps)=>{
    return (
        <div className={'checkboxContainer'}>
            <input type="checkbox" data-tooltip-id="checkboxTooltip"
                   onChange={()=>setIsChecked((isChecked)=>!isChecked)}
                   name="checkbox" checked={isChecked} />
            <ReactTooltip isOpen={!!checkboxTooltipText||!isChecked} className={'tooltip'}
                          place="top" id="checkboxTooltip" variant={'error'}
                          content={checkboxTooltipText}/>
            <label htmlFor="checkbox"
                   onClick={()=>setIsChecked((isChecked)=>!isChecked)}>
                {labelText}
                {labelAnchors?.length && labelAnchors.map(({text,anchor})=>{
                    return <a key={text} href={anchor} target="_blank">{text}</a>
                })}
            </label>
        </div>
    )
}


