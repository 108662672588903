import {useTranslation} from "react-i18next";
import {phrases1Src} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("course2_phrases_for_messaging_title")
    const subtitle = t("course2_phrases_for_messaging_subtitle1")
    const article = t("course2_phrases_for_messaging_article")
    const article3 = t("course2_phrases_for_messaging_article3")
    const article2 = t("course2_phrases_for_messaging_article2")
    const list = t("course2_phrases_for_messaging_list1", { returnObjects: true })
    const list1 = t("course2_phrases_for_messaging_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article3}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={phrases1Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro