import {useTranslation} from "react-i18next";
import { imgUrl } from "./constants";
export const Part2 = () => {
    const { t } = useTranslation('app');
    const title = t("course3_poses_title12")
    return(
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            {new Array(10).fill("").map((_,idx) => {
              return <> <div className="child-container__red-title mt-24 typography-18 typography-bold">
                    {t(`course3_poses_title${13+idx}`)}
                </div>
                <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl['src'+(11+idx)]} alt="course-preview" />
                </div>
                <div className="child-container__article-title mt-16 typography-16">
            {t(`course3_poses_article${11+idx}`)}
                </div>
               </>
            })
            }
        </div>
    )
}