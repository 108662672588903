import {Trans, useTranslation} from "react-i18next";
import {sexualPotentialPicture} from "../../../assets/onBoardingImages";
import {ButtonThemes} from "../../../constants/button.constants";
import './SexualPotentialScreen.scss';
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

export const SexualPotentialScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={sexualPotentialPicture} />
            </ContentContainer>
            <p className={'sexualPotentialQuestion'}>
                <Trans t={t}>{t("elevateSexualPotentialScreen.question")}</Trans>
            </p>
            <NextRouteButton option={t("elevateSexualPotentialScreen.agreeButtonText")}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'sexualPotentialAgreeButton'} />
        </>
    )
}
