export const beforeBeginPicture = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FbeforeWeBegin.png?alt=media&token=b8f1faf0-2622-4fd0-bf63-2f4271abeed0&_gl=1*13tqwoo*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDM5NDIuNjAuMC4w';
export const hundredsOfThousands = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FhundredsOfThousands.png?alt=media&token=713af200-24c8-4f5d-b598-f77bd864c0dc&_gl=1*1lq5uis*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQwODguNjAuMC4w';
export const feelPhysicallyConfident = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FfeelPhysicallyConfident.png?alt=media&token=5ecc1b03-653b-443a-92af-a836d14c46ce&_gl=1*sv4d20*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQwNTguMjQuMC4w';
export const currentRelationship = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FcurrentRelationship.png?alt=media&token=0ca1d59f-a472-4814-9300-e128b2786bd7&_gl=1*1dpw6jj*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDM5NzYuMjYuMC4w';
export const candorCheck = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FcandorCheck.png?alt=media&token=681b3f37-baca-44e7-871b-11c07db0081c&_gl=1*108gnz8*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDM5NTkuNDMuMC4w';
export const reallyClever = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FreallyClever.png?alt=media&token=3bd0ce6f-9d56-4537-8897-19efecbe0b21&_gl=1*n5xu3r*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQxMjIuMjYuMC4w';
export const tryToBeOpen = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FtryToBeOpen.png?alt=media&token=a78e21c2-9387-4786-ad9b-731fb55396f1&_gl=1*1ezaekm*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDM4ODEuNjAuMC4w';
export const educationTimePicture = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FeducationTimeCheck.png?alt=media&token=65f7430d-9d9a-41a0-a945-e0d5e4265d49&_gl=1*1pfyywj*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQwMjIuNjAuMC4w';
export const partnerExplorePicture = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FpartnerExplore.png?alt=media&token=ac081f51-a61c-482b-9cdc-67106a6866eb&_gl=1*1qzftjf*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQxMDQuNDQuMC4w';
export const sexualPotentialPicture = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FsexualPotential.png?alt=media&token=d26fe107-5fa5-40aa-b138-b7557fe4568d&_gl=1*18ecdc8*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQxMzUuMTMuMC4w';
export const beEnticingOne = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FenticingOne.png?alt=media&token=60344cf3-0292-4fa2-845b-b0c775a288f9&_gl=1*1qbrjvo*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQwNDUuMzcuMC4w';
export const goodTestResult = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FgoodTestResult.png?alt=media&token=70a6acba-21ff-40fc-a6b9-f1e639919d4c&_gl=1*wuu9fw*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQwNzMuOS4wLjA.';
export const averageTestResult = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FaverageTestResult.png?alt=media&token=281a3c5d-3086-4096-b073-9f2955c23f26&_gl=1*7oj8my*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDM5MTkuMjIuMC4w';
export const unsatisfactoryTestResult = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FunsatisfactoryTestResult.png?alt=media&token=cedc7a04-82c3-4dd3-82f3-0fcab017eaac&_gl=1*hqu82g*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5Njk0MzY0MS4xNy4xLjE2OTY5NDQxNTIuNjAuMC4w';
export const newLifeJourney='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FnewLifeJourney.png?alt=media&token=bfcc1f1b-684f-4eb8-a5a9-dc43fa8fdd13&_gl=1*xy2pyu*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzExMzIyMy4yMS4xLjE2OTcxMTMzMjguMTguMC4w'
export const signUpOffer='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FsignUpOffer.png?alt=media&token=0f6164b0-e420-4110-8cc0-6a7ee13e5fef&_gl=1*161kqt4*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzIxNDQ3OC4yMy4xLjE2OTcyMTQ1MTYuMjIuMC4w'
export const timerTitleImage='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FtimerTitleImage.png?alt=media&token=72e8fd75-9e80-40a7-a0f1-e8bde29d0a9e&_gl=1*aiscq7*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDEzNTguNDguMC4w'
export const yourReadinessImage='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FyourReadiness.png?alt=media&token=251a829e-3b30-41a2-bab7-5807a148f516&_gl=1*3973nm*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDE3NDYuNTEuMC4w'
export const learnHowEasyLogo='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FlearnHowEasyLogo.png?alt=media&token=67631e04-3328-4223-82a5-da7cbe19f056&_gl=1*gdtngr*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDIzMjkuMzQuMC4w'
export const moneyBackGuarantee='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FmoneyBackGuarantee.png?alt=media&token=6f025e3f-435a-4519-b1cd-0fe9311e19e9&_gl=1*twfa5*_ga*MTAzNTI5ODM2NS4xNjU0MjU2NTg4*_ga_CW55HF8NVT*MTY5NzMwMTM0Ni4yNi4xLjE2OTczMDIzODkuNTMuMC4w'
export const moneyBackGuaranteeLogo='https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/onboarding%2FmoneyBackGuaranteeLogo.png?alt=media&token=0566c9f7-aac9-41a7-819f-b2425fd47934&_gl=1*1pid890*_ga*MTI5NjMyMjQwLjE2OTY2OTg2NTM.*_ga_CW55HF8NVT*MTY5NzkwODgyNS44LjEuMTY5NzkwOTA0MC42MC4wLjA.'