export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fbusinesswoman%2F8.4.4.1.png?alt=media&token=d3540427-c82f-46ea-90e0-4032057c0b2c",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fbusinesswoman%2F8.4.4.2.png?alt=media&token=11e16e88-8874-41ff-b0f3-e4e31a32a347",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fbusinesswoman%2F8.4.4.3.png?alt=media&token=2bdfe610-0c39-4a73-a1f7-a8eeea139299",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fbusinesswoman%2F8.4.4.4.png?alt=media&token=d50ba3f1-186f-47b7-9420-be72e388c600"
]





