import './Carousel.scss';
import React from "react";
import 'react-alice-carousel/lib/alice-carousel.css';
import {ProgressDivision} from "../ProgressBar/ProgressDivision/ProgressDivision";
import {ProgressBarState} from "../../constants/onBoardingConstants/progressBar.constants";
import {leftArrow, rightArrow} from "../../assets/onBoardingImages/carouselArrows";
import AliceCarousel, {DotsItem} from "react-alice-carousel";

const CustomDot=({active,onClick}:{onClick?:()=>void, active?:boolean})=>{
    return <ProgressDivision onClick={onClick} divisionStatus={
        active ? ProgressBarState.FILLED : ProgressBarState.UNFILLED
    }/>
}

const CustomLeftArrow=({onClick}:{onClick?:()=>void})=> {
    return <img loading="lazy" onClick={onClick} className={'carouselLeftArrow react-multiple-carousel__arrow--left'} src={leftArrow} alt={''} />
}

const CustomRightArrow=({onClick}:{onClick?:()=>void})=> {
    return <img loading="lazy" src={rightArrow} alt={''} onClick={onClick}
                className={'carouselRightArrow react-multiple-carousel__arrow--right'}/>
}

export const CarouselComponent =({itemPadding,children}:{itemPadding:number,children:React.ReactElement[]})=>{
    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    };
    return (
        <div className={'carouselWrapper'}>
            <AliceCarousel
                renderDotsItem={(e: DotsItem)=><CustomDot active={e.isActive}/>}
                renderPrevButton={()=><CustomLeftArrow/>}
                renderNextButton={()=><CustomRightArrow/>}
                mouseTracking={true}
                infinite={true}
                items={children}
                paddingLeft={itemPadding}
                paddingRight={itemPadding}
                responsive={responsive}
            />
        </div>
    )
}