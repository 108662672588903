import './AgeCheckScreen.scss';
import {useTranslation} from "react-i18next";
import {AgeCheck} from "../../../components/onboarding/AgeCheck/AgeCheck";
import {Footer} from "../../../components/lego/Footer/Footer";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";

export const AgeCheckScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
    <>
        <AgeCheck nextRoute={nextRoute}/>
        <Footer text={<span className="">
                    {t("privatePolicyText")}
            <a href={PolicyAnchors.TERMS}>{t("createAccountScreen.policyAnchors.terms")}</a>      &nbsp;
            <a href={PolicyAnchors.PRIVACY}>{t("createAccountScreen.policyAnchors.privacy")}</a>{' '}      &nbsp;
            <a href={PolicyAnchors.SUBSCRIPTION}>{t("createAccountScreen.policyAnchors.subscription")}</a>      &nbsp;
                </span>}/>
    </>
    )
}
