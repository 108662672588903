import {
    GAMES_ROUTE,
    PREMIUM_ROUTE,
    SCENARIOS_ROUTE,
    COURSES as COURSE_ROUTE
} from "../../../constants/routes.constants";
import {SCENARIOS_NAMES_ROUTES} from "../Scenarios/constants";
import {PREMIUM_NAMES_ROUTES} from "../PremiumItem/contants";
import {GAMES_NAMES_ROUTES} from "../Games/constants";
import {COURSES_NAMES_ROUTES} from "../Courses/constants";

export const COURSES = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/cousre1.png?alt=media&token=dd92ede3-21cf-494f-8fae-f0a2a04bf8fc&_gl=1*1v2zigt*_ga*Mzc0MDQ0ODMwLjE2OTY4NzExMDk.*_ga_CW55HF8NVT*MTY5Njg4MzA4MC4yLjEuMTY5Njg4NDY0MC41Ni4wLjA.',
        id: 'course_1',
        url: COURSE_ROUTE + '/' + COURSES_NAMES_ROUTES[0]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course2.png?alt=media&token=2d7fd970-6e61-4a25-b30c-dc754eea4a7d&_gl=1*4unelc*_ga*Mzc0MDQ0ODMwLjE2OTY4NzExMDk.*_ga_CW55HF8NVT*MTY5Njg4MzA4MC4yLjEuMTY5Njg4NTE3MS4zOC4wLjA.',
        id: 'course_2',
        url: COURSE_ROUTE + '/' + COURSES_NAMES_ROUTES[1]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3.png?alt=media&token=c3aa378d-fc61-4d85-80c7-e3e6c12fdbbb&_gl=1*17gw3eg*_ga*Mzc0MDQ0ODMwLjE2OTY4NzExMDk.*_ga_CW55HF8NVT*MTY5Njg4MzA4MC4yLjEuMTY5Njg4NTE4MC4yOS4wLjA.',
        id: 'course_3',
        url: COURSE_ROUTE + '/' + COURSES_NAMES_ROUTES[2]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course4.png?alt=media&token=d1113df5-be91-4455-b8e7-7ef3c882d187&_gl=1*nxttry*_ga*Mzc0MDQ0ODMwLjE2OTY4NzExMDk.*_ga_CW55HF8NVT*MTY5Njg4MzA4MC4yLjEuMTY5Njg4NTE5MC4xOS4wLjA.',
        id: 'course_4',
        url: COURSE_ROUTE + '/' + COURSES_NAMES_ROUTES[3]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/9.1%2069%20Arousing%20sms%20(text%20and%20images).png?alt=media&token=59d6ec40-9552-4e6a-b0c3-c938a96bbe1f',
        id: 'course_5',
        url: COURSES_NAMES_ROUTES[4]
    }
]
export const GAMES = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgame1.png?alt=media&token=9b89f066-c84f-46cb-bf7a-294a88ce2afe&_gl=1*txs1ar*_ga*MTU0MjE4MTgxNi4xNjk2OTcxODQw*_ga_CW55HF8NVT*MTY5Njk3MTg0MC4xLjEuMTY5Njk3MTk5Ni4zNi4wLjA.',
        id: 'games_1',
        url: GAMES_ROUTE + '/' + GAMES_NAMES_ROUTES[0]
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgame2.png?alt=media&token=41c32110-012f-466c-ab6b-32a9e197bf7a&_gl=1*ogqr46*_ga*MTU0MjE4MTgxNi4xNjk2OTcxODQw*_ga_CW55HF8NVT*MTY5Njk3MTg0MC4xLjEuMTY5Njk3MjAyNC44LjAuMA..',
        id: 'games_2',
        url: GAMES_ROUTE + '/' + GAMES_NAMES_ROUTES[1]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgame3.png?alt=media&token=d07cc310-3a58-404e-bf0a-d5ecdeed1a14&_gl=1*1y88r50*_ga*MTU0MjE4MTgxNi4xNjk2OTcxODQw*_ga_CW55HF8NVT*MTY5Njk3MTg0MC4xLjEuMTY5Njk3MjA0OS42MC4wLjA.',
        id: 'games_3',
        url: GAMES_ROUTE + '/' + GAMES_NAMES_ROUTES[2]
    }
]

export const SCENARIOS = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fscenario1.png?alt=media&token=2e1d1466-0e65-4650-81bb-ea174f4fd60f&_gl=1*1ybj0r4*_ga*MTU0MjE4MTgxNi4xNjk2OTcxODQw*_ga_CW55HF8NVT*MTY5Njk3MTg0MC4xLjEuMTY5Njk3MjIzNC42MC4wLjA.',
        id: 'scenarios_1',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[0]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fscenario2.png?alt=media&token=7173bd3d-8278-4c93-817e-289455e30c1b&_gl=1*1t4ce8*_ga*MTU0MjE4MTgxNi4xNjk2OTcxODQw*_ga_CW55HF8NVT*MTY5Njk3MTg0MC4xLjEuMTY5Njk3MjI3MC4yNC4wLjA.',
        id: 'scenarios_2',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[1]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fscenario3.png?alt=media&token=115d2650-8a9a-4b4a-82be-19072f57841f&_gl=1*18ld9tj*_ga*MTU0MjE4MTgxNi4xNjk2OTcxODQw*_ga_CW55HF8NVT*MTY5Njk3MTg0MC4xLjEuMTY5Njk3MjI3Ny4xNy4wLjA.',
        id: 'scenarios_3',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[2]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fscenario4.png?alt=media&token=ad958f47-2df2-4574-8752-471c3a3a9853&_gl=1*4jx3d3*_ga*MTU0MjE4MTgxNi4xNjk2OTcxODQw*_ga_CW55HF8NVT*MTY5Njk3MTg0MC4xLjEuMTY5Njk3MjI4Ni44LjAuMA..',
        id: 'scenarios_4',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[3]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fscenario5.png?alt=media&token=b0e90f4e-cfad-44a0-9f9f-d7e9b0155895&_gl=1*114g5w*_ga*MTU0MjE4MTgxNi4xNjk2OTcxODQw*_ga_CW55HF8NVT*MTY5Njk3MTg0MC4xLjEuMTY5Njk3MjI5NC42MC4wLjA.',
        id: 'scenarios_5',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[4]
    }
]


export const PREMIUM = [
    {
        img: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2F1.%20BDSM%20Game.png?alt=media&token=3dccfa76-b20e-4df0-8a04-712724750826",
        id: 'premium_1',
        url: PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[0]
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2F2.%20%E2%80%9CTruth%20or%20Dare%E2%80%9D%20Advanced%20.png?alt=media&token=992b8058-5b94-433f-899c-e8e539ef5925",
        id: 'premium_2',
        url: PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[1]
    },
    {
        img:"https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2F3.1%20Tasks%20(text%20and%20images).png?alt=media&token=e062606d-ea59-46c5-ab8e-88651818cd5b",
        id: 'premium_3',
        url: PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[2]
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/premium%2Fmain%2F4.%20365%20Days%20of%20Sex%20Calendar.png?alt=media&token=048a1d4d-3de0-4745-9162-cd963a0c7fa9",
        id: 'premium_4',
        url: PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[3]
    }
]