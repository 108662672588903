export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-scenarios%2Fscenarios1.png?alt=media&token=b76fa37c-41bd-491f-a01f-f81b2b443a2a"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-scenarios%2Fscenarios2.png?alt=media&token=1b49b24c-6d81-43cc-802b-eec499fd6d4e"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-scenarios%2Fscenarios.png?alt=media&token=66de8ab4-0db6-4aa3-997a-d725b65da655"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-scenarios%2Fscenarios4.png?alt=media&token=65676793-470e-4cca-913e-16c08f5f8a8e"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-scenarios%2Fscenarios5.png?alt=media&token=9585bb4f-f79b-486b-bd71-d1a3358f5c02"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-scenarios%2Fscenarios6.png?alt=media&token=dbff904c-da73-433f-9586-7b683438beea"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fsex-scenarios%2Fscenarios7.png?alt=media&token=cd1cbc92-6129-4a2d-bc0f-6548f951153f"

