export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest1.png?alt=media&token=e14371f7-571b-44d3-a740-7f3b318e5798"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest2.png?alt=media&token=79c98d85-6075-4dc9-b8e5-b0b6bf2d817c"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest3.png?alt=media&token=66e505e6-2471-4d8a-bf4e-b21812f02dda"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest4.png?alt=media&token=c1673336-3339-4d02-9d21-875ed039bb3d"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest5.png?alt=media&token=8e48f571-3f7b-47ed-b16d-ade78779a75c"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest6.png?alt=media&token=b2172485-a4de-4f42-ba40-b4459ae83a34"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest7.png?alt=media&token=43f1ac74-13e5-4aa5-abd5-62741c1e56c1"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest8.png?alt=media&token=86e65d8d-f706-4826-998f-b66f6865ffc9"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest9.png?alt=media&token=54ff0118-f0df-4350-8723-785327ce3aa8"
export const src10="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest10.png?alt=media&token=ddcddc1c-b9fa-489a-a2d2-b5acf6c943c2"
export const src11="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest11.png?alt=media&token=81c4c52a-5645-413f-b023-b65a155a3188"
export const src12="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest12.png?alt=media&token=cb46e281-a833-4d96-bc4d-de4b9beac31b"
export const src13="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest13.png?alt=media&token=06b51437-62ed-43a0-939f-e2185693bdf5"
export const src14="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest14.png?alt=media&token=841f5c48-9de8-4f42-88cb-a3e9582533bf"
export const src15="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest15.png?alt=media&token=e6ff7d2f-b44b-4135-8123-9e30361c229b"
export const src16="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest16.png?alt=media&token=08e7913f-73ca-4903-b999-a0cdd7eb0f61"
export const src17="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest17.png?alt=media&token=9835bf8a-fccc-4c8e-ba43-ae0ec0eb0aa3"
export const src18="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest18.png?alt=media&token=59e52a34-dd78-4b1e-a1f5-4287134fbc82"
export const src19="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest19.png?alt=media&token=281e549a-e4fb-4b1b-b2c3-2ae868848620"
export const src20="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest20.png?alt=media&token=91dba339-b75c-441e-9aaf-1719cc27e24f"
export const src21="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest21.png?alt=media&token=2f635467-6e20-4d49-b6d0-aa5b8a2f2209"
export const src22="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest22.png?alt=media&token=e304b9e0-5a6c-4d78-806c-acea37dca33a"
export const src23="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest23.png?alt=media&token=97a4255a-6407-42bd-ab12-2b37a87be171"
export const src24="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest24.png?alt=media&token=8323e369-05d3-47d1-a1a4-149cfcbd807d"
export const src25="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest25.png?alt=media&token=1e0e89fa-9ca2-4718-9db8-52e9e5967574"
export const src26="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest26.png?alt=media&token=c60f3797-87dd-4478-9469-c4ddfcbe294c"
export const src27="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest27.png?alt=media&token=e1ea60e9-af0e-4b6d-9080-a8319349c4df"
export const src28="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest28.png?alt=media&token=4233fb75-be38-4fba-bd1f-4c2fc83f919f"
export const src29="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest29.png?alt=media&token=f04a4166-605c-487c-9925-d6c2e0639be9"
export const src30="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest30.png?alt=media&token=43c65d45-a241-481b-81ac-0991dcb1a61d"
export const src31="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest31.png?alt=media&token=587a34da-c57c-4fa8-8d64-1b66411cc4f9"
export const src32="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest32.png?alt=media&token=ed0fc0ed-4c5c-4894-83e0-54c559514b3d"
export const src33="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course3%2Ftests%2Ftest33.png?alt=media&token=a2294123-b208-4079-928c-715ad2d681f7"


