import {useTranslation} from "react-i18next";
import { storiesSrc } from "./constants";
import '../FirstDateRule/styles.scss'
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {getAuth} from "firebase/auth";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {MAN_OF_MY_DREAMS} from "../../CourseItem/contants";

export const Stories = () => {
    const { t } = useTranslation('app');
    const title = t("stories_title")
    const subTitle = t("stories_subtitle")
    const article = t("stories_article")
    const list = t("stories_content", { returnObjects: true })
    const end = t("stories_end")
    const titleMain = t("man_of_my_dreams_course")
    const user = getAuth()
    const [listFav, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("man_of_my_dreams") + 2]}
                        objectToSave={{
                            img: MAN_OF_MY_DREAMS[2].img,
                            value: 'man_of_my_dreams',
                            route: '/stories',
                            index: 2
                        }}
                        uuid={"man_of_my_dreams2"}
                        route={"/courses/man-of-my-dreams"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: {tab: string}) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-20 typography-bold">
                {end}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={storiesSrc} alt="course-preview" />
            </div>
        </div>
            </>
    )
}

export default Stories