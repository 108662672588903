import {signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo, signOut} from "firebase/auth";
import {auth, googleProvider} from "../../../firebase/firebase.config";
import {writeUserDataToDatabase} from "../userData.utils";

interface ILogInWithGoogleParams {
    successfulAuthRoute:()=>void;
    isLogInScreen?: boolean;
    newAccountRoute?:()=>void;
    confirmEmailRoute?:()=>void;
}
export const loginWithGoogle=({isLogInScreen=false,successfulAuthRoute,newAccountRoute}:ILogInWithGoogleParams)=>{
    signInWithPopup(auth, googleProvider)
        .then(async (result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential!.accessToken;
            if(token) localStorage.setItem("accessToken", token)
            const user = result.user;
           if(isLogInScreen && getAdditionalUserInfo(result)?.isNewUser) {
               await user.delete()
               newAccountRoute && newAccountRoute()
           } else {
               await writeUserDataToDatabase(user.uid, user.displayName||user.email!, user.email!, "")
               !isLogInScreen && signOut(auth)
               successfulAuthRoute()
           }
        }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
    });
}