import {useTranslation} from "react-i18next";
import {behavior5Src} from "./constants";
const Phrases = () => {
    const { t } = useTranslation('app');
    const subTitle = t("behavior_subtitle")
    const list = t("behavior_content", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={behavior5Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Phrases