import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {DAYS_MONTHLY} from "../../contants";

export const Monthly = () => {
    const { t } = useTranslation('app');
    const title = t("premium3_challenges_title1")
    const subtitle = t("premium3_challenges_title2")
    const subtitle1 = t("premium3_challenges_title3")
    const subtitle4 = t("premium3_challenges_title4")
    const subtitle5 = t("premium3_challenges_title5")
    const subtitle6 = t("premium3_challenges_title6")
    const subtitle7 = t("premium3_challenges_title7")
    const subtitle8 = t("premium3_challenges_title8")
    const subtitle9 = t("premium3_challenges_title9")
    const subtitle10 = t("premium3_challenges_title10")
    const subtitle11 = t("premium3_challenges_title11")
    const subtitle12 = t("premium3_challenges_title12")
    const subtitle13 = t("premium3_challenges_title13")
    const subtitle14 = t("premium3_challenges_title14")
    const subtitle15 = t("premium3_challenges_title15")
    const subtitle16 = t("premium3_challenges_title16")
    const subtitle17 = t("premium3_challenges_title17")
    const subtitle18 = t("premium3_challenges_title18")
    const subtitle19 = t("premium3_challenges_title19")
    const subtitle20 = t("premium3_challenges_title20")
    const subtitle21 = t("premium3_challenges_title21")
    const subtitle22 = t("premium3_challenges_title22")
    const subtitle23 = t("premium3_challenges_title23")
    const subtitle24 = t("premium3_challenges_title24")
    const subtitle25 = t("premium3_challenges_title25")
    const subtitle26 = t("premium3_challenges_title26")
    const subtitle27 = t("premium3_challenges_title27")
    const subtitle28 = t("premium3_challenges_title28")
    const subtitle29 = t("premium3_challenges_title29")
    const subtitle30 = t("premium3_challenges_title30")
    const subtitle31 = t("premium3_challenges_title31")
    const subtitle32 = t("premium3_challenges_title32")
    const subtitle33 = t("premium3_challenges_title33")
    const subtitle34 = t("premium3_challenges_title34")
    const subtitle35 = t("premium3_challenges_title35")
    const subtitle36 = t("premium3_challenges_title36")
    const subtitle37 = t("premium3_challenges_title37")
    const subtitle38 = t("premium3_challenges_title38")
    const subtitle39 = t("premium3_challenges_title39")
    const subtitle40 = t("premium3_challenges_title40")
    const subtitle41 = t("premium3_challenges_title41")
    const subtitle42 = t("premium3_challenges_title42")
    const subtitle43 = t("premium3_challenges_title43")
    const subtitle44 = t("premium3_challenges_title44")
    const subtitle45 = t("premium3_challenges_title45")
    const subtitle46 = t("premium3_challenges_title46")
    const subtitle47 = t("premium3_challenges_title47")
    const subtitle48 = t("premium3_challenges_title48")
    const subtitle49 = t("premium3_challenges_title49")
    const subtitle50 = t("premium3_challenges_title50")
    const subtitle51 = t("premium3_challenges_title51")
    const subtitle52 = t("premium3_challenges_title52")
    const article1 = t("premium3_challenges_article1")
    const article2 = t("premium3_challenges_article2")
    const article3 = t("premium3_challenges_article3")
    const article4 = t("premium3_challenges_article4")
    const article5 = t("premium3_challenges_article5")
    const article6 = t("premium3_challenges_article6")
    const article7 = t("premium3_challenges_article7")
    const article8 = t("premium3_challenges_article8")
    const article9 = t("premium3_challenges_article9")
    const article10 = t("premium3_challenges_article10")
    const article11 = t("premium3_challenges_article11")
    const article12 = t("premium3_challenges_article12")
    const article13 = t("premium3_challenges_article13")
    const article14 = t("premium3_challenges_article14")
    const list = t("premium3_challenges_list1", { returnObjects: true })
    const list1 = t("premium3_challenges_list2", { returnObjects: true })
    const list3 = t("premium3_challenges_list3", { returnObjects: true })
    const list4 = t("premium3_challenges_list4", { returnObjects: true })
    const list5 = t("premium3_challenges_list5", { returnObjects: true })
    const list6 = t("premium3_challenges_list6", { returnObjects: true })
    const list7 = t("premium3_challenges_list7", { returnObjects: true })
    const list8 = t("premium3_challenges_list8", { returnObjects: true })
    const list9 = t("premium3_challenges_list9", { returnObjects: true })
    const list10 = t("premium3_challenges_list10", { returnObjects: true })
    const list11 = t("premium3_challenges_list11", { returnObjects: true })
    const list12 = t("premium3_challenges_list12", { returnObjects: true })
    const list13 = t("premium3_challenges_list13", { returnObjects: true })
    const list14 = t("premium3_challenges_list14", { returnObjects: true })
    const list15 = t("premium3_challenges_list15", { returnObjects: true })
    const list16 = t("premium3_challenges_list16", { returnObjects: true })
    const list17 = t("premium3_challenges_list17", { returnObjects: true })
    const list18 = t("premium3_challenges_list18", { returnObjects: true })
    const list19 = t("premium3_challenges_list19", { returnObjects: true })
    const list20 = t("premium3_challenges_list20", { returnObjects: true })
    const list21 = t("premium3_challenges_list21", { returnObjects: true })
    const list22 = t("premium3_challenges_list22", { returnObjects: true })
    const list23 = t("premium3_challenges_list23", { returnObjects: true })
    const list24 = t("premium3_challenges_list24", { returnObjects: true })
    const list25 = t("premium3_challenges_list25", { returnObjects: true })
    const list26 = t("premium3_challenges_list26", { returnObjects: true })
    const list27 = t("premium3_challenges_list27", { returnObjects: true })
    const list28= t("premium3_challenges_list28", { returnObjects: true })
    const list29 = t("premium3_challenges_list29", { returnObjects: true })
    const list30 = t("premium3_challenges_list30", { returnObjects: true })
    const list31 = t("premium3_challenges_list31", { returnObjects: true })
    const list32 = t("premium3_challenges_list32", { returnObjects: true })
    const list33 = t("premium3_challenges_list33", { returnObjects: true })
    const list34 = t("premium3_challenges_list34", { returnObjects: true })
    const list35 = t("premium3_challenges_list35", { returnObjects: true })
    const list36 = t("premium3_challenges_list36", { returnObjects: true })
    const list37 = t("premium3_challenges_list37", { returnObjects: true })
    const list38 = t("premium3_challenges_list38", { returnObjects: true })
    const list39 = t("premium3_challenges_list39", { returnObjects: true })
    const list40 = t("premium3_challenges_list40", { returnObjects: true })
    const list41 = t("premium3_challenges_list41", { returnObjects: true })
    const list42 = t("premium3_challenges_list42", { returnObjects: true })
    const list43 = t("premium3_challenges_list43", { returnObjects: true })
    const list44 = t("premium3_challenges_list44", { returnObjects: true })
    const list45 = t("premium3_challenges_list45", { returnObjects: true })
    const list46 = t("premium3_challenges_list46", { returnObjects: true })
    const list47 = t("premium3_challenges_list47", { returnObjects: true })
    const titleHeader = t("days_monthly_premium")?.split(' ').slice(0, 3).join(' ').replace("\"", "")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleHeader}
                        isActive={!!listFav[("days_monthly") + 0]}
                        objectToSave={{
                            img: DAYS_MONTHLY[0].img,
                            value: 'days_monthly',
                            route: '/monthly-challenges',
                            index: 0
                        }}
                        uuid={"days_monthly0"}
                        route={"/premium/days-monthly"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            {list.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            {list1.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            {list3.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__list">
                <ul>
                    {list4.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            {list5.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
            {subtitle5}
            </div>
            {list6.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle6}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            {list7.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle7}
            </div>
            <div className="child-container__list">
                <ul>
                    {list8.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle8}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            {list9.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle9}
            </div>
            <div className="child-container__list">
                <ul>
                    {list10.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle10}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[3]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article3}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle11}
            </div>
            {list11.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle12}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[4]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article4}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle13}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle14}
            </div>
            {list12.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle15}
            </div>
            <div className="child-container__list">
                <ul>
                    {list13.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article5}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle16}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[5]} alt="course-preview" />
            </div>
            {list14.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle17}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[6]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article6}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle18}
            </div>
            <div className="child-container__list">
                <ul>
                    {list15.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            {list16.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle19}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[7]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article7}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle20}
            </div>
            {list17.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle21}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[8]} alt="course-preview" />
            </div>
            {list18.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle22}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[9]} alt="course-preview" />
            </div>
            {list19.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle23}
            </div>
            {list20.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle24}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[10]} alt="course-preview" />
            </div>
            {list21.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle25}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[11]} alt="course-preview" />
            </div>
            {list22.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle26}
            </div>
            {list23.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle27}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[12]} alt="course-preview" />
            </div>
            {list24.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle28}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[13]} alt="course-preview" />
            </div>
            {list25.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle29}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[14]} alt="course-preview" />
            </div>
            {list26.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle30}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[15]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article8}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle31}
            </div>
            {list27.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle32}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[16]} alt="course-preview" />
            </div>
            {list28.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle33}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[17]} alt="course-preview" />
            </div>
            {list29.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle34}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[18]} alt="course-preview" />
            </div>
            {list30.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__list">
                <ul>
                    {list31.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            {list32.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle35}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[19]} alt="course-preview" />
            </div>
            {list33.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle36}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article9}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle37}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[20]} alt="course-preview" />
            </div>
            {list34.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle38}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[21]} alt="course-preview" />
            </div>
            {list35.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            {list36.map((i: { title: string, description: string }) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-18 typography-bold">
                        {i.title}
                    </div>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i.description}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle39}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[22]} alt="course-preview" />
            </div>
            {list37.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle40}
            </div>
            {list38.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle41}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[23]} alt="course-preview" />
            </div>
            {list39.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle42}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[24]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article10}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle43}
            </div>
            <div className="child-container__list">
                <ul>
                    {list40.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article11}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle44}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[25]} alt="course-preview" />
            </div>
            {list41.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle45}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[26]} alt="course-preview" />
            </div>
            {list42.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle46}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[27]} alt="course-preview" />
            </div>
            {list43.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle47}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[28]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article12}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle48}
            </div>
            <div className="child-container__list">
                <ul>
                    {list44.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article13}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle49}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[29]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article14}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle50}
            </div>
            <div className="child-container__list">
                <ul>
                    {list45.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle51}
            </div>
            {list46.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle52}
            </div>
            {list47.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
        </div>
            </>
            )
}