export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fbeach%2F8.5.3.1.png?alt=media&token=c3590c03-a55e-464d-a2ab-73f43e7aca27",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fbeach%2F8.5.3.2.png?alt=media&token=e6218b8b-bc6d-46bc-bbba-4959c8fe9ee6",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fbeach%2F8.5.3.3.png?alt=media&token=c0c5411d-0a08-494f-bde9-67cf348b13f6",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fbeach%2F8.5.3.4.png?alt=media&token=e7989352-96b2-4d68-9edc-07c217076896"
]





