import './ContentContainer.scss';
import React from "react";

export const ContentContainer =({children}:{children:React.ReactNode})=>{
    return (
            <div className={'contentContainer'}>
                {children}
            </div>
    )
}
