export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
]



export const src1 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow1.png?alt=media&token=7499fd9e-c796-49cd-b897-ab765f11499e"
export const src2 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow2.png?alt=media&token=79d39470-d3ed-46db-816f-b9546f55228f"
export const src3 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow3.png?alt=media&token=d21cbc33-a6e0-40f2-a5a7-b547f24f8ddd"
export const src4 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow4.png?alt=media&token=a79cd9e1-d61d-4612-9a0f-074b2d9e7852"
export const src5 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow5.png?alt=media&token=3d525c70-2c2a-4118-86be-0e8e083d8e36"
export const src6 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow6.png?alt=media&token=0d463a8a-94d0-4e3d-83e7-d01ff22bbf30"
export const src7 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow7.png?alt=media&token=902ae30f-1ecb-49a4-b958-38e18fe23d69"
export const src8 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow8.png?alt=media&token=0a3b3aa2-da65-4a27-af8f-629fc01ba15a"
export const src9 = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fblow-job%2Fblow9.png?alt=media&token=121f84d5-464f-45fb-939b-f2e336741429"


