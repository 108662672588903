import '../../FirstDateRule/styles.scss'
import {useTranslation} from "react-i18next";
import {attitudeSrc} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {PATH_TO_PLEASURE} from "../../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
const AttitudeTowardsSex = () => {
    const { t } = useTranslation('app');
    const title = t("course2_attitude_title")
    const list = t("course2_attitude_list", { returnObjects: true })
    const titleMain = t("path_to_pleasure_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("path_to_pleasure") + 1]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[1].img,
                            value: 'path_to_pleasure',
                            route: '/attitude-towards-sex',
                            index: 1
                        }}
                        uuid={"path_to_pleasure1"}
                        route={"/courses/path_to_pleasure"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={attitudeSrc} alt="course-preview" />
            </div>
        </div>
            </>
    )
}

export default AttitudeTowardsSex