import {useTranslation} from "react-i18next";
import { zones4Src} from "./constants";

const TurnOn2 = () => {
    const { t } = useTranslation('app');
    const list = t("course2_erogenous_list4", { returnObjects: true })
    const article = t("course2_erogenous_article2")
    const article1 = t("course2_erogenous_article3")
    const article2 = t("course2_erogenous_article4")
    const note = t("course2_erogenous_note")
    const subText1 = t("course2_erogenous_title3")
    const subText2 = t("course2_erogenous_title4")
    return(
        <div className="child-container">
            <div className="child-container__article-title mt-16 typography-14">
                {article}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__article-title mt-16 typography-14">
                {article1}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText1}
            </div>
            <div className="child-container__article-title mt-16 typography-14">
                {article2}
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subText2}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={zones4Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default TurnOn2