export const MasturbationRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
    {
        tab: 10,
    },
    {
        tab: 11,
    }
]

export const masturbation1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation1.png?alt=media&token=24003a29-7b86-47bc-9504-ea303747f807"
export const masturbation2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation2.png?alt=media&token=6042fb18-24c9-4886-bf01-362d09ba7dd3"
export const masturbation3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation3.png?alt=media&token=a0fa2685-0ccf-4e67-b766-93b84b6028be"
export const masturbation4Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation4.png?alt=media&token=0b880791-8dcd-431c-b59e-404c97f1b796"
export const masturbation5Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation5.png?alt=media&token=da506135-515f-4e04-8dc7-9250f3454d22"
export const masturbation6Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation6.png?alt=media&token=050ac7c6-d9df-43fe-977c-75d3ffaf6d83"
export const masturbation7Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation7.png?alt=media&token=d36cddad-071d-47ef-92ad-5ba04c84288b"
export const masturbation8Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation8.png?alt=media&token=9c17fbe4-e3f6-41e7-a19c-94c8b1f01c7d"
export const masturbation9Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation9.png?alt=media&token=ace4a935-cced-41ed-b02e-104e5362d55b"
export const masturbation10Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation10.png?alt=media&token=6e2f658a-5d3a-4597-85b7-ac92bd4312e3"
export const masturbation11Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation11.png?alt=media&token=a31df97a-8cb7-4b5e-8782-173b180557a4"
export const masturbation12Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation12.png?alt=media&token=e17a424f-73a0-40c9-bff5-ce8ef651f0b6"
export const masturbation13Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation13.png?alt=media&token=318170ed-ea01-4173-a02a-ea9b9c664d08"
export const masturbation14Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation14.png?alt=media&token=2461cbed-731a-4926-8dc4-04a1dd276488"
export const masturbation15Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation15.png?alt=media&token=4ce8e5e3-90f7-48a8-a800-9322ed870155"
export const masturbation16Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation16.png?alt=media&token=c67313e5-0d03-4ddf-938c-cef4b795e317"
export const masturbation17Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmasturbation%2Fmasturbation17.png?alt=media&token=bb59baa3-f203-425e-866a-0993bb818496"