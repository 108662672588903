import {useTranslation} from "react-i18next";
import {ways3Src, ways4Src, ways5Src} from "./constants";

const ThirdPartOfWays = () => {
    const { t } = useTranslation('app');
    const subTitle = t("ways_3_title")
    const article = t("ways_3_article")
    const subTitle1 = t("ways_4_title")
    const article1 = t("ways_4_article")
    const subTitle2 = t("ways_5_title")
    const list = t("ways_5_article", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways3Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle1}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways4Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle2}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways5Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default ThirdPartOfWays