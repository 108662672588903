import {useTranslation} from "react-i18next";
import {src7} from "./constants";

export const BadTeacher = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_scenarios_subtitle6")
    const article = t("course2_scenarios_article9")
    const article1 = t("course2_scenarios_11")
    const article2 = t("course2_scenarios_article10")
    const list = t("course2_scenarios_list15", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src7} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
        </div>
    )
}