import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import { BDSM_GAME } from "../contants";

export const ScenariousBdsm = () => {
    const { t } = useTranslation('app');
    const title = t("premium1_scenarios_title1")
    const titleMain = t("bdsm_game_premium")
    const list = t("premium1_scenarios_list1", { returnObjects: true })
    const list1 = t("premium1_scenarios_list2", { returnObjects: true })
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("bdsm_game") + 0]}
                        objectToSave={{
                            img: BDSM_GAME[0].img,
                            value: 'bdsm_game',
                            route: '/scenarios-bdsm',
                            index: 0
                        }}
                        uuid={"bdsm_game0"}
                        route={"/premium/bdsm-game"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            {list.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            {list1.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
        </div>
            </>
            )
}