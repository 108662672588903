import {useTranslation} from "react-i18next";
import {
    hardModeLogo,
    hotTasksLogo,
    oralGameLogo,
    sexGameLogo,
    SMSGameLogo, truthOrDareLogo
} from "../../../assets/onBoardingImages/gamesLogo";
import {InfoDarkBox} from "../../InfoDarkBox/InfoDarkBox";
import {IInfoBoxData} from "../../../types/infoDarkBox.types";
export const SignUpOfferGamesContainer =()=>{
    const { t } = useTranslation('onboarding');

    const gamesData:IInfoBoxData[]=[
        {logoSrc:truthOrDareLogo,title:t("sighUpOfferScreen.games.truthOrDareGame")},
        {logoSrc:sexGameLogo,title:t("sighUpOfferScreen.games.sexGame")},
        {logoSrc:oralGameLogo,title:t("sighUpOfferScreen.games.oralSexGame")},
        {logoSrc:hotTasksLogo,title:t("sighUpOfferScreen.games.hotTasksGame")},
        {logoSrc:SMSGameLogo,title:t("sighUpOfferScreen.games.SMSGame")},
        {logoSrc:hardModeLogo,title:t("sighUpOfferScreen.games.hardModeGame")}
    ]

    return (
           <InfoDarkBox infoData={gamesData} title={t("sighUpOfferScreen.games.title")} />
    )
}
