import './ProgressBar.scss';
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {ProgressBarState} from "../../constants/onBoardingConstants/progressBar.constants";
import {ProgressDivision} from "./ProgressDivision/ProgressDivision";
import {getProgressDivisionsInitialState} from "../../utils/progressBar.utils";
import {IProgressBarRoute, IProgressDivision} from "../../types/progressBar.types";
import {useTestElementsVisibility} from "../../hooks/onboarding/useTestElementsVisibility";

export const ProgressBar =({routes,progressBarRoutes}:{routes:string[]; progressBarRoutes: IProgressBarRoute[]})=>{
    const location= useLocation();
    const [progressDivisions, setProgressDivisions]=useState<IProgressDivision[]>(
        getProgressDivisionsInitialState(progressBarRoutes, location.pathname)
    )
    const isProgressBarVisible=useTestElementsVisibility(routes)

    useEffect(()=>{
        if(isProgressBarVisible) {
           const currentQuestionBarDivision= progressDivisions.find((progressDivision)=>progressDivision.route===location.pathname)

            const divisionsWithFilledLabels = progressDivisions.reduce((prevDivisions,division)=>{
                const isQuestionStaysBeforeCurrent =
                    currentQuestionBarDivision && division.divisionNumber<=currentQuestionBarDivision.divisionNumber;
                const isQuestionStaysAfterCurrent =
                    currentQuestionBarDivision && division.divisionNumber>currentQuestionBarDivision.divisionNumber;

                if (isQuestionStaysBeforeCurrent && currentQuestionBarDivision.divisionStatus===ProgressBarState.UNFILLED) {
                   prevDivisions.push({...division,divisionStatus:ProgressBarState.FILLED})
                } else if(isQuestionStaysAfterCurrent && currentQuestionBarDivision.divisionStatus===ProgressBarState.FILLED) {
                    prevDivisions.push({...division, divisionStatus: ProgressBarState.UNFILLED})
                }else {
                    prevDivisions.push(division)
               }

               return prevDivisions;
            },[] as IProgressDivision[])

            setProgressDivisions(divisionsWithFilledLabels)
        }
    },[location.pathname])

    useEffect(()=>{
        setProgressDivisions(
            getProgressDivisionsInitialState(
                progressBarRoutes,location.pathname)
        )
    },[location.pathname])

    return (<>
            {isProgressBarVisible ?
                <div className={'divisionsContainer'}>
                {progressDivisions.map(({label, divisionNumber, divisionStatus})=>{
                    return <ProgressDivision key={divisionNumber} label={label} divisionStatus={divisionStatus} />
                })}
                </div>
                :null}
        </>
    );
}
