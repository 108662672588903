export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
]

export const preparation1Src = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fpreparation%2Fpreparation1.png?alt=media&token=ebba3e53-07b8-4444-be6a-4bcac4009d17'
export const preparation2Src = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fpreparation%2Fpreparation2.png?alt=media&token=dcd19f12-0b6b-4b95-a793-08323227a4da'
export const preparation3Src = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fpreparation%2Fpreparation3.png?alt=media&token=bbeb3ea2-da6d-444f-aefc-d31edba569e4'
export const preparation4Src = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fpreparation%2Fpreparation4.png?alt=media&token=b83b9d01-6896-4be6-b475-a5a66c27f74f'
export const preparation5Src = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fpreparation%2Fpreparation5.png?alt=media&token=968523d8-7fe6-48dc-b593-cc4daafdc25f'
export const preparation6Src = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fpreparation%2Fpreparation6.png?alt=media&token=8803507d-6ac1-47ca-8dd1-1639a56c033f'
export const preparation7Src = 'https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fpreparation%2Fpreparation7.png?alt=media&token=173a1fd9-f537-4e0f-8bd6-e9550e892e0d'



