export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
]

export const zones1Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fzones%2Fzones1.png?alt=media&token=552967dd-3a84-4d64-9e11-447625f4bf31"
export const zones2Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fzones%2Fzones2.png?alt=media&token=edd13678-fbb0-4992-9c62-5e3c11e8f921"
export const zones3Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fzones%2Fzones3.png?alt=media&token=9b41a14b-852d-40a1-bc36-93103e7623e5"
export const zones4Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fzones%2Fzones4.png?alt=media&token=4c420969-4a57-4081-a1cf-cdd6ce6b4e4e"
export const zones5Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fzones%2Fzones5.png?alt=media&token=46b88356-b0d2-45c9-99ec-ea91e61011e5"
export const zones6Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fzones%2Fzones6.png?alt=media&token=13eebf3e-6d0d-4acd-81b2-e1c3a72571a8"


