import {useParams} from "react-router";
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import '../../FirstDateRule/styles.scss'
import {Rules} from "./constants";
import Part1 from "./Part1";
import Part2 from "./Part2";
import Part3 from "./Part3";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {MAN_OF_MY_DREAMS, PATH_TO_PLEASURE} from "../../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";

const RulesOfThePerfectLover = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("path_to_pleasure_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 0]}
                        objectToSave={{
                            img: PATH_TO_PLEASURE[0].img,
                            value: 'path_to_pleasure',
                            route: '/rules-of-the-perfect-lover/1',
                            index: 0
                        }}
                        uuid={"path_to_pleasure0"}
                        route={"/courses/path_to_pleasure"}
            />
            <Routes>
                <Route path={'/1'} element={<Part1 />} />
                <Route path={'/2'} element={<Part2 />} />
                <Route path={'/3'} element={<Part3 />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={Rules} routeToNavigate={COURSE_ROUTES.RULES_OF_THE_PERFECT_LOVER}  />
        </>
    )
}

export default RulesOfThePerfectLover