import {useTranslation} from "react-i18next";
import {src9} from "./constants";

const Chef = () => {
    const { t } = useTranslation('app');
    const subtitle = t("sex_games_subtitle8")
    const list = t("sex_games_list8", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src9} alt="course-preview" />
            </div>
        </div>
    )
}

export default Chef