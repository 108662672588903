import { TPropsItem } from "./types"
import {ReactComponent as StartIcon} from "assets/app/start.svg";
import {setFavoriteToList} from "../../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";
import cn from 'classnames'
import {useTranslation} from "react-i18next";

export const Item = ({img, info, handleRedirect, viewed, id, route, value, index, active, onSuccess}:TPropsItem) => {
    const user = getAuth()
    const {t} = useTranslation('app');
    const handleFavorites = async() => {
        const data = {
            img: img,
            route: route,
            value: value,
            index: index
        }
        await  setFavoriteToList(user?.currentUser?.accessToken, id, data)
        onSuccess()
    }

    return(
        <div className="main-item-wrapper column" onClick={handleRedirect}>
            <StartIcon className={cn("main-item-wrapper__favorite", {active: active})} onClick={(e) => {
            e.preventDefault()
                e.stopPropagation()
                handleFavorites()
            }
            } />
            <img loading="lazy" src={img} alt="course-preview" />
            <div className="main-item-wrapper__content">
                <div className="main-item-wrapper__title typography-16 mt-20">
                    {info.title || info}
                </div>
                <div className="main-item-wrapper__subtitle typography-14 typography-300 mt-8">
                    {info.description || ''}
                </div>
            </div>
            {!!viewed?.sumOfItems && <div className="main-item-wrapper__progress-container column">
                <div className="main-item-wrapper__progress-container__bar">
                    <div className="main-item-wrapper__progress-container__bar__red"
                         style={{'width': `${+viewed?.sumOfViewed * 100 / +viewed?.sumOfItems}%`}}/>
                </div>
                <div className="main-item-wrapper__progress-container__text row-container space-between">
                    <div className="typography-12">{t("tasks")}</div>
                    <div className="main-content__title-count typography-12">
                        <span>{+viewed?.sumOfViewed}</span>/{+viewed?.sumOfItems}</div>
                </div>
            </div>}
        </div>
    )
}