export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fpanty-method%2F8.2.4.1.png?alt=media&token=4dc3b25d-c301-45f6-8d9b-c663742b9448",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fpanty-method%2F8.2.4.2.png?alt=media&token=183a5679-2290-41f9-8553-9a85cdfed7c2",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fpanty-method%2F8.2.4.3.png?alt=media&token=af18cf07-e754-4403-9dd3-0486be4b25a0",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fpanty-method%2F8.2.4.4.png?alt=media&token=49f728fc-dbe5-40a3-8be1-12d179ecddce"
]





