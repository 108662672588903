import {Header} from "../Header/Header";
import {getOnboardingQuestionsRoutes, purchaseRoutes} from "../../utils/progressBar.utils";
import {ProgressBar} from "../ProgressBar/ProgressBar";
import {CREATE_ACCOUNT_ROUTE, NEW_LIFE_JOURNEY_ROUTE, SIGN_UP_OFFER_ROUTE} from "../../constants/routes.constants";
import {useLocation} from "react-router";
import {useContext, useEffect} from "react";
import {ThemeContext} from "../../context/themeContext";

export const HeaderContainer= ({isTrustingRelationship}: { isTrustingRelationship:boolean })=>{
    const location= useLocation();
    const { setTheme } = useContext(ThemeContext);
    const getProgressRoutesLinks=(routes:{route:string}[])=>{
        return routes.map((route)=>route.route)
    }

    const getIsPurchaseRoutesForProgressBar=()=>{
        return getProgressRoutesLinks(purchaseRoutes).includes(location.pathname)
    }

    useEffect(() => {
        if(location.search.includes('theme=dark')){
            setTheme('dark')
        } else if(location.search.includes('theme=light')){
            setTheme('light')
        }
    }, [])

    return (
        <div>
            <Header routesWithBackArrow={getIsPurchaseRoutesForProgressBar()?
                [] : getProgressRoutesLinks(getOnboardingQuestionsRoutes(isTrustingRelationship))}/>

            <ProgressBar routes={getIsPurchaseRoutesForProgressBar() ?
                [CREATE_ACCOUNT_ROUTE, SIGN_UP_OFFER_ROUTE]:
                getProgressRoutesLinks(getOnboardingQuestionsRoutes(isTrustingRelationship))}
                         progressBarRoutes={getIsPurchaseRoutesForProgressBar() ?
                             purchaseRoutes : getOnboardingQuestionsRoutes(isTrustingRelationship)}/>
        </div>
    )
}
