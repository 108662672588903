export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Finterview%2F8.4.3.1.png?alt=media&token=421eb24c-d702-41f0-a103-12a29550006f",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Finterview%2F8.4.3.2.png?alt=media&token=4fe417d3-a8a3-450b-8452-6f59441adcad",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Finterview%2F8.4.3.3.png?alt=media&token=f4236a4c-3807-4231-84cb-a69db15b58d7",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Finterview%2F8.4.3.4.png?alt=media&token=b5f6ad8f-7efa-4053-8d70-50e467618aaf"
]





