export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Flocker-room%2F8.5.1.1.png?alt=media&token=ec475ce8-8403-4a40-bc08-80eadb4c7844",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Flocker-room%2F8.5.1.2.png?alt=media&token=742b1774-4025-4dba-bdbd-50407753099d",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Flocker-room%2F8.5.1.3.png?alt=media&token=25eb3ed7-c87a-4949-887e-35ecc6d9ead2",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Flocker-room%2F8.5.1.4.png?alt=media&token=ede0308d-f5db-422b-a23f-bc3c10061e66",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Flocker-room%2F8.5.1.5.png?alt=media&token=47a3edac-cded-4481-8a57-da96eacdaf8e"
]





