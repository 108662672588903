import './specialOrder.scss';
import {TFunction} from "i18next";
import {Timer} from "../../../components/Timer/Timer";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import {PayCardsContainer} from "../../../components/onboarding/PayInfoContainer/PayCardsContainer/PayCardsContainer";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import SpecialOrderOption from "./SpecialOrderOption";
import React, {useEffect, useMemo, useState} from "react";
import {pricePeriods, priceValues, priceWithDiscount} from "../../../constants/price";
import {EMAIL_VALUE, PHONE_VALUE} from "../../../constants/info";

type TProps = {
    t: TFunction;
    timerDuration:number;
    onClick: () => void;
    setPrice: (data:number) => void;
    isTimerOver: boolean
    price: number
    selected: number
    setSelected: (val:number) => void
}
const SpecialOrder = ({t, timerDuration, onClick, setPrice, isTimerOver, price = 0, selected, setSelected}: TProps) => {

    const index = priceValues.indexOf(price)
    const priceVal = (isTimerOver ? price : priceWithDiscount[index])
    const period = pricePeriods[selected-1]

    const text = useMemo(() =>{
        return t("payInfoScreen.discountDescription").replace("44.99", priceVal).replace("4-", period+'-')
    },[t, priceVal, period])

    return (
        <div className="special-order">
            <div className="typography-22 typography-bold justify-content-center row-container">{t("payInfoScreen.discountSale50")}</div>
            <div className="special-order__timer row-container align-center justify-content-center mt-24">
                <div className="special-order__timer__container row-container align-center">
                <Timer duration={timerDuration} className={'headerTimerDesign'} withoutHours/>
                <div className="red-color typography-16 typography-bold">min</div>
                </div>
            </div>

            <SpecialOrderOption
                plan={1}
                t={t}
                oldPrice={!isTimerOver ? '24.99' : ''}
                newPrice={12.49}
                perDay={78}
                sale={!isTimerOver ? '3.57' : ''}
                total={!isTimerOver ? 1 : 3}
                selected={selected === 1}
                setSelected={() => {
                    setSelected(1)
                    setPrice( 24.99)
                }}
            />
            <SpecialOrderOption
                plan={4}
                t={t}
                oldPrice={!isTimerOver ? '89.99' : ''}
                newPrice={44.99}
                perDay={60}
                sale={!isTimerOver ? '3.21' : ''}
                total={!isTimerOver ? 1 : 3}
                popular
                selected={selected === 2}
                setSelected={() => {
                    setSelected(2)
                    setPrice( 89.99)
                }}
            />
            <SpecialOrderOption
                plan={12}
                t={t}
                oldPrice={!isTimerOver ? '199.99' : ''}
                newPrice={99.99}
                perDay={19}
                sale={!isTimerOver ? '2.38' : ''}
                total={!isTimerOver ? 1 : 2}
                selected={selected === 3}
                setSelected={() => {
                    setSelected(3)
                    setPrice( 199.99)
                }}
            />

            <Button onClick={onClick} text={t("payInfoScreen.getMyPlan")} buttonTheme={ButtonThemes.DARK} width={100} className="mt-24" />
            <div className="row-container align-center justify-center mt-16">
            <PayCardsContainer />
            </div>
            <div className="special-order__footer-text mt-16 column">
                <div className="typography-8">
                    {text}
                </div>
                <div className="typography-8">
                    {t("payInfoScreen.discountApp")}
                </div>
                <p className="typography-10 common-color">
                    {t("payInfoScreen.contact_us")} {EMAIL_VALUE}, {PHONE_VALUE}
                </p>
                <p className="typography-8">{`${t("payInfoScreen.learnMoreAboutTermsText")} `}
                    <a href={PolicyAnchors.SUBSCRIPTION}>{t("payInfoScreen.subscriptionTermsAnchor")}</a>
                </p>
            </div>
        </div>
    )
}

export default SpecialOrder