export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
    {
        tab: 10,
    },
]

export const src1="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual1.png?alt=media&token=bfe389c5-a569-467f-b66e-043b2c055a03"
export const src2="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual2.png?alt=media&token=edf01f88-17b7-4bdf-9c36-b5fded8afe07"
export const src3="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual3.png?alt=media&token=a9bf1b71-d519-4ade-885e-f3c25f329249"
export const src4="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual4.png?alt=media&token=7eb0d74b-495d-4a04-a08a-89b2fe9950fb"
export const src5="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual5.png?alt=media&token=797445f0-03a2-4f41-a7eb-370884b665e4"
export const src6="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual6.png?alt=media&token=ab4640ea-dd2e-4594-991c-fb430b1b2d4f"
export const src7="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual7.png?alt=media&token=99f131d7-10da-47a3-b3c8-206008848648"
export const src8="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual8.png?alt=media&token=d0bf5738-b54f-41cf-a4f0-322020b1d2de"
export const src9="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual9.png?alt=media&token=b3c7a43d-0f79-4ca3-bf7f-40c437f8b2af"
export const src10="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual10.png?alt=media&token=37fad464-b645-4645-8ecb-f433ce8989a2"
export const src11="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual11.png?alt=media&token=98c9bb19-a438-4885-b47c-dfd360998f23"
export const src12="https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Fmanual%2Fmanual12.png?alt=media&token=b12b895c-9938-4b40-861e-6529b7cbb1d6"




