export const ManOfMyDreamRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
]

export const behaviorSrc = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Funnamed.png?alt=media&token=b1b61468-15ab-4523-8c98-a5e7c6ab9fa8"
export const modelSrc = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fthe-model.png?alt=media&token=0c7029b7-1034-4370-b952-c0aa43128e2a"
export const placeSrc = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fplace.png?alt=media&token=196039fe-0bd1-4d26-b19a-17971ea898e2"

