export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fstrangers%2F8.2.1.1.png?alt=media&token=0f0096d0-7f04-4a08-a66c-1e7e6587002f",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fstrangers%2F8.2.1.2.png?alt=media&token=f22735c9-f888-4838-a4a4-462e35d5e947",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fstrangers%2F8.2.1.3.png?alt=media&token=26fc8ccb-2137-41b2-9004-eb71fe154f40",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fstrangers%2F8.2.1.4.png?alt=media&token=b0d8b637-81a5-4d60-b1a9-c6e497060b74"
]





