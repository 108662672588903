import {memo} from "react";
import '../ProgressBar/ProgressDivision/ProgressDivision.scss';
import {useNavigate} from "react-router";


export type TProps = {
    status: 'filled' | "unfilled",
    route: string
}
export const Bar = memo((
    {route, status}: TProps)=>{
const navigate = useNavigate()
    const onClick = () => {
    navigate(route)
    }
    return <div className={'divisionContainer'} onClick={onClick}>
        <div key={route} className={`bar ${status}`}/></div>;
})