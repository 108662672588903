export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fcomfort-zone%2F8.4.2.1.png?alt=media&token=b5c3c4a1-5697-47a5-97d4-7ee86a8ebfb7",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fcomfort-zone%2F8.4.2.2.png?alt=media&token=a7c867eb-3d3d-46c0-9e95-7f2690594817",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fcomfort-zone%2F8.4.2.3.png?alt=media&token=89ae6490-495b-4ebd-8dac-459ef79eeefd",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Fcomfort-zone%2F8.4.2.4.png?alt=media&token=1a6bf4d9-1797-4148-a163-fae56bb2ca64"
]





