export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
]

export const taboo1Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftaboo%2Ftaboo1.png?alt=media&token=f3a4c674-8f2e-4f7c-93c5-a3349344bc3e"
export const taboo2Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftaboo%2Ftaboo2.png?alt=media&token=0990a785-5a2b-4ec0-bdee-3451eb6f4f46"
export const taboo3Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftaboo%2Ftaboo3.png?alt=media&token=f20f9e35-0bc2-48bd-b305-b007be0f0ca2"
export const taboo4Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftaboo%2Ftaboo4.png?alt=media&token=1920d845-1393-4666-b40b-60111de736e1"
export const taboo5Src= "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2Fcourse2%2Ftaboo%2Ftaboo5.png?alt=media&token=34d4a9c3-b46e-48b1-9f17-cb831726314f"



