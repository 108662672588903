export const BehaveOnADateRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
]

export const behavior1Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2FBehavior%2Fbehavior1.png?alt=media&token=c8b6fc2d-701a-4717-9db2-8acfe915c543"
export const behavior2Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2FBehavior%2Fbehavior2.png?alt=media&token=de3362f4-89de-4095-a809-e800e1a35559"
export const behavior3Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2FBehavior%2Fbehavior3.png?alt=media&token=86771baf-d8f1-4a2c-b1ea-dde8e318e4ab"
export const behavior4Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2FBehavior%2Fbehavior4.png?alt=media&token=2c09216f-5210-40a4-8033-379797a4137f"
export const behavior5Src = "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/course-content%2FBehavior%2Fbehavior4.png?alt=media&token=2c09216f-5210-40a4-8033-379797a4137f"