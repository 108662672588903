export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.1.png?alt=media&token=4cd183ab-e409-4dab-97d3-b87af755d719",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.2.png?alt=media&token=32d790e1-3687-499b-8c27-e7f5addc1c0a",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.3.png?alt=media&token=dafe55e4-f4a7-4858-9e26-20b1bd1e6f51",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.4.png?alt=media&token=a0aedd50-7865-4001-8727-d977e0489be6",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.5.png?alt=media&token=ab7e9fc3-a98a-4c98-820d-3c63e7a5ad80",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.6.png?alt=media&token=38d6b100-a5e3-40c5-9be3-16bc5e4a7625",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.7.png?alt=media&token=1034475e-54e4-4ce4-8af6-9bc4830b2258",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.8.png?alt=media&token=b90f32df-a77a-43bd-a0c2-fa002bfe1f91",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.9.png?alt=media&token=d36a45f4-2798-4406-8bf0-cc0cbdc34b61",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.10.png?alt=media&token=5f5eeb0a-a115-4007-99df-043aec577507",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.11.png?alt=media&token=28f59fed-3bec-44a6-99fe-7af4bdf02613",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.12.png?alt=media&token=d537f871-eaef-4b5e-8ce8-a8daf4b062c3",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.13.png?alt=media&token=bb88a4e6-b662-486b-979e-c5b4e16b9afc",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.14.png?alt=media&token=059ab542-8794-41ed-bdbc-9ec9bfc1dc52",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/games%2Fgames-5%2F6.3.15.png?alt=media&token=1f6edee7-a939-4f8a-b8ce-deedda09361f",
]






