import React from 'react';
import  './style.scss'

export type TProps = {
    label?:string;
    checked: boolean;
    setChecked: () => void;
}
const RadioCheckButton = ({label, checked, setChecked}:TProps) => {
    return(
        <div className="checkbox-wrapper-4">
            <input className="inp-cbx" type="checkbox" checked={checked} />
            <label className="cbx" onClick={setChecked} >
        <span>

          <svg width="16px" height="16px">
            <use xlinkHref="#check-4"></use>
          </svg>
        </span>
                <span>{label}</span>
            </label>
            <svg className="inline-svg">
                <symbol id="check-4" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </symbol>
            </svg>
        </div>
    )
}

export default RadioCheckButton