import {useTranslation} from "react-i18next";
import {beforeBeginPicture} from "../../../assets/onBoardingImages";
import {ButtonThemes} from "../../../constants/button.constants";
import './HonestCheckScreen.scss';
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

export const HonestCheckScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={beforeBeginPicture} />
                <h4 className={'honestCheckTitle'}>{t("honestCheckScreen.beforeBeginTitle")}</h4>
                <p className={'honestCheckText'}>{t("honestCheckScreen.beforeBeginInfo")}</p>
                <h6 className={'honestCheckQuestion'}>{t("honestCheckScreen.beforeBeginQuestion")}</h6>
            </ContentContainer>
             <NextRouteButton option={t("honestCheckScreen.agreeButtonText")}
                                    route={nextRoute}
                                    buttonTheme={ButtonThemes.DARK}
                                    className={'honestCheckAgreeButton'} />
        </>
    )
}
