export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Falien%2F8.3.4.1.png?alt=media&token=533fa0c7-04bb-4bfa-a112-0c0e4e5ff922",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Falien%2F8.3.4.2.png?alt=media&token=8b07633b-4724-461a-8e2b-ca4182ded50d",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Falien%2F8.3.4.3.png?alt=media&token=56654861-24da-435d-bf73-f199491f871a",
        "https://firebasestorage.googleapis.com/v0/b/divinedesires-436c5.appspot.com/o/scenarios%2Falien%2F8.3.4.4.png?alt=media&token=de2e25ed-756d-4533-a634-67d09e7bb32c"
]





