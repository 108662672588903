import {useTranslation} from "react-i18next";
import {ways16Src, ways17Src} from "./constants";

const Subway = () => {
    const { t } = useTranslation('app');
    const subTitle = t("ways_17_title")
    const subTitle2 = t("ways_16_title")
    const article = t("ways_16_article")
    const article2 = t("ways_17_article")
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle2}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways16Src} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subTitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={ways17Src} alt="course-preview" />
            </div>
        </div>
    )
}

export default Subway