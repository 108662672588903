import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {IN_RESTAURANT} from "../../constants";


const DirtyGames = () => {
    const { t } = useTranslation('app');
    const title = t("scenario1_dirty_title1")
    const article = t("scenario1_dirty_article1")
    const subtitle = t("scenario1_dirty_title2")
    const subtitle1 = t("scenario1_dirty_title3")
    const list1 = t("scenario1_dirty_list1", { returnObjects: true })
    const list2 = t("scenario1_dirty_list2", { returnObjects: true })
    const titleMain = t("in_restaurant_scenario")

    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("in_restaurant") + 2]}
                        objectToSave={{
                            img: IN_RESTAURANT[2].img,
                            value: 'in_restaurant',
                            route: '/dirty-games',
                            index: 2
                        }}
                        uuid={"in_restaurant2"}
                        route={"/scenarios/in-restaurant"}
            />
        <div className="child-container">
            <div className="child-container__title typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
        </div>
</>
    )
}

export default DirtyGames