import {SCENARIOS} from "../Main/constants";
import {Item} from "../Main/Item";
import { SCENARIOS_ROUTE} from "../../../constants/routes.constants";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import '../styles/styles.scss'
import {SCENARIOS_NAMES_ROUTES} from "./constants";
import {useCallback, useEffect, useState} from "react";
import {
    calculateCoursesProgressItem, getListOfFavorites,
    getUnitUserDataToDatabase,
    ProgressCalcResponse
} from "../../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";
import {TFavoritesList} from "../FavoritesPage/FavoritesPage";
import cn from "classnames";

export const Scenarios = () => {
    const navigate=useNavigate();
    const user = getAuth()
    const { t } = useTranslation('app');
    const scenariosInfo = t('scenarios_info', { returnObjects: true })
    const scenariosTitle = t('scenarios')
    const [scenariosViewed, setScenariosViewed] = useState<ProgressCalcResponse[]>([{ sumOfViewed: 0, sumOfItems: 0 }])
    const getCoursesViewed = useCallback(async() => {
        if(!user?.currentUser?.uid) return
        const res = await getUnitUserDataToDatabase(user?.currentUser?.accessToken, "scenarios")
        setScenariosViewed(calculateCoursesProgressItem(res))
    }, [])
    useEffect(() => {
        // window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(!user?.currentUser?.uid) return
        getCoursesViewed()
    }, [user])
    const choose_your = t('choose_your')
    const handleRedirect = (route: string) => {
        navigate(route)
    }
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <div className="section-wrapper app-wrapper column">
            <div className="section-wrapper__header typography-24 typography-bold mt-32">
                {choose_your}&nbsp;<span>{scenariosTitle}</span>
            </div>
            <div className="section-wrapper__content column align-center mt-32">
                {SCENARIOS.map((i, idx) =><div
                    className={cn("section-wrapper-item__content__border", {active: !!scenariosViewed[idx]?.sumOfViewed})}>
                    {!scenariosViewed[idx]?.sumOfViewed && <div className="start-btn typography-400" onClick={() => handleRedirect(SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[idx])}>
                        {t("start")}
                    </div>} <Item
                    handleRedirect={() => handleRedirect(SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[idx])}
                    img={i.img}
                    onSuccess={getFavorites}
                    id={i.id}
                    route={i.url}
                    active={!!list[i.id]}
                    value={"scenarios_info"}
                    index={idx}
                    key={'scenario' + idx}
                    viewed={scenariosViewed[idx] || scenariosViewed[0]}
                    info={scenariosInfo[idx]} /></div>)}
            </div>
        </div>
    )
}
