import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";

export const Down = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course4_sexuality_title14")
    const subtitle1 = t("course4_sexuality_title15")
    const article = t("course4_sexuality_article3")
    const list = t("course4_sexuality_list13", { returnObjects: true })
    const list1 = t("course4_sexuality_list10", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[5]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
    )
}