import { Route, Routes} from "react-router-dom";
import {COURSE_ROUTES} from "../../../../constants/routes.constants";
import Behaviour from "./Behaviour";
import { useParams} from "react-router";
import TheModel from "./TheModel";
import Look from "./Look";
import Place from "./Place";
import {ManOfMyDreamRoutes} from "./constants";
import './styles.scss'
import TabsFooter from "../../../../components/Bar";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useTranslation} from "react-i18next";
import {MAN_OF_MY_DREAMS} from "../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";


const AllFirstDateRule = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("man_of_my_dreams_course")
    const user = getAuth()
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!list[("man_of_my_dreams") + 0]}
                        objectToSave={{
                            img: MAN_OF_MY_DREAMS[0].img,
                            value: 'man_of_my_dreams',
                            route: '/first-date-rule/1',
                            index: 0
                        }}
                        uuid={"man_of_my_dreams0"}
                        route={"/courses/man-of-my-dreams"}
            />
        <Routes>
            <Route path={'/1'} element={<Behaviour />} />
            <Route path={'/2'} element={<TheModel />} />
            <Route path={'/3'} element={<Look />} />
            <Route path={'/4'} element={<Place />} />
        </Routes>

            <TabsFooter tab={tab || ''} routes={ManOfMyDreamRoutes} routeToNavigate={COURSE_ROUTES.FIRST_DATE_RULE}  />
        </>
    )
}

export default AllFirstDateRule