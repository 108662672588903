import {getDownloadURL, ref, listAll} from "firebase/storage";
import {storageFirebase} from "../../firebase/firebase.config";


export async function getImage(imageName: string) {
    const imageRef = ref(storageFirebase, imageName)

    listAll(imageRef)
        .then(async (res) => {
            const { items } = res;
            const urls = await Promise.all(
                items.map((item) => getDownloadURL(item))
            );
        })

}
export async function getSingleImage(imageName: string) {
    const imageRef = ref(storageFirebase, imageName)
        return  await getDownloadURL(imageRef)

}

