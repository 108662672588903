import {useNavigate, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {ReactComponent as StarIcon} from "assets/app/start.svg";
import {ReactComponent as LipsIcon} from "assets/app/lips.svg";
import * as ImageUrls from './constants'
import {MouseEvent, useEffect, useMemo, useState} from "react";
import '../styles/styles.scss'
import {getAuth} from "firebase/auth";
import {
    changeProgressUserDataToDatabase, getListOfFavorites,
    getProgressUserDataToDatabase,
    setFavoriteToList
} from "../../../utils/firebase/userData.utils";
import cn from "classnames";
import {AT_HOME_ROUTES, AT_HOME_NUMBER} from "./constants";
import {TFavoritesList} from "../FavoritesPage/FavoritesPage";

export const ScenarioItem = () => {
    const params = useParams()
    const { t } = useTranslation('app');
    const scenarioInfo = t(params.id?.replaceAll('-', '_'), { returnObjects: true })
    const scenarioName = t(params.id?.replaceAll('-', '_') + "_scenario")
    const scenarioTitle = t("scenario_title")
    const navigate = useNavigate()
    const [viewed, setViewed] = useState<any>([])

    const routes = useMemo(() => {
        if(params.id) {
            const name = params.id.replaceAll('-', '_').toUpperCase()
            // @ts-ignore
            return !!ImageUrls[`${name}_ROUTES`]  ? ImageUrls[`${name}_ROUTES`] : AT_HOME_ROUTES
        } else {
            return AT_HOME_ROUTES
        }
    }, [params])

    const imageIdx = useMemo(() => {
        if(params.id) {
            const name = params.id.replaceAll('-', '_').toUpperCase()
            // @ts-ignore
            return !!ImageUrls[`${name}`]  ? ImageUrls[`${name}`] : ImageUrls.AT_HOME
        } else {
            return ImageUrls.AT_HOME
        }
    }, [params])
    const user = getAuth()
    const indexInDataBase = useMemo(() => {
        if(params.id) {
            const name = params.id.replaceAll('-', '_').toUpperCase()
            // @ts-ignore
            return !!ImageUrls[`${name}_NUMBER`]  ? ImageUrls[`${name}_NUMBER`] : AT_HOME_NUMBER
        } else {
            return AT_HOME_NUMBER
        }
    }, [params])
    const getViewed = async () => {
        const res = await getProgressUserDataToDatabase(user?.currentUser?.accessToken, "scenarios", indexInDataBase.toString()) as any
        setViewed(res)
    }

    useEffect(() => {
        getViewed()
    }, [indexInDataBase, user])
    const handleFavorites = async(e:MouseEvent, data: any, uuid?: string) => {
        if(!uuid) return
        e.preventDefault()
        e.stopPropagation()
        await  setFavoriteToList(user?.currentUser?.accessToken, uuid, data)
        await getFavorites()
    }

    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <div className="section-wrapper-item app-wrapper">
            <div className="section-wrapper-item__header column mt-32">
                <span className="section-wrapper-item__header__title typography-14 typography-400">
                    {scenarioTitle}
                </span>
                <span className="section-wrapper-item__header__name typography-18 typography-bold">
                    {scenarioName}
                </span>
            </div>
            <div className="section-wrapper-item__content column align-center mt-32">
                {!!imageIdx?.length && scenarioInfo?.map((i: string, idx: number) => {
                    return(
                        <div className="section-wrapper-item__content__wrapper" onClick={() => {
                            navigate(routes[idx])
                            changeProgressUserDataToDatabase(user?.currentUser?.accessToken, "scenarios", indexInDataBase.toString(), idx.toString())

                        }}>
                            <StarIcon className={cn("section-wrapper-item__content__wrapper__favorite", {active: !!list[(params?.id?.replaceAll('-', '_') || '') + idx]})} onClick={(e) => {
                                handleFavorites(e, {
                                    img: imageIdx[idx].img, value: params?.id?.replaceAll('-', '_'), route: routes[idx], index: idx
                                }, (params?.id?.replaceAll('-', '_') || '') + idx)
                            }} />
                            <img loading="lazy" src={imageIdx[idx].img} alt="course-preview" />
                            <div className="section-wrapper-item__content__wrapper__title row-container space-between">
                                <span className="typography-14 typography-bold">{i}</span>
                                <LipsIcon className={cn("section-wrapper-item__content__wrapper__title__lip", {active: viewed[idx]})}/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}