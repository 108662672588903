import {COURSES} from "../Main/constants";
import {Item} from "../Main/Item";
import {COURSES as CourseRoute} from "../../../constants/routes.constants";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import '../styles/styles.scss'
import {COURSES_NAMES_ROUTES} from "./constants";
import {useCallback, useEffect, useState} from "react";
import {
    calculateCoursesProgressItem, getListOfFavorites,
    getUnitUserDataToDatabase,
    ProgressCalcResponse
} from "../../../utils/firebase/userData.utils";
import { getAuth } from "firebase/auth";
import {TFavoritesList} from "../FavoritesPage/FavoritesPage";
import cn from "classnames";

export const Courses = () => {
    const navigate=useNavigate();
    const user = getAuth()
    const { t } = useTranslation('app');
    const coursesInfo = t('course_info', { returnObjects: true })
    const courseTitle = t('course')
    const choose_your = t('choose_your')
    const [coursesViewed, setCoursesViewed] = useState<ProgressCalcResponse[]>([{ sumOfViewed: 0, sumOfItems: 0 }])
    const getCoursesViewed = useCallback(async() => {
        if(!user?.currentUser?.uid) return
        const res = await getUnitUserDataToDatabase(user?.currentUser?.accessToken, "courses")
        setCoursesViewed(calculateCoursesProgressItem(res))
    }, [])

    useEffect(() => {
        if(!user?.currentUser?.uid) return
        getCoursesViewed()
    }, [user])
    const handleRedirect = (route: string) => {
        navigate(route)
    }
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    useEffect(() => {
        // window.scrollTo(0, 0)
    }, [])
    return(
        <div className="section-wrapper app-wrapper column">
            <div className="section-wrapper__header typography-24 typography-bold mt-32">
                {choose_your}&nbsp;<span>{courseTitle}</span>
            </div>
            <div className="section-wrapper__content column align-center mt-32">
                {COURSES.map((i, idx) => <div
                    className={cn("section-wrapper-item__content__border", {active: !!coursesViewed[idx]?.sumOfViewed})}>
                    {!coursesViewed[idx]?.sumOfViewed && <div className="start-btn typography-400" onClick={() => handleRedirect(idx === 4 ? COURSES_NAMES_ROUTES[idx] : CourseRoute + '/' + COURSES_NAMES_ROUTES[idx])}>
                        {t("start")}
                    </div>}<Item
                    handleRedirect={() => handleRedirect(idx === 4 ? COURSES_NAMES_ROUTES[idx] : CourseRoute + '/' + COURSES_NAMES_ROUTES[idx])}
                    img={i.img}
                    id={i.id}
                    route={i.url}
                    value={"course_info"}
                    index={idx}
                    onSuccess={getFavorites}
                    viewed={coursesViewed[idx] || coursesViewed[0]}
                    key={'course' + idx}
                    active={!!list[i.id]}
                    info={coursesInfo[idx]} />
                </div>)}
            </div>
        </div>
    )
}
