import {useTranslation} from "react-i18next";
import {rules2} from "./constants";

const Part2 = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course2_rules_subtitle2")
    const list = t("course2_rules_list1", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={rules2} alt="course-preview" />
            </div>
        </div>
    )
}

export default Part2