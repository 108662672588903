import {useTranslation} from "react-i18next";
import { src10} from "./constants";

export const Policeman = () => {
    const { t } = useTranslation('app');
    const subtitle = t("course3_rules_title11")
    const subtitle1 = t("course3_rules_article15")
    const list = t("course3_rules_list15", { course3_rules_list18: true })
    const list2 = t("course3_rules_list19", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src10} alt="course-preview" />
            </div>
            <div className="child-container__red-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list2.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
        </div>
    )
}